import {PURCHASE_ORDER_STATII} from '../../../../common/constants/PurchaseOrders.js'
import {useSelector} from '../../../../store.js'
import {
  updateModalForm,
  modalFormSelector,
  STATUS_FILTER_PANEL,
} from './poListFilterModalFunctions.js'
import FilterPanel from '../../../OrderListPage/Modals/OrderListFilterModal/FilterPanel.js'
import {
  getNounsFromValue,
  tokenizeOptions,
} from '../../../../common/tokenizeOptions.js'

const statusOptions = PURCHASE_ORDER_STATII.map(({name, slug}) => ({
  value: slug,
  display: name,
  nouns: getNounsFromValue(`${slug} ${name}`),
}))

const statusOptionTokens = tokenizeOptions(statusOptions)

export default function StatusFilterPanel() {
  const form = useSelector(modalFormSelector)

  return (
    <FilterPanel
      id={STATUS_FILTER_PANEL}
      label="Status"
      searchPlaceholder="Search for a status filter..."
      options={statusOptions}
      optionTokens={statusOptionTokens}
      selected={form.status}
      onSelect={(option) => {
        const status = form.status.filter((id) => id !== option.value)

        if (status.length === form.status.length) {
          status.push(option.value)
        }

        updateModalForm({status})
      }}
    />
  )
}
