import {combineReducers} from 'redux'

import exportInsuranceHistoryModal from './exportInsuranceHistoryModal.js'
import confirmAttemptToAllocateModal from './confirmAttemptToAllocateModal.js'
import confirmDeallocateModal from './confirmDeallocateModal.js'
import confirmDropshipProductModal from './confirmDropshipProductModal.js'
import endiciaCreditCardModal from './endiciaCreditCardModal.js'
import listOnAmazonModal from './listOnAmazonModal.js'
import confirmArchiveProductModal from './confirmArchiveProductModal.js'
import addPurchaseOrderCommentModal from './addPurchaseOrderCommentModal.js'
import editInstructionsModal from './editInstructionsModal.js'

export default combineReducers({
  exportInsuranceHistoryModal,
  confirmAttemptToAllocateModal,
  confirmDeallocateModal,
  confirmDropshipProductModal,
  endiciaCreditCardModal,
  listOnAmazonModal,
  confirmArchiveProductModal,
  addPurchaseOrderCommentModal,
  editInstructionsModal,
})
