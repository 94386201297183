import PropTypes from 'prop-types'
import difference from 'lodash/difference.js'
import intersection from 'lodash/intersection.js'
import without from 'lodash/without.js'
import uniq from 'lodash/uniq.js'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

import apiverson from '../../common/apiverson.js'
import {
  getState,
  dispatch,
  setForm,
  updateForm,
  removeForm,
} from '../../store.js'
import delay from '../../common/delay.js'
import {
  ORDER_PLURAL_URI_COMPONENT,
  DEFAULT_EXPAND_MODE,
  SHIP_TO_ADDRESS_VALIDATION_OPTIONS,
  ALL_ORDER_SORT_OPTIONS,
  DEFAULT_SORT,
  DEFAULT_PER_PAGE,
  BATCH_SORT_OPTIONS,
  ORDER_SINGLE_URI_COMPONENT,
  DEFAULT_STATUS,
  ORDER_STATUS_BY_SLUG,
} from '../../common/constants/Orders.js'
import {showGlobalError} from '../GlobalErrorMessage.js'
import {
  orderTagsHaveLoadedSelector,
  orderTagsSortedByNameSelector,
} from '../../data/orderTags.js'
import {setOrdersStateAndEnsureProductsLoaded} from '../../data/orders.js'
import {
  updateOrderCounts,
  updateOrderRevisionCount,
  acknowledgeNewOrders,
} from '../../data/orderCounts.js'

import {currentPageSelector} from '../../redux/selectors/ui/index.js'
import {ORDER_LIST_PAGE} from '../../common/constants/Pages.js'
import {navigate} from '../../common/location.js'
import {locationSelector} from '../../redux/selectors/ui/location.js'
import {
  activeOrderNumbersSelector,
  activeOrdersSelector,
  defaultHashParamsSelector,
  expandedOrderNumbersSelector,
  getOrderDetailURL,
  orderListQueryParamsSelector,
  orderListQuerySelector,
  ORDER_LIST,
  selectedOrderNumbersSelector,
  validDateRangeFilterTypesOptionsSelector,
  visibleOrderNumbersSelector,
  summarizeOrderDateRangeParams,
} from './orderListSelectors.js'
import {ensureArrayOrUndefined, ensureTroolean} from '../../common/ensure.js'
import {
  TAG_FILTER_OPTIONS,
  TAG_FILTER_OR,
  TAG_TYPE__ORDER,
} from '../../common/constants/Tags.js'
import {goToCreateOrderPage} from '../CreateOrderPage/createOrderActions.js'
import {showConfirmAttemptToAllocateModal} from '../../redux/actions/ui/modals/confirmAttemptToAllocateModal.js'
import {showConfirmDeallocateModal} from '../../redux/actions/ui/modals/confirmDeallocateModal.js'
import {labelInfoIDsForOrdersAndLabelTypesSelector} from '../../data/labelInfos/index.js'
import {showEditOrderDimensionsModal} from './Modals/EditOrderDimensionsModal.js'
import {setDefaultShippingMethod} from './DropshipConfigForm/ManualDropshipForm/manualDropshipFormFunctions.js'
import {showEditTagModal} from '../../common/components/Modal/EditTagModal.js'
import {fromZonedTime} from '../../common/zonedTime.js'

export const OrderListFormShape = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  visibleOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandMode: PropTypes.string.isRequired,
})

export function setupOrderListForm() {
  setForm(ORDER_LIST, {
    isLoading: false,
    count: 0,
    visibleOrderNumbers: [],
    selectedOrderNumbers: [],
    expandedOrderNumbers: [],
    expandMode: DEFAULT_EXPAND_MODE,
    // We are caching the status so that when users navigate to order
    // details from the list they can back to the same status
    // This is a VERY LIMITED feature and WE SHOULD NOT BE DOING IT
    // The browser's back button works better than this
    cachedOrderListStatus_DO_NOT_USE: null,
  })
}

export function updateOrderListForm(updates, meta) {
  updateForm(ORDER_LIST, updates, meta)
}

export function removeOrderListForm() {
  removeForm(ORDER_LIST)
}

export function setSelectedOrderNumbers(selectedOrderNumbers) {
  updateOrderListForm({
    selectedOrderNumbers,
  })
}

export function selectOrder(orderNumber) {
  const selectedOrderNumbers = selectedOrderNumbersSelector(getState())
  const visibleOrderNumbers = visibleOrderNumbersSelector(getState())

  if (selectedOrderNumbers.includes(orderNumber)) {
    setSelectedOrderNumbers(without(selectedOrderNumbers, orderNumber))
  } else {
    setSelectedOrderNumbers(
      intersection(visibleOrderNumbers, [...selectedOrderNumbers, orderNumber]),
    )
  }

  setDefaultShippingMethod()
}

export function deselectOrder(orderNumber) {
  deselectOrders([orderNumber])
}

export function deselectOrders(orderNumbers) {
  const selectedOrderNumbers = selectedOrderNumbersSelector(getState())

  setSelectedOrderNumbers(difference(selectedOrderNumbers, orderNumbers))
}

export function toggleExpanded(orderNumber) {
  const expandedOrderNumbers = expandedOrderNumbersSelector(getState())

  const orderNumbers = expandedOrderNumbers.filter((o) => o !== orderNumber)

  if (!expandedOrderNumbers.includes(orderNumber)) {
    orderNumbers.push(orderNumber)
  }

  setExpandedOrderNumbers(orderNumbers)
}

export function navigateToOrderListPage(query = {}) {
  const defaultQuery = defaultHashParamsSelector(getState())

  const pathComponents = [ORDER_SINGLE_URI_COMPONENT]

  return navigate(pathComponents, {
    ...defaultQuery,
    ...query,
  })
}

export function navigateToOrderDetailPage(orderNumber) {
  return navigate(...getOrderDetailURL(orderNumber))
}

export function navigateOrderList(updates = {}) {
  let {pathComponents, query} = locationSelector(getState())

  if (
    ![ORDER_PLURAL_URI_COMPONENT, ORDER_SINGLE_URI_COMPONENT].includes(
      pathComponents[0],
    )
  ) {
    return
  }

  // HACK to allow single status in url path. This code eats that status
  // and adds it to whatever status that might be present in updates
  if (
    pathComponents[0] === ORDER_PLURAL_URI_COMPONENT &&
    (pathComponents[1] in ORDER_STATUS_BY_SLUG || !pathComponents[1])
  ) {
    updates.status = uniq([
      pathComponents[1] || DEFAULT_STATUS,
      ...(updates.status || []),
    ])
  }

  pathComponents = [ORDER_SINGLE_URI_COMPONENT]

  query = {...query, ...updates}

  return navigate(pathComponents, query)
}

export function resetOffset() {
  return {
    offset: undefined,
  }
}

export function updateOrderListStatus(status) {
  status = status.filter((s) => s in ORDER_STATUS_BY_SLUG)

  return {
    status,
    ...resetOffset(),
  }
}

export function setOrderListStatus(status) {
  return navigateOrderList(updateOrderListStatus(status))
}

export function updateSupplierFilters(supplierIDs) {
  return {
    supplierFilters: ensureArrayOrUndefined(supplierIDs),
    ...resetOffset(),
  }
}

export function setSupplierFilters(supplierIDs) {
  return navigateOrderList(updateSupplierFilters(supplierIDs))
}

export function removeSupplierFilter(supplierID) {
  const {supplier} = orderListQuerySelector(getState())

  return setSupplierFilters(supplier.filter((id) => id !== supplierID))
}

export function updateWarehouseFilters(warehouseIDs) {
  return {
    warehouseFilters: ensureArrayOrUndefined(warehouseIDs),
    ...resetOffset(),
  }
}

export function setWarehouseFilters(warehouseIDs) {
  return navigateOrderList(updateWarehouseFilters(warehouseIDs))
}

export function removeWarehouseFilter(warehouseID) {
  const {warehouse_id} = orderListQuerySelector(getState())

  return setWarehouseFilters(warehouse_id.filter((id) => id !== warehouseID))
}

export function updateAllocationFilters(allocations) {
  return {
    allocationFilters: ensureArrayOrUndefined(allocations),
    ...resetOffset(),
  }
}

export function setAllocationFilters(allocations) {
  return navigateOrderList(updateAllocationFilters(allocations))
}

export function removeAllocationFilter(allocation) {
  const {allocation_status} = orderListQuerySelector(getState())

  return setAllocationFilters(
    allocation_status.filter((status) => status !== allocation),
  )
}

export function updateCartFilters(cartIDs) {
  return {
    cartFilters: ensureArrayOrUndefined(cartIDs),
    ...resetOffset(),
  }
}

export function setCartFilters(cartIDs) {
  return navigateOrderList(updateCartFilters(cartIDs))
}

export function removeCartFilter(cartID) {
  const {sales_channel} = orderListQuerySelector(getState())

  return setCartFilters(sales_channel.filter((id) => id !== cartID))
}

export function updateShipperFilters(shipperIDs) {
  return {
    shipper: ensureArrayOrUndefined(shipperIDs),
    ...resetOffset(),
  }
}

export function setShipperFilters(shipperIDs) {
  return navigateOrderList(updateShipperFilters(shipperIDs))
}

export function removeShipperFilter(shipperID) {
  const {shipper} = orderListQuerySelector(getState())

  return setShipperFilters(shipper.filter((id) => id !== shipperID))
}

export function updateOrderTagFilters(tags) {
  const orderTags = orderTagsSortedByNameSelector(getState())

  const orderTagFilters = orderTags.reduce((prev, tag) => {
    if (tags.includes(tag.text)) {
      prev.push(tag.link)
    }

    return prev
  }, [])

  return {
    orderTagFilters: uniq(ensureArrayOrUndefined(orderTagFilters)),
    ...resetOffset(),
  }
}

export function setOrderTagFilters(tags) {
  return navigateOrderList(updateOrderTagFilters(tags))
}

export function removeOrderTagFilter(tagName) {
  const {tag} = orderListQuerySelector(getState())

  return setOrderTagFilters(tag.filter((text) => text !== tagName))
}

export function updateOrderUntaggedFilter(untagged) {
  return {
    orderUntaggedFilter: untagged ? true : undefined,
    ...resetOffset(),
  }
}

export function setOrderUntaggedFilter(untagged) {
  return navigateOrderList(updateOrderUntaggedFilter(untagged))
}

export function updateOrderTagFilterBy(orderTagFilterBy) {
  if (!TAG_FILTER_OPTIONS.find(({value}) => orderTagFilterBy === value)) {
    orderTagFilterBy = TAG_FILTER_OR
  }

  if (orderTagFilterBy === TAG_FILTER_OR) {
    orderTagFilterBy = undefined
  }

  return {
    orderTagFilterOperator: orderTagFilterBy,
    ...resetOffset(),
  }
}

export function setOrderTagFilterBy(tag_filter_by) {
  return navigateOrderList(updateOrderTagFilterBy(tag_filter_by))
}

export function updateOrderExcludeTags(tags) {
  return {
    exclude_tags: uniq(ensureArrayOrUndefined(tags)),
    ...resetOffset(),
  }
}

export function setOrderExcludeTags(tags) {
  return navigateOrderList(updateOrderExcludeTags(tags))
}

export function removeOrderExcludeTag(tagName) {
  const {exclude_tags} = orderListQuerySelector(getState())

  return setOrderExcludeTags(exclude_tags.filter((name) => name !== tagName))
}

export function updateDateRangeFilters(
  {
    dateFilterType,
    startDate,
    endDate,
    intervalType,
    intervalAmount,
    intervalTrunc,
    timezone,
  } = {},
  status,
) {
  const options = validDateRangeFilterTypesOptionsSelector(getState(), {status})

  const updates = {
    dateFilterType: undefined,
    startDate: undefined,
    endDate: undefined,
    intervalType: undefined,
    intervalAmount: undefined,
    intervalTrunc: undefined,
    timezone: undefined,
    ...resetOffset(),
  }

  if (intervalType) {
    updates.intervalType = intervalType
    updates.intervalAmount = Number(intervalAmount) || 1
    updates.intervalTrunc = !!intervalTrunc
    updates.timezone = timezone
  } else if (startDate || endDate) {
    if (startDate) {
      startDate = startOfDay(startDate)

      if (timezone) {
        startDate = fromZonedTime(startDate, timezone)

        updates.timezone = timezone
      }

      updates.startDate = startDate.toISOString()
    }

    if (endDate) {
      endDate = endOfDay(endDate)

      if (timezone) {
        endDate = fromZonedTime(endDate, timezone)

        updates.timezone = timezone
      }

      updates.endDate = endDate.toISOString()
    }
  }

  updates.dateFilterType =
    (updates.startDate || updates.endDate || updates.intervalType) &&
    options.find(({value}) => value === dateFilterType)
      ? dateFilterType
      : undefined

  return updates
}

export function setDateRangeFilters(dateRangeFilters) {
  const {status} = orderListQuerySelector(getState())

  return navigateOrderList(updateDateRangeFilters(dateRangeFilters, status))
}

export function updateShowUnprintedOnly(showUnprintedOnly) {
  return {
    showUnprintedOnly: showUnprintedOnly ? true : undefined,
    ...resetOffset(),
  }
}

export function setTimezone(timezone) {
  let {
    startDate,
    endDate,
    dateFilterType,
    intervalType,
    intervalAmount,
    intervalTrunc,
  } = summarizeOrderDateRangeParams(orderListQuerySelector(getState()))

  return navigateOrderList(
    updateDateRangeFilters({
      dateFilterType,
      startDate,
      endDate,
      intervalType,
      intervalAmount,
      intervalTrunc,
      timezone,
    }),
  )
}

export function updateTimezone(timezone) {
  return {
    timezone: timezone || undefined,
    ...resetOffset(),
  }
}

export function setShowUnprintedOnly(showUnprintedOnly) {
  return navigateOrderList(updateShowUnprintedOnly(showUnprintedOnly))
}

export function updateHasSimilarOpenAddresses(hasSimilarOpenAddresses) {
  return {
    hasSimilarOpenAddresses: hasSimilarOpenAddresses ? true : undefined,
    ...resetOffset(),
  }
}

export function setHasSimilarOpenAddresses(hasSimilarOpenAddresses) {
  return navigateOrderList(
    updateHasSimilarOpenAddresses(hasSimilarOpenAddresses),
  )
}

export function updateShipToIsBillTo(shipToIsBillTo) {
  return {
    shipToIsBillTo: ensureTroolean(shipToIsBillTo),
    ...resetOffset(),
  }
}

export function setShipToIsBillTo(shipToIsBillTo) {
  return navigateOrderList(updateShipToIsBillTo(shipToIsBillTo))
}

export function updateHasRevision(hasRevision) {
  return {
    hasRevision: hasRevision ? true : undefined,
    ...resetOffset(),
  }
}

export function setHasRevision(hasRevision) {
  return navigateOrderList(updateHasRevision(hasRevision))
}

export function updateSimilarAddressTo(similarAddressTo) {
  similarAddressTo = (similarAddressTo || '').trim() || undefined

  return {
    similarAddressTo,
    ...resetOffset(),
  }
}

export function setSimilarAddressTo(similarAddressTo) {
  return navigateOrderList(updateSimilarAddressTo(similarAddressTo))
}

export function updateShipToStatus(shipToStatus) {
  shipToStatus = (shipToStatus || '').trim().toLowerCase()

  shipToStatus = SHIP_TO_ADDRESS_VALIDATION_OPTIONS.find(
    ({value}) => value === shipToStatus,
  )

  return {
    shipToStatus:
      shipToStatus && shipToStatus.value ? shipToStatus.value : undefined,
    ...resetOffset(),
  }
}

export function setShipToStatus(shipToStatus) {
  return navigateOrderList(updateShipToStatus(shipToStatus))
}

export function updateShipToCountry(shipToCountry) {
  shipToCountry = (shipToCountry || '').trim().toUpperCase()

  const [, negSymbol, country] = shipToCountry.match(
    /^((?:(?:NON|NOT)(?:-)?)|-)?\s*([A-Z][A-Z])?/,
  )

  return {
    shipToCountry: country ? `${negSymbol ? '-' : ''}${country}` : undefined,
    ...resetOffset(),
  }
}

export function setShipToCountry(shipToCountry) {
  return navigateOrderList(updateShipToCountry(shipToCountry))
}

export function updateBatchReferenceID(batchReferenceID) {
  const {sort} = defaultHashParamsSelector(getState())

  batchReferenceID = (batchReferenceID || '').trim()

  return {
    batch_reference_id: batchReferenceID ? [batchReferenceID] : undefined,
    ...(batchReferenceID ? null : {sort}), // reset sort back to sticky if clearing reference ID
    ...resetOffset(),
  }
}

export function setBatchReferenceID(batchReferenceID) {
  return navigateOrderList(updateBatchReferenceID(batchReferenceID))
}

export function updateSearchText(searchText) {
  searchText = (searchText || '').trim() || undefined

  return {
    searchText,
    ...resetOffset(),
  }
}

export function updateCarrierError(carrierError) {
  return {
    carrier_error: ensureTroolean(carrierError),
    ...resetOffset(),
  }
}

export function setCarrierError(carrierError) {
  return navigateOrderList(updateCarrierError(carrierError))
}

export function updateInBatch(inBatch) {
  return {
    inBatch: ensureTroolean(inBatch),
    ...resetOffset(),
  }
}

export function setInBatch(inBatch) {
  return navigateOrderList(updateInBatch(inBatch))
}

export function setSearchText(searchText) {
  return navigateOrderList(updateSearchText(searchText))
}

export function updateLimit(limit) {
  limit = Number(limit)

  if (![10, 50, 100].includes(limit)) {
    limit = DEFAULT_PER_PAGE
  }

  if (limit === DEFAULT_PER_PAGE) {
    limit = undefined
  }

  return {
    limit,
    ...resetOffset(),
  }
}

export function setLimit(limit) {
  const updates = updateLimit(limit)

  updateOrderListForm(
    {
      sticky__limit: updates.limit,
    },
    {stickyProps: ['sticky__limit']},
  )

  return navigateOrderList(updates)
}

export function updateSort(sort) {
  if (!ALL_ORDER_SORT_OPTIONS.find(({value}) => sort === value)) {
    sort = DEFAULT_SORT
  }

  if (sort === DEFAULT_SORT) {
    sort = undefined
  }

  return {
    sort,
    ...resetOffset(),
  }
}

export function setSort(sort) {
  const updates = updateSort(sort)

  if (!BATCH_SORT_OPTIONS.find(({value}) => value === updates.sort)) {
    // don't set batch sort options as sticky

    updateOrderListForm(
      {
        sticky__sort: updates.sort,
      },
      {stickyProps: ['sticky__sort']},
    )
  }

  return navigateOrderList(updates)
}

export function updateOffset(offset) {
  offset = Number(offset) || 0

  if (!offset) {
    offset = undefined
  }

  return {
    offset,
  }
}

export function setOffset(offset) {
  return navigateOrderList(updateOffset(offset))
}

export function setQueryResults({count, orders}) {
  const selectedOrderNumbers = selectedOrderNumbersSelector(getState())
  const expandedOrderNumbers = expandedOrderNumbersSelector(getState())
  const visibleOrderNumbers = orders.map((order) => order.order_number)

  updateOrderListForm({
    visibleOrderNumbers,
    count,
    selectedOrderNumbers: selectedOrderNumbers.filter((orderNumber) =>
      visibleOrderNumbers.includes(orderNumber),
    ),
    expandedOrderNumbers: expandedOrderNumbers.filter((orderNumber) =>
      visibleOrderNumbers.includes(orderNumber),
    ),
  })
}

export function setExpandedOrderNumbers(expandedOrderNumbers) {
  updateOrderListForm({
    expandedOrderNumbers,
  })
}

export function setExpandMode(expandMode) {
  updateOrderListForm(
    {
      expandMode,
    },
    {stickyProps: ['expandMode']},
  )
}

async function getReadyForRefresh() {
  while (orderTagsHaveLoadedSelector(getState()) === false) {
    await delay(200)
  }
}

export async function refreshOrderList() {
  const currentPage = currentPageSelector(getState())
  if (currentPage !== ORDER_LIST_PAGE) {
    return
  }

  const token = (refreshOrderList.requestToken = {})

  try {
    updateOrderListForm({isLoading: true})

    acknowledgeNewOrders()

    await getReadyForRefresh()

    const params = orderListQueryParamsSelector(getState())

    const {
      json: {count, order: orders},
    } = await apiverson.get('/order/', params)

    if (token !== refreshOrderList.requestToken) {
      return
    }

    await setOrdersStateAndEnsureProductsLoaded(orders)

    setQueryResults({count, orders})

    updateOrderListForm({isLoading: false})
  } catch (error) {
    showGlobalError(
      {
        summary: 'Something went wrong while fetching the list of orders.',
        details: error.message,
      },
      error,
    )

    updateOrderListForm({isLoading: false})
  }
}

export async function refreshOrderListAndCounts() {
  await Promise.all([
    refreshOrderList(),
    updateOrderCounts(),
    updateOrderRevisionCount(),
  ])
}

export function cloneSelectedOrder() {
  const orders = activeOrdersSelector(getState())

  if (orders.length !== 1) {
    return
  }

  goToCreateOrderPage({clone_from: orders[0].order_number})
}

export function attemptToAllocateSelectedOrders() {
  const activeOrders = activeOrdersSelector(getState())

  dispatch(showConfirmAttemptToAllocateModal(activeOrders))
}

export function deallocateSelectedOrders() {
  const activeOrders = activeOrdersSelector(getState())

  dispatch(showConfirmDeallocateModal(activeOrders))
}

export function editDimensionsSelectedOrders() {
  const orderNumbers = activeOrderNumbersSelector(getState())

  const labelInfoIDs = labelInfoIDsForOrdersAndLabelTypesSelector(getState(), {
    orderNumbers,
    labelTypes: ['shipping', 'return'],
  })

  showEditOrderDimensionsModal(labelInfoIDs)
}

export function createAndApplyTagToOrders(orderNumbers) {
  showEditTagModal(TAG_TYPE__ORDER, null, {
    addTagToOrderNumbers: orderNumbers,
  })
}

export async function markDocumentsPrinted(orderNumbers) {
  for (var i = 0; i < orderNumbers.length; i++) {
    const orderNumber = encodeURIComponent(orderNumbers[i])

    await apiverson.post(`/order/${orderNumber}/mark_as_printed/`)
  }

  await refreshOrderList()
}
