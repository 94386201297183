import PropTypes from 'prop-types'

import {useSelector} from '../../store.js'
import ButtonAction from '../../common/components/Button/ButtonAction.js'
import {printURLSelector} from '../../data/mos.js'
import {showEditMOLineModal} from './Modals/EditMOLineModal.js'
import {showUpdateMOStatusModal} from './Modals/UpdateMOStatusModal.js'
import AddMOTagsDropdown from './AddMOTagsDropdown.js'

export default function ActionsForSelected({referenceIDs}) {
  const printURL = useSelector((state) =>
    printURLSelector(state, {referenceIDs}),
  )

  return (
    <>
      <li className="inline-block fs-n0 divider--left">
        <button
          className="btn btn--action v-align-base"
          type="button"
          onClick={() => showUpdateMOStatusModal(referenceIDs)}
        >
          Update Status
        </button>
      </li>

      {referenceIDs.length === 1 && (
        <li className="inline-block fs-n0 margin-left-10">
          <button
            className="btn btn--action v-align-base"
            type="button"
            onClick={() => showEditMOLineModal(referenceIDs[0])}
          >
            Add Line
          </button>
        </li>
      )}
      <AddMOTagsDropdown referenceIDs={referenceIDs} />
      <li className="inline-block fs-n0 margin-left-10">
        <ButtonAction onClick={() => window.open(printURL, '_blank')}>
          Preview/Print
        </ButtonAction>
      </li>
    </>
  )
}

ActionsForSelected.propTypes = {
  referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
}
