import PropTypes from 'prop-types'

import {useSelector} from '../../../../store.js'
import {ErrorsShape} from '../../../../common/PropTypes.js'
import NumberInput from '../../../../common/components/Form/NumberInput.js'
import ButtonLink from '../../../../common/components/Button/ButtonLink.js'
import Quantity from '../../../../common/components/Quantity.js'
import ProductFilter from '../../../../common/components/ProductFilter.js'
import {plural} from '../../../../common/components/Plural.js'
import {currencySymbolSelector} from '../../../../data/company.js'
import {
  productSelector,
  supplierLeadTimeSelector,
} from '../../../../data/products.js'
import {activeNonFBASupplierOptionsSelector} from '../../../../data/suppliers.js'
import {nonFBAWarehouseOptionsSelector} from '../../../../data/warehouses.js'
import {
  removeCreatePOLine,
  updateCreatePOLine,
  getInitialSupplierValues,
  initializeCreatePOLine,
} from './createPOModalActions.js'

export default function ProductRow({index, line, errors}) {
  const product = useSelector((state) =>
    productSelector(state, {sku: line.sku}),
  )
  const supplierOptions = useSelector((state) =>
    activeNonFBASupplierOptionsSelector(state),
  )
  const warehouseOptions = useSelector((state) =>
    nonFBAWarehouseOptionsSelector(state),
  )
  const currencySymbol = useSelector((state) => currencySymbolSelector(state))
  const supplier_lead_time = useSelector((state) =>
    product && line.supplierID
      ? supplierLeadTimeSelector(state, {
          sku: product.sku,
          supplierID: line.supplierID,
        })
      : null,
  )

  const warehouse = product
    ? product.warehouses.find(({id}) => id === line.warehouseID)
    : null

  return (
    <tr>
      <td className="table__td table__td--sm-side">
        {product ? (
          <>
            <ButtonLink
              className="darker"
              onClick={() => updateCreatePOLine(index, {sku: null})}
            >
              <div>
                <strong className="fs-n0 word-breaker margin-right-5">
                  {product.name}
                </strong>
              </div>
              <div className="word-breaker">{line.sku}</div>
              {product.kit_components.length > 0 && (
                <div className="label__callout label__callout--grey inline-block no-underline">
                  <strong>KIT</strong>
                </div>
              )}
              <div className="fs-n1 lh-md text--lt-grey">Edit</div>
            </ButtonLink>
          </>
        ) : (
          <div className="wrap--product-filter-stacked">
            <ProductFilter
              dropdown={`CREATE_PO_PRODUCT_FILTER__${index}`}
              onSelect={(product) => initializeCreatePOLine(index, product)}
              autoFocus
            />
          </div>
        )}
      </td>

      <td className="table__td table__td--sm-side">
        {product ? (
          <select
            className="select w-100"
            name="add_to_po_supplier"
            id="id_add_to_po_supplier"
            value={line.supplierID}
            onChange={(event) => {
              const supplierID = Number(event.target.value)

              updateCreatePOLine(index, {
                supplierID,
                ...getInitialSupplierValues(product, supplierID),
              })
            }}
          >
            {supplierOptions.map(({value, display}) => (
              <option key={value} value={value}>
                {display}
              </option>
            ))}
          </select>
        ) : (
          '—'
        )}
      </td>
      <td className="table__td table__td--sm-side">
        <select
          className="select w-100"
          name="add_to_po_location"
          id="id_add_to_po_location"
          value={line.warehouseID}
          onChange={(event) => {
            const warehouseID = Number(event.target.value)

            updateCreatePOLine(index, {warehouseID})
          }}
        >
          {warehouseOptions.map(({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ))}
        </select>
      </td>
      <td className="table__td table__td--sm-side">
        <NumberInput
          id="id_product_quantity"
          className="input--med-h"
          value={line.quantity}
          onChange={(value) => updateCreatePOLine(index, {quantity: value})}
          onIncrement={(value) => updateCreatePOLine(index, {quantity: value})}
          onDecrement={(value) => updateCreatePOLine(index, {quantity: value})}
          isInvalid={!!errors.quantity}
          min={0}
          autoFocus={!!product}
        />
        {errors.quantity && (
          <small className="error error-message">{errors.quantity}</small>
        )}
      </td>
      <td className="table__td table__td--sm-side">
        <div className="flex">
          <div className="wrap--input-prefix flex--justify-col">
            <strong className="fs-n1 text--md-grey">{currencySymbol}</strong>
          </div>
          <input
            type="text"
            className="input--text input--med-h input--w-prefix margin-bottom-0"
            value={line.unitPrice}
            onChange={(event) =>
              updateCreatePOLine(index, {
                unitPrice: event.target.value,
              })
            }
          />
        </div>
        {errors.unitPrice && (
          <small className="error error-message">{errors.unitPrice}</small>
        )}
      </td>
      <td className="table__td table__td--sm-side align-right">
        <strong className="fs-00 lh-md">
          {warehouse ? <Quantity value={warehouse.physical_on_hand} /> : '—'}
        </strong>
        {product && product.warehouses.length > 1 && (
          <div>
            <span className="fs-n2 lh-md op-50">
              (<Quantity value={product.total_on_hand} /> total)
            </span>
          </div>
        )}
      </td>
      <td className="table__td table__td--sm-side align-right">
        <strong className="fs-00 lh-md">
          {product ? <Quantity value={product.total_committed} /> : '—'}
        </strong>
      </td>
      <td className="table__td table__td--sm-side align-right">
        <strong className="fs-00 lh-md">
          {product ? <Quantity value={product.on_pos} /> : '—'}
        </strong>
      </td>
      <td className="table__td table__td--sm-side align-right">
        <strong className="fs-00 lh-md">
          {warehouse ? <Quantity value={warehouse.low_stock_threshold} /> : 0}
        </strong>
      </td>
      <td className="table__td table__td--sm-side">
        {product && (
          <span>
            {supplier_lead_time
              ? plural(supplier_lead_time)`${supplier_lead_time} day${['s']}`
              : '—'}
          </span>
        )}
      </td>
      <td className="table__td table__td--sm-side align-right padding-right-0">
        <button
          type="button"
          className="btn btn--link no-underline"
          onClick={() => removeCreatePOLine(index)}
        >
          <span className="i-trash fs-00" aria-hidden="true" />
        </button>
      </td>
    </tr>
  )
}

ProductRow.propTypes = {
  index: PropTypes.number.isRequired,
  line: PropTypes.shape({
    sku: PropTypes.string,
    supplierID: PropTypes.number.isRequired,
    warehouseID: PropTypes.number.isRequired,
    quantity: PropTypes.string.isRequired,
    unitPrice: PropTypes.string.isRequired,
  }),
  errors: ErrorsShape.isRequired,
}
