import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import moment from 'moment'

import {SelectOptionValue} from '../../../common/PropTypes.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import LinkButton from '../../../common/components/Link/LinkButton.js'
import ButtonSecondary from '../../../common/components/Button/ButtonSecondary.js'
import ButtonSendToPrinter from '../../../common/components/Button/ButtonSendToPrinter.js'
import {
  RMA_LABEL_VIEW_ABODE_FORM,
  abodeRMALabelLinkSelector,
} from '../../AbodeForm/abodeFormFunctions.js'
import AbodeForm from '../../AbodeForm/index.js'
import {showEmailReturnOrdersModal} from '../Modals/EmailReturnOrdersModal.js'
import {showDeleteReturnOrderLabelsModal} from '../Modals/DeleteReturnOrderLabelsModal.js'
import {rmaLabelPrinterIDSelector} from '../../../data/printerSettings.js'

function HasLabelPane({
  referenceIDs,
  abodeRMALabelLink,
  rmaLabelPrinterID,
  ...props
}) {
  return (
    <div className="fieldset--shipping-options fieldset--view-pdfs">
      <dl className="list divider--bottom">
        <dt className="list__title--label-success lh-md margin-bottom-15">
          {`Email ${
            referenceIDs.length === 1
              ? 'the return label'
              : `${referenceIDs.length} return labels`
          }:`}
        </dt>
        <dd className="flex flex-wrap">
          <ButtonPrimary
            onClick={() => props.showEmailReturnOrdersModal(referenceIDs)}
          >
            Send Email{referenceIDs.length > 1 && 's'}
          </ButtonPrimary>
          <ButtonSecondary
            onClick={() => props.showDeleteReturnOrderLabelsModal(referenceIDs)}
          >
            Delete Label{referenceIDs.length > 1 && 's'}
          </ButtonSecondary>
        </dd>
      </dl>

      <dl className="list">
        <dt className="list__title--label-success lh-md margin-bottom-15">
          {`Or print ${
            referenceIDs.length === 1
              ? 'the return label'
              : `${referenceIDs.length} return labels`
          }:`}
        </dt>
        <dd>
          <AbodeForm
            formName={RMA_LABEL_VIEW_ABODE_FORM}
            docTypes={['label']}
          />
        </dd>
        <dd className="flex flex-wrap">
          {abodeRMALabelLink && (
            <>
              <div className="margin-right-10">
                <LinkButton
                  mode="primary"
                  className="btn--primary-alt btn--md"
                  href={abodeRMALabelLink}
                  target="_blank"
                >
                  View Label{referenceIDs.length > 1 && 's'}
                </LinkButton>
              </div>
              <div className="margin-right-10">
                <ButtonSendToPrinter
                  className="margin-bottom-5"
                  title="RMA Label"
                  pdfLink={abodeRMALabelLink}
                  printerID={rmaLabelPrinterID}
                  documentCount={referenceIDs.length}
                />
              </div>
            </>
          )}
          <div>
            <ButtonSecondary
              onClick={() =>
                props.showDeleteReturnOrderLabelsModal(referenceIDs)
              }
            >
              Delete Label{referenceIDs.length > 1 && 's'}
            </ButtonSecondary>
          </div>
        </dd>
      </dl>
    </div>
  )
}

HasLabelPane.propTypes = {
  referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  abodeRMALabelLink: PropTypes.string,
  rmaLabelPrinterID: SelectOptionValue.isRequired,
  showEmailReturnOrdersModal: PropTypes.func.isRequired,
  showDeleteReturnOrderLabelsModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {referenceIDs}) {
  return {
    abodeRMALabelLink: abodeRMALabelLinkSelector(state, {
      formName: RMA_LABEL_VIEW_ABODE_FORM,
      referenceIDs,
      utcOffset: moment().utcOffset(),
      docTypes: ['label'],
    }),
    rmaLabelPrinterID: rmaLabelPrinterIDSelector(state),
  }
}

const mapDispatchToProps = {
  showEmailReturnOrdersModal,
  showDeleteReturnOrderLabelsModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(HasLabelPane)
