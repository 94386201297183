import PropTypes from 'prop-types'
import format from 'date-fns/format'

import {
  getState,
  setForm,
  updateForm,
  setFormValue,
  removeForm,
  formsSelector,
  onlyIfForm,
  useSelector,
} from '../../../store.js'
import {getRealDate} from '../../../common/date.js'
import {isPresent, isNonZeroPositiveNumeric} from '../../../common/utils.js'
import apiverson from '../../../common/apiverson.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import ProductFilter from '../../../common/components/ProductFilter.js'
import Select from '../../../common/components/Select.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import NumberInput from '../../../common/components/Form/NumberInput.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import Quantity from '../../../common/components/Quantity.js'
import {setMOStateAndEnsureProductsLoaded} from '../../../data/mos.js'
import {
  defaultWarehouseSelector,
  nonFBAWarehouseOptionsSelector,
} from '../../../data/warehouses.js'
import {
  setProduct,
  ensureLinkedSKUsLoaded,
  productSelector,
  productBOMComponentsSelector,
  getProductName,
} from '../../../data/products.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {refreshMOList} from '../moListActions.js'
import {navigateToMODetailPage} from '../../MODetailPage/moDetailActions.js'

export const MODAL_FORM = 'CREATE_MO_MODAL'

const PREFIX = 'MO-'

export async function showCreateMOModal({skus = []} = {}) {
  const date = getRealDate()
  const defaultWarehouse = defaultWarehouseSelector(getState())

  setForm(MODAL_FORM, {
    referenceID: format(date, 'yyyyMMdd-HHmmss'),
    referenceIDIsDuplicated: false,
    warehouse_id: defaultWarehouse ? defaultWarehouse.id : undefined,
    internal_notes: '',
    manufacturer_notes: '',
    lines: [],
    isSaving: false,
    serverError: null,
  })

  await checkMOReferenceID()

  for (const sku of skus) {
    addLine(sku)
  }

  await ensureLinkedSKUsLoaded(skus)
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function setModalFormValue(path, value) {
  return setFormValue(MODAL_FORM, path, value)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  const forms = formsSelector(state)

  return forms[MODAL_FORM]
}

export function errorsSelector(state) {
  const {referenceID, referenceIDIsDuplicated, lines} = modalFormSelector(state)
  const errors = {}

  if (!isPresent(referenceID)) {
    errors.referenceID = 'Reference ID is required'
    errors.preventSave = true
  } else if (referenceIDIsDuplicated) {
    errors.referenceID = 'Reference ID is already in use'
    errors.preventSave = true
  }

  if (lines.length === 0) {
    errors.lines = 'At least one line must be added'
    errors.preventSave = true
  }

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]

    if (!isPresent(line.sku)) {
      errors[`lines__${i}__sku`] = 'a Finished SKU is required'
      errors.preventSave = true
    }

    if (!isNonZeroPositiveNumeric(line.requested_quantity)) {
      errors[`lines__${i}__requested_quantity`] =
        'Requested Qty must be a positive number'
      errors.preventSave = true
    }
  }

  return errors
}

export async function checkMOReferenceID() {
  const token = {}
  checkMOReferenceID.token = token

  const {referenceID} = modalFormSelector(getState())

  if (!referenceID) {
    updateModalForm({referenceIDIsDuplicated: false})

    return
  }

  try {
    updateModalForm({isSaving: true, serverError: null})

    await apiverson.get(
      `/manufacturing_order/${PREFIX}${encodeURIComponent(referenceID)}`,
    )

    if (token !== checkMOReferenceID.token) {
      return
    }

    updateModalForm({referenceIDIsDuplicated: true})
  } catch (err) {
    if (token !== checkMOReferenceID.token) {
      return
    }

    if (err.response && err.response.status === 404) {
      updateModalForm({referenceIDIsDuplicated: false})
    } else {
      updateModalForm({
        serverError: `Error checking MO reference ID: ${
          err.message || err.error_message
        }`,
      })
    }
  } finally {
    if (token === checkMOReferenceID.token) {
      updateModalForm({isSaving: false})
    }
  }
}

export function addLine(sku = null) {
  const {lines} = modalFormSelector(getState())

  updateModalForm({
    lines: [
      ...lines,
      {
        sku,
        requested_quantity: '1',
        internal_notes: '',
        manufacturer_notes: '',
      },
    ],
  })
}

export function setSKU(index, product) {
  setProduct(product)

  setModalFormValue(`lines.${index}.sku`, product.sku)
}

export function removeLine(index) {
  const {lines} = modalFormSelector(getState())

  updateModalForm({
    lines: lines.filter((l, i) => i !== index),
  })
}

export async function createMO({goToMODetails} = {}) {
  try {
    const {
      referenceID,
      warehouse_id,
      internal_notes,
      manufacturer_notes,
      lines,
    } = modalFormSelector(getState())

    updateModalForm({isSaving: true, serverError: null})

    const {json: mo} = await apiverson.post('/manufacturing_order', {
      reference_id: referenceID,
      warehouse_id,
      internal_notes,
      manufacturer_notes,
      lines: lines.map(
        ({sku, requested_quantity, internal_notes, manufacturer_notes}) => ({
          sku,
          requested_quantity: Number(requested_quantity),
          internal_notes,
          manufacturer_notes,
        }),
      ),
    })

    await setMOStateAndEnsureProductsLoaded(mo)

    if (goToMODetails) {
      // navigate to details when it exists and not refresh list
      navigateToMODetailPage(mo.reference_id)
    } else {
      refreshMOList()
    }

    showMessageToast('MFG Order was successfully created.')

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error creating MFG order: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function Line({index, line}) {
  const errors = useSelector(errorsSelector)
  const product = useSelector((state) =>
    productSelector(state, {sku: line.sku}),
  )
  const bomComponents = useSelector((state) =>
    productBOMComponentsSelector(state, {sku: line.sku}),
  )

  return (
    <tr>
      <td className="table__td">
        <div className="wrap--product-filter-stacked">
          {product ? (
            <ButtonLink
              className="darker align-left"
              onClick={() => setModalFormValue(`lines.${index}.sku`, null)}
            >
              <div className="fs-n0">
                <strong>{getProductName(product)}</strong>
              </div>
              <div className="fs-n1">SKU: {product.sku}</div>
              <div className="fs-n1 lh-md text--lt-grey">Edit</div>
            </ButtonLink>
          ) : (
            <ProductFilter
              queryParams={{manufactured: true}}
              dropdown={`CREATE_MO_MODAL_PRODUCT_SEARCH__${index}`}
              onSelect={(product) => {
                setModalFormValue(`lines.${index}.sku`, product.sku)

                ensureLinkedSKUsLoaded([product.sku])
              }}
              autoFocus
            />
          )}
        </div>
      </td>
      <td className="table__td">
        {product && (
          <>
            {bomComponents.length ? (
              <ul className="list">
                {bomComponents.map((component) => (
                  <li
                    className="list__item list__item--no-style flex--justify divider--bottom"
                    key={component.id}
                  >
                    <div className="fs-n1 lh-md w-85">
                      <strong>{component.name}</strong>
                      <div>{component.sku}</div>
                    </div>
                    <div className="fs-n1 lh-md">
                      <Quantity value={component.quantity} />
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div>
                <span className="spinner--sm v-align-middle" />
                <strong className="inline-block v-align-middle fs-00 op-75 margin-left-5">
                  Loading...
                </strong>
              </div>
            )}
          </>
        )}
      </td>
      <td className="table__td">
        <NumberInput
          className="margin-bottom-0"
          value={line.requested_quantity}
          onChange={(value) =>
            setModalFormValue(`lines.${index}.requested_quantity`, `${value}`)
          }
          autoFocus={!!line.sku}
        />
        {errors[`lines__${index}__requested_quantity`] && (
          <small className="error">
            {errors[`lines__${index}__requested_quantity`]}
          </small>
        )}
      </td>
      <td className="table__td">
        <TextArea
          value={line.manufacturer_notes}
          onChange={(value) =>
            setModalFormValue(`lines.${index}.manufacturer_notes`, value)
          }
        />
      </td>
      <td className="table__td">
        <TextArea
          value={line.internal_notes}
          onChange={(value) =>
            setModalFormValue(`lines.${index}.internal_notes`, value)
          }
        />
      </td>
      <td className="table__td align-right">
        <ButtonLink
          className="no-underline"
          title="Remove line"
          onClick={() => removeLine(index)}
        >
          <span className="i-trash fs-00" aria-hidden="true"></span>
        </ButtonLink>
      </td>
    </tr>
  )
}

const LineShape = PropTypes.shape({
  sku: PropTypes.string,
  requested_quantity: PropTypes.string.isRequired,
  internal_notes: PropTypes.string.isRequired,
  manufacturer_notes: PropTypes.string.isRequired,
})

Line.propTypes = {
  index: PropTypes.number.isRequired,
  line: LineShape.isRequired,
}

function CreateMOModal({form}) {
  const errors = useSelector(errorsSelector)
  const warehouseOptions = useSelector(nonFBAWarehouseOptionsSelector)

  return (
    <ConfirmModal
      title="Initiate a MFG Order"
      modalSize="lg"
      confirmText="Create MFG Order"
      onConfirm={() => createMO()}
      preventHotKeyConfirm
      MiddleButtons={() => (
        <ButtonPrimary
          alt
          className="margin-right-10"
          isDisabled={errors.preventSave || form.isSaving}
          isLoading={form.isSaving}
          onClick={() => createMO({goToMODetails: true})}
        >
          Create and View MFG Order
        </ButtonPrimary>
      )}
      cancelText="Cancel"
      onCancel={() => closeModal()}
      isSaving={form.isSaving}
      isDisabled={errors.preventSave}
      error={form.serverError}
    >
      <div className="row">
        <div className="medium-7 columns">
          <ul className="list list--no-style margin-bottom-30">
            <li className="list__item margin-bottom-20">
              <TextInput
                label="MFG Order ID"
                id="referenceID"
                prefix={PREFIX}
                className="input--med-h"
                value={form.referenceID}
                onChange={(value) => updateModalForm({referenceID: value})}
                onBlur={() => checkMOReferenceID()}
                errorMessage={errors.referenceID}
              />
            </li>
            <li className="list__item margin-bottom-20">
              <Select
                label="Assigned Warehouse"
                id="warehouse_id"
                value={form.warehouse_id}
                onChange={(id) => updateModalForm({warehouse_id: Number(id)})}
              >
                {warehouseOptions.map(({value, display}) => (
                  <option key={value} value={value}>
                    {display}
                  </option>
                ))}
              </Select>
            </li>

            <li className="list__item margin-bottom-20">
              <TextArea
                className="fs-n0 lh-lg"
                label="Notes for Manufacturer"
                id="manufacturer_notes"
                value={form.manufacturer_notes}
                onChange={(value) =>
                  updateModalForm({manufacturer_notes: value})
                }
              />
            </li>
            <li className="list__item margin-bottom-0">
              <TextArea
                className="fs-n0 lh-lg"
                label="Internal Notes"
                id="internal_notes"
                value={form.internal_notes}
                onChange={(value) => updateModalForm({internal_notes: value})}
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="medium-12 columns">
          <p className="fs-01 margin-bottom-15">
            <strong>Products to be Manufactured</strong>
          </p>
          <table className="table">
            <thead>
              <tr>
                <th className="table__th table__th--sm w-25">Finished SKU</th>
                <th className="table__th table__th--sm w-20">BOM Components</th>
                <th className="table__th table__th--sm w-10">Req Qty</th>
                <th className="table__th table__th--sm w-20">
                  Notes for Manufacturer
                </th>
                <th className="table__th table__th--sm w-20">Internal Notes</th>

                <th className="table__th table__th--sm"></th>
              </tr>
            </thead>
            <tbody className="table__tbody--lines">
              {form.lines.map((line, index) => (
                <Line key={index} index={index} line={line} />
              ))}
              <tr>
                <td
                  className="table__td table__td--product-search padding-left-0 align-center"
                  colSpan="6"
                >
                  <ButtonPrimary isOutlined size="sm" onClick={() => addLine()}>
                    Add a Finished SKU
                  </ButtonPrimary>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ConfirmModal>
  )
}

CreateMOModal.propTypes = {
  form: PropTypes.shape({
    referenceID: PropTypes.string.isRequired,
    referenceIDIsDuplicated: PropTypes.bool.isRequired,
    warehouse_id: PropTypes.number.isRequired,
    internal_notes: PropTypes.string.isRequired,
    manufacturer_notes: PropTypes.string.isRequired,
    lines: PropTypes.arrayOf(LineShape).isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(CreateMOModal, modalFormSelector)
