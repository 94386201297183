import AbodeForm from '../../../ordoro/AbodeForm/index.js'
import {packingListPrinterIDSelector} from '../../../data/printerSettings.js'
import {
  abodePickPackLinkSelector,
  atLeastOneSplitOrderSelector,
  openAbodeTab,
  pickOrPackIsSelectedSelector,
  sendAbodeRenderToPrintNode,
} from '../../../ordoro/AbodeForm/abodeFormFunctions.js'
import ButtonSendToPrinter from '../Button/ButtonSendToPrinter.js'
import ErrorMessage from '../ErrorMessage.js'
import {
  formsSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../../store.js'
import {getUTCOffset} from '../../date.js'
import ConfirmModal from './ConfirmModal.js'
import {useAbodeRenderLabelsSelector} from '../../../data/company.js'

const MODAL_FORM = 'PRINT_PICK_PACK_MODAL'
const PRINT_PICK_PACK_MODAL_FORM = 'PRINT_PICK_PACK_MODAL_FORM'

export function showPrintPickPackModal(orderNumbers) {
  setForm(MODAL_FORM, {
    orderNumbers,
    isPrinting: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

export async function viewPickPackDocument(pdfLink) {
  const useAbodeRenderLabels = useAbodeRenderLabelsSelector(getState())

  if (useAbodeRenderLabels) {
    try {
      const {orderNumbers} = modalFormSelector(getState())
      updateModalForm({
        serverError: null,
        isPrinting: true,
      })

      await openAbodeTab({
        formName: PRINT_PICK_PACK_MODAL_FORM,
        orderNumbers,
        utcOffset: getUTCOffset(),
        docTypes: ['pick', 'pack'],
      })

      closeModal()
    } catch (err) {
      updateModalForm({
        serverError: err.error_message || err.message,
        isPrinting: false,
      })
    }
  } else {
    window.open(pdfLink, '_blank')

    closeModal()
  }
}

async function handleButtonSentToPrintClick({canPrint, printButtonFormKey}) {
  // when we need to navigate to settings to setup printers
  if (!canPrint) {
    closeModal()

    return
  }

  const useAbodeRenderLabels = useAbodeRenderLabelsSelector(getState())

  if (useAbodeRenderLabels) {
    try {
      const printerID = packingListPrinterIDSelector(getState())
      const {orderNumbers} = modalFormSelector(getState())

      updateModalForm({
        serverError: null,
        isPrinting: true,
      })

      await sendAbodeRenderToPrintNode({
        formName: PRINT_PICK_PACK_MODAL_FORM,
        orderNumbers,
        utcOffset: getUTCOffset(),
        docTypes: ['pick', 'pack'],
        printButtonFormKey,
        printerID,
        documentCount: orderNumbers.length,
      })

      closeModal()
    } catch (err) {
      updateModalForm({
        serverError: err.error_message || err.message,
        isPrinting: false,
      })
    }
  }
}

function PrintPickPackModal({form}) {
  const utcOffset = getUTCOffset()
  const docTypes = ['pick', 'pack']
  const {orderNumbers} = form

  const abodePickPackLink = useSelector((state) =>
    abodePickPackLinkSelector(state, {
      formName: PRINT_PICK_PACK_MODAL_FORM,
      orderNumbers,
      utcOffset,
      docTypes,
    }),
  )
  const atLeastOneSplitOrder = useSelector((state) =>
    atLeastOneSplitOrderSelector(state, {
      orderNumbers,
    }),
  )
  const pickOrPackIsSelected = useSelector((state) =>
    pickOrPackIsSelectedSelector(state, {
      formName: PRINT_PICK_PACK_MODAL_FORM,
    }),
  )
  const packingListPrinterID = useSelector(packingListPrinterIDSelector)

  const isDisabled = !abodePickPackLink || !pickOrPackIsSelected

  return (
    <ConfirmModal
      title="Print Pick/Pack Lists"
      modalSize="md"
      onConfirm={() => viewPickPackDocument(abodePickPackLink)}
      onCancel={() => closeModal()}
      confirmText="View/Print"
      cancelText="Close"
      middleButtons={
        <div className="margin-right-10">
          <ButtonSendToPrinter
            className="margin-bottom-5"
            title="Pick/Pack List"
            pdfLink={abodePickPackLink}
            printerID={packingListPrinterID}
            documentCount={orderNumbers.length}
            onClick={({canPrint, printButtonFormKey}) =>
              handleButtonSentToPrintClick({
                canPrint,
                printButtonFormKey,
              })
            }
            isDisabled={isDisabled}
          />
        </div>
      }
      isSaving={form.isPrinting}
      isDisabled={isDisabled}
      error={form.serverError}
    >
      <AbodeForm
        formName={PRINT_PICK_PACK_MODAL_FORM}
        docTypes={docTypes}
        fromModal
        includeSplitQuantitiesCheckbox={atLeastOneSplitOrder}
        allowPrintConfigs
      />
      {!pickOrPackIsSelected && (
        <ErrorMessage>
          Choose which type of list you would like to print from the Document
          Options
        </ErrorMessage>
      )}
    </ConfirmModal>
  )
}

export default onlyIfForm(PrintPickPackModal, modalFormSelector)
