import {numberToEnglish} from '../numbers.js'

/**
 * Format a number
 *
 * @param {Object} props
 * @param {Number|String} props.value
 * @param {Number} [props.digits]
 * @param {Object} [props.options]
 * @returns {String}
 */
export function quantity({
  value,
  digits,
  options: {toEnglish, ...options} = {},
}) {
  let number = Number(value || 0)

  if (digits !== undefined) {
    options = {
      maximumFractionDigits: digits,
      ...options,
    }
  }

  return toEnglish
    ? numberToEnglish(number)
    : number.toLocaleString('en-us', options)
}

/**
 * Format a number
 *
 * @param {Object} props
 * @param {String} [props.className]
 * @param {Number|String} props.value
 * @param {Number} [props.digits]
 * @param {Object} [props.options]
 * @returns {ReactNode}
 */
export default function Quantity({className, value, digits, options}) {
  return <span className={className}>{quantity({value, digits, options})}</span>
}
