import NumberInput from './NumberInput.js'
import {
  labelViewFormAbodeSelector,
  setLabelLeftMargin,
  setLabelTopMargin,
} from '../abodeFormFunctions.js'
import {useSelector} from '../../../store.js'

export default function PageMargins({formName}) {
  const {label_top_margin, label_left_margin} = useSelector((state) =>
    labelViewFormAbodeSelector(state, {formName}),
  )

  return (
    <div className="flex-item">
      <label className="label--bold">Label Margins</label>
      <div className="flex flex-wrap-margin-10">
        <NumberInput
          text="Top"
          id="margin-top"
          value={label_top_margin || ''}
          onChange={(event) => setLabelTopMargin(formName, event.target.value)}
        />
        <NumberInput
          text="Left"
          id="margin-left"
          value={label_left_margin || ''}
          onChange={(event) => setLabelLeftMargin(formName, event.target.value)}
        />
      </div>
    </div>
  )
}
