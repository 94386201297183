import PropTypes from 'prop-types'
import snakeCase from 'lodash/snakeCase.js'
import pick from 'lodash/pick.js'

import {
  getState,
  setForm,
  updateForm,
  removeForm,
  formsSelector,
  onlyIfForm,
  dispatch,
  useSelector,
} from '../../../store.js'
import {
  PluralBlock,
  Plural,
  Count,
  plural,
} from '../../../common/components/Plural.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {checkRunningTasks} from '../../../redux/actions/data/isRunningTasks.js'
import apiverson from '../../../common/apiverson.js'
import {getUTCOffset} from '../../../common/date.js'
import {setBatch} from '../../../data/batches.js'
import AbodeForm from '../../AbodeForm/index.js'
import {
  BATCH_LABEL_VIEW_ABODE_FORM,
  BATCH_PICK_PACK_ABODE_FORM,
  abodeParamsSelector,
} from '../../AbodeForm/abodeFormFunctions.js'
import ButtonSendToPrinter from '../../../common/components/Button/ButtonSendToPrinter.js'
import {
  labelPrinterIDSelector,
  packingListPrinterIDSelector,
} from '../../../data/printerSettings.js'
import {printerNameSelector} from '../../../data/printNode.js'
import {BATCH_CREATE_DOCUMENT_COMMENT} from '../../../common/constants/Comments.js'
import {join} from '../../../common/components/Join.js'

const MODAL_FORM = 'CREATE_LABELS_MODAL'

export function showCreateLabelsModal({referenceIDs, includeLabels = true}) {
  setForm(MODAL_FORM, {
    referenceIDs,
    includeLabels,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  updateForm(MODAL_FORM, updates)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

function errorsSelector(state) {
  const errors = {}
  const [
    {
      params: {actions},
    },
  ] = batchCreateLabelsPayloadSelector(state)

  if (actions.length === 0) {
    errors.general = 'Please attach at least one document type'
    errors.preventSave = true
  }

  return errors
}

function deCamelCase(obj) {
  const newObj = {}

  for (const [key, value] of Object.entries(obj)) {
    newObj[snakeCase(key)] = value
  }

  return newObj
}

export function batchCreateLabelsPayloadSelector(state, {printerID} = {}) {
  const {referenceIDs, includeLabels} = modalFormSelector(state)
  const abodeParams = deCamelCase(
    abodeParamsSelector(state, {
      formName: includeLabels
        ? BATCH_LABEL_VIEW_ABODE_FORM
        : BATCH_PICK_PACK_ABODE_FORM,
      utcOffset: `${getUTCOffset()}`,
      docTypes: includeLabels ? ['pick', 'pack', 'label'] : ['pick', 'pack'],
    }),
  )
  const name = []

  abodeParams.packing_list_id =
    (Array.isArray(abodeParams.template) && abodeParams.template[0]) ||
    undefined

  const params = {
    action: {type: 'mult-stage-processing'},
    actions: [],
  }
  const comment = {
    type: BATCH_CREATE_DOCUMENT_COMMENT,
    docs: [...(includeLabels ? ['label'] : []), ...abodeParams.docs],
  }

  let labelIndex
  if (includeLabels) {
    params.actions.push(
      {
        type: 'generate_label',
        stop_on_error: true,
        data: {},
      },
      {
        type: 'render_label',
        stop_on_error: true,
        data: {
          render_options: pick(abodeParams, [
            'layout',
            'show_logo_on_label',
            'label_width',
            'label_height',
            'label_left_margin',
            'label_top_margin',
            'enable_envelope',
            ...(abodeParams.packing_list_id ? ['packing_list_id'] : []),
          ]),
        },
      },
    )

    labelIndex = params.actions.length - 1

    name.push('Labels')
  }

  let packIndex
  if (abodeParams.docs.includes('packing')) {
    packIndex = params.actions.length

    params.actions.push({
      type: 'render_packing_list',
      stop_on_error: true,
      data: {
        render_options: pick(abodeParams, [
          'utc_offset',
          'layout',
          'tray_number',
          'packing_list_sort',
          'show_barcode',
          'show_packing_list_images',
          'packing_list_kit_view_mode',
          'show_ordoro_product_name',
          'show_sibling_order_quantities',
          'show_zero_quantity_lines',
          'show_order_tray',
          'packing_list_layout',
          'show_bill_to',
          'show_price',
          'show_pack_serial_numbers',
          'show_warehouse_location',
          'show_shipping_info',
          'show_customer_notes',
          'highlight_pack_line_qty',
          'show_packing_list_details',
          'show_pack_sku_barcode',
          ...(abodeParams.packing_list_id ? ['packing_list_id'] : []),
        ]),
      },
    })

    name.push('Packing')
  }

  let pickIndex
  if (abodeParams.docs.includes('pick')) {
    pickIndex = params.actions.length

    params.actions.push({
      type: 'render_pick_list',

      stop_on_error: true,
      data: {
        render_options: {
          name: 'Pick-List',
          ...pick(abodeParams, [
            'layout',
            'pick_list_sort',
            'show_pick_list_images',
            'pick_list_kit_view_mode',
            'show_order_tray',
            'pick_list_layout',
            'show_pick_serial_numbers',
            'show_pick_list_total_items',
            'show_pick_list_total_skus',
            'show_pick_list_total_orders',
            'highlight_pick_line_qty',
            'show_pick_list_poh',
            'show_pick_sku_barcode',
          ]),
        },
      },
    })

    name.push('Pick')
  }

  if (name.length > 0) {
    params.actions.push({
      type: 'render_lpp',
      stop_on_error: true,
      data: {
        render_options: {
          name:
            name.join('_') +
            (name.includes('Packing') || name.includes('Pick') ? '-List' : ''),
          side_by_side: ['twoperpage_v2', 'twoperpage'].includes(
            abodeParams.layout,
          ),
          ...pick(abodeParams, [
            'break_page_on_order',
            'label_left_always',
            'label_right_always',
          ]),
        },
        ...(labelIndex !== undefined
          ? {label: `$actions.${labelIndex}.output.*.full_cache_path`}
          : undefined),
        ...(packIndex !== undefined
          ? {packing_list: `$actions.${packIndex}.output.*.full_cache_path`}
          : undefined),
        ...(pickIndex !== undefined
          ? {pick_list: `$actions.${pickIndex}.output.-1.full_cache_path`}
          : undefined),
      },
    })

    if (printerID) {
      params.actions.push({
        type: 'send_to_printer',
        stop_on_error: true,
        data: {
          content_type: 'pdf_uri',
          printer_id: printerID,
          content_url: '$previous.output.-1.cache_url',
        },
      })

      if (includeLabels) {
        params.actions.push({
          type: 'endpoint',
          data: {
            method: 'post',
            url_parts: ['mark_as_printed'],
          },
        })
      }

      comment.printer_name = printerNameSelector(state, {
        printerID,
      })
    }
  }

  const toastParts = []
  if (name.includes('Labels')) {
    toastParts.push('labels')
  }
  if (name.includes('Packing')) {
    toastParts.push('packing lists')
  }
  if (name.includes('Pick')) {
    toastParts.push(plural(referenceIDs)`pick list${['s']}`)
  }

  const toast = plural(referenceIDs)`A task was started to create ${join(
    toastParts,
  )} for ${Count} batch${['es']}.`

  return [{params, comment: JSON.stringify(comment)}, toast]
}

export async function createLabels(referenceID, payload) {
  await apiverson.post(
    `/batch/${encodeURIComponent(referenceID)}/action`,
    payload,
  )

  const {json} = await apiverson.get(
    `/batch/${encodeURIComponent(referenceID)}`,
  )

  setBatch(json)
}

export async function createLabelsAll(printerID) {
  const {referenceIDs} = modalFormSelector(getState())

  try {
    updateModalForm({isSaving: true})

    const [payload, toast] = batchCreateLabelsPayloadSelector(getState(), {
      printerID,
    })

    await Promise.all(
      referenceIDs.map((referenceID) => createLabels(referenceID, payload)),
    )

    dispatch(checkRunningTasks())

    showMessageToast(toast)

    closeModal()
  } catch (err) {
    updateModalForm({
      serverError: `Error creating documents for batches: ${
        err.message || err.error_message
      }`,
      isSaving: false,
    })
  }
}

function CreateLabelsModal({form}) {
  const labelPrinterID = useSelector(labelPrinterIDSelector)
  const packingListPrinterID = useSelector(packingListPrinterIDSelector)
  const errors = useSelector(errorsSelector)
  const printerID = form.includeLabels ? labelPrinterID : packingListPrinterID

  return (
    <ConfirmModal
      title={form.includeLabels ? 'Create Labels' : 'Create Pick/Pack Lists'}
      onConfirm={() => createLabelsAll()}
      onCancel={() => closeModal()}
      confirmText="Create"
      cancelText="Cancel"
      middleButtons={
        <div className="margin-right-10">
          <ButtonSendToPrinter
            className="margin-bottom-5"
            title="Batch Label"
            buttonText="Create and InstaPrint"
            printerID={printerID}
            onClick={({canPrint}) => {
              if (canPrint) {
                createLabelsAll(printerID)
              }

              closeModal()
            }}
            isDisabled={errors.preventSave}
          />
        </div>
      }
      isSaving={form.isSaving}
      error={form.serverError}
      isDisabled={errors.preventSave}
    >
      <p className="fs-01 lh-md">
        <strong>
          <PluralBlock array={form.referenceIDs}>
            {form.includeLabels ? (
              <>
                Create labels for <Count /> batch
                <Plural p="es" />?
              </>
            ) : (
              <>
                Create pick/pack lists for <Count /> batch
                <Plural p="es" />?
              </>
            )}
          </PluralBlock>
        </strong>
      </p>
      <AbodeForm
        formName={
          form.includeLabels
            ? BATCH_LABEL_VIEW_ABODE_FORM
            : BATCH_PICK_PACK_ABODE_FORM
        }
        docTypes={
          form.includeLabels ? ['label', 'pick', 'pack'] : ['pick', 'pack']
        }
        includeSplitQuantitiesCheckbox
        fromModal
        allowPrintConfigs
      />
    </ConfirmModal>
  )
}

CreateLabelsModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    includeLabels: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
}

export default onlyIfForm(CreateLabelsModal, modalFormSelector)
