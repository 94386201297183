import {companySelector} from '../data/company.js'
import {
  accountHaveLoadedSelector,
  dailyPurchaseLimitSelector,
} from './account.js'

export function canBeTrustedSelector(state) {
  return companySelector(state).trusted === null
    ? null
    : accountHaveLoadedSelector(state)
      ? dailyPurchaseLimitSelector(state) > 0
      : null
}
