import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty.js'

import {isPresent} from '../../common/utils.js'
import {
  MANUAL,
  FULFILLMENT_CHANNEL_FBA,
} from '../../common/constants/CartVendorOptions.js'
import Checkbox from '../../common/components/Checkbox.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import PrettyNumber from '../../common/components/PrettyNumber.js'
import FormattedDate, {
  formatDate,
} from '../../common/components/FormattedDate.js'
import handleListSelection from '../../common/handleListSelection.js'
import {showEditProductCartModal} from '../ProductListPage/Modals/EditProductCartModal.js'
import {showFulfillmentLatencyModal} from '../ProductListPage/Modals/FulfillmentLatencyModal.js'
import {showAmazonFulfillmentModal} from '../ProductListPage/Modals/AmazonFulfillmentModal.js'
import {updateProductDetailForm} from './productDetailActions.js'
import {
  cartsSelector,
  getCartName,
  getIsAmazonCart,
  cartsCanHaveFulfillmentLatencySelector,
  cartsHaveLinkedWarehousesSelector,
} from '../../data/carts.js'
import {
  productCartsSelector,
  getProductCartFulfillmentChannel,
  getProductCartFulfillmentLatency,
} from '../../data/products.js'
import {
  productDetailFormSelector,
  skuSelector,
  productCartIDsSelector,
  areAllCartsSelectedSelector,
  areIndeterminateCartsSelectedSelector,
} from './productDetailSelectors.js'
import {showMarkNeedsSyncModal} from '../ProductListPage/Modals/MarkNeedsSyncModal.js'
import {dispatch, useSelector} from '../../store.js'
import {CartShape} from '../../common/PropTypes.js'
import {quantity} from '../../common/components/Quantity.js'

function SalesChannel({
  sku,
  productCart,
  selectedCartIDs,
  carts,
  productCartIDs,
  cartsCanHaveFulfillmentLatency,
  cartsHaveLinkedWarehouses,
}) {
  const fulfillmentLatency = getProductCartFulfillmentLatency(productCart)
  const fulfillmentChannel = getProductCartFulfillmentChannel(productCart)
  const syncSuccess =
    isEmpty(productCart.audit.latest_export) ||
    productCart.audit.latest_export.success

  const cart = carts[productCart.id]

  if (!cart) {
    return null
  }

  return (
    <tr key={productCart.id}>
      <td className="table__td">
        <Checkbox
          key={productCart.id}
          className="margin-bottom-0"
          checked={selectedCartIDs.includes(productCart.id)}
          onClick={(checked, event) =>
            updateProductDetailForm({
              selectedCartIDs: handleListSelection({
                value: productCart.id,
                isSelected: checked,
                isShiftKey: event.shiftKey,
                selected: selectedCartIDs,
                list: productCartIDs,
              }),
            })
          }
        />
      </td>
      <td className="table__td table__td--md">
        <strong>{getCartName(cart)}</strong>
        {getIsAmazonCart(cart) && (
          <div className="text--md-grey fs-n1 margin-top-5">
            <strong>Fulfillment:</strong>{' '}
            <ButtonLink
              className="mid v-align-base lh-sm"
              onClick={() =>
                dispatch(
                  showAmazonFulfillmentModal(
                    sku,
                    productCart.id,
                    fulfillmentChannel,
                  ),
                )
              }
            >
              {fulfillmentChannel === FULFILLMENT_CHANNEL_FBA ? 'FBA' : 'FBM'}
            </ButtonLink>
          </div>
        )}
        {cartsCanHaveFulfillmentLatency[productCart.id] &&
          fulfillmentChannel !== FULFILLMENT_CHANNEL_FBA && (
            <div className="text--md-grey fs-n1 margin-top-3">
              <strong>Latency:</strong>{' '}
              <ButtonLink
                className="mid v-align-base lh-sm"
                onClick={() =>
                  dispatch(
                    showFulfillmentLatencyModal(
                      sku,
                      productCart.id,
                      fulfillmentLatency,
                    ),
                  )
                }
              >
                {isPresent(fulfillmentLatency)
                  ? `${fulfillmentLatency} Day${
                      fulfillmentLatency === 1 ? '' : 's'
                    }`
                  : 'Not Set'}
              </ButtonLink>
            </div>
          )}
      </td>
      <td className="table__td table__td--md">
        {productCart.audit.latest_import.created ? (
          <>
            <div className="margin-bottom-3">
              <FormattedDate
                value={productCart.audit.latest_import.created}
                format="MMM D, YYYY [at] h:mma"
              />
            </div>
            <div>
              <span className="text--md-grey">
                {`(${quantity({value: productCart.audit.latest_import.quantity})} unit${
                  productCart.audit.latest_import.quantity === 1 ? '' : 's'
                })`}
              </span>{' '}
              <span
                className="tooltip--top fs-01 v-align-top"
                data-hint={`First Import - ${formatDate(
                  productCart.audit.first_import.created,
                  'MMM D, YYYY [at] h:mma',
                )} (${quantity({value: productCart.audit.first_import.quantity})} unit${
                  productCart.audit.first_import.quantity === 1 ? '' : 's'
                })`}
              >
                <span
                  className="i--info v-align-middle i--lh-0 text--lt-grey"
                  aria-hidden="true"
                />
              </span>
            </div>
          </>
        ) : (
          '—'
        )}
      </td>
      <td className="table__td table__td--md">
        {productCart.vendor === MANUAL ? (
          '—'
        ) : cartsHaveLinkedWarehouses[productCart.id] ? (
          <ButtonLink
            className="darker v-align-base lh-sm"
            onClick={() =>
              showEditProductCartModal(sku, [productCart.id], 'sync')
            }
          >
            {productCart.sync ? 'Enabled' : 'Disabled'}
          </ButtonLink>
        ) : (
          <a
            href={`#/settings/saleschannels/${productCart.id}/edit`}
            className="btn btn--link v-align-base lh-sm"
          >
            <em>Disabled for sales channel</em>
          </a>
        )}
      </td>
      <td className="table__td table__td--md align-center">
        {productCart.vendor === MANUAL ? (
          '—'
        ) : (
          <ButtonLink
            className="darker v-align-base align-center lh-sm"
            onClick={() =>
              showEditProductCartModal(sku, [productCart.id], 'export_qty')
            }
          >
            {!productCart.cart_aoh_calculated ? null : Object.keys(
                productCart.cart_aoh_calculated,
              ).length > 1 ? (
              <em className="fs-00 margin-bottom-1 block">Multi-location</em>
            ) : (
              <strong className="fs-00 margin-bottom-1 block">
                <PrettyNumber
                  value={productCart.cart_aoh_calculated.total_aoh}
                />
              </strong>
            )}
            <span className="fs-n2">
              (
              <span>
                {isPresent(productCart.max_export_qty) ? (
                  <PrettyNumber value={productCart.max_export_qty} />
                ) : (
                  'All'
                )}
              </span>
              {' · '}
              <span>
                {isPresent(productCart.min_export_qty) ? (
                  <PrettyNumber value={productCart.min_export_qty} />
                ) : (
                  'N/A'
                )}
              </span>
              {' · '}
              <span>{productCart.percent_export_qty}%</span>
              {' · '}
              <span>
                <PrettyNumber value={productCart.reserve_export_qty} />
              </span>
              )
            </span>
          </ButtonLink>
        )}
      </td>
      <td className="table__td table__td--md">
        {productCart.vendor === MANUAL ||
        !productCart.sync ||
        !cartsHaveLinkedWarehouses[productCart.id] ? (
          '—'
        ) : productCart.inventory_changed && syncSuccess ? (
          <>
            <span
              className="i-exclamation-triangle fs-01 v-align-middle text--warning margin-right-3"
              aria-hidden="true"
            />
            <strong className="text--warning">Needs Sync</strong>
          </>
        ) : productCart.audit.latest_export && !syncSuccess ? (
          <>
            <div>
              <span
                className="i--error fs-01 v-align-middle margin-right-3 i--lh-0"
                aria-hidden="true"
              />
              <strong className="error">Sync Failed</strong>
            </div>
            {productCart.audit.latest_export.message && (
              <div className="error fs-n1 margin-top-3">
                {productCart.audit.latest_export.message}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="margin-bottom-3">
              <span
                className="i-check-circle fs-01 v-align-middle text--green margin-right-3 i--lh-0"
                aria-hidden="true"
              />
              <strong className="text--green">Synced</strong>
            </div>
            <ButtonLink
              className="darker v-align-base lh-sm"
              onClick={() => showMarkNeedsSyncModal([sku], productCart.id)}
            >
              Mark as Needs Sync
            </ButtonLink>
          </>
        )}
      </td>
      <td className="table__td table__td--md">
        {productCart.audit.latest_export.created ? (
          <>
            <div className="margin-bottom-3">
              <FormattedDate
                value={productCart.audit.latest_export.created}
                format="MMM D, YYYY [at] h:mma"
              />
            </div>
            <div>
              <span className="text--md-grey">
                {`(${quantity({value: productCart.audit.latest_export.quantity})} unit${
                  productCart.audit.latest_export.quantity === 1 ? '' : 's'
                })`}
              </span>{' '}
              <span
                className="tooltip--top fs-01 v-align-top"
                data-hint={`First Writeback - ${formatDate(
                  productCart.audit.first_export.created,
                  'MMM D, YYYY [at] h:mma',
                )} (${quantity({value: productCart.audit.first_export.quantity})} unit${
                  productCart.audit.first_export.quantity === 1 ? '' : 's'
                })`}
              >
                <span
                  className="i--info v-align-middle i--lh-0 text--lt-grey"
                  aria-hidden="true"
                />
              </span>
            </div>
          </>
        ) : (
          '—'
        )}
      </td>
    </tr>
  )
}

SalesChannel.propTypes = {
  sku: PropTypes.string.isRequired,
  productCart: PropTypes.shape({
    id: PropTypes.number.isRequired,
    vendor: PropTypes.string.isRequired,
    sync: PropTypes.bool.isRequired,
    inventory_changed: PropTypes.bool.isRequired,
    max_export_qty: PropTypes.number,
    min_export_qty: PropTypes.number.isRequired,
    percent_export_qty: PropTypes.number.isRequired,
    reserve_export_qty: PropTypes.number.isRequired,
    cart_aoh_calculated: PropTypes.shape({
      total_aoh: PropTypes.number.isRequired,
    }),
    audit: PropTypes.shape({
      first_import: PropTypes.shape({
        created: PropTypes.string,
        quantity: PropTypes.number,
        message: PropTypes.string,
        success: PropTypes.bool,
      }).isRequired,
      latest_import: PropTypes.shape({
        created: PropTypes.string,
        quantity: PropTypes.number,
        message: PropTypes.string,
        success: PropTypes.bool,
      }).isRequired,
      first_export: PropTypes.shape({
        created: PropTypes.string,
        quantity: PropTypes.number,
        message: PropTypes.string,
        success: PropTypes.bool,
      }).isRequired,
      latest_export: PropTypes.shape({
        created: PropTypes.string,
        quantity: PropTypes.number,
        message: PropTypes.string,
        success: PropTypes.bool,
      }).isRequired,
    }).isRequired,
  }),
  selectedCartIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  carts: PropTypes.objectOf(CartShape).isRequired,
  cartsCanHaveFulfillmentLatency: PropTypes.objectOf(PropTypes.bool).isRequired,
  cartsHaveLinkedWarehouses: PropTypes.objectOf(PropTypes.bool).isRequired,
  productCartIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default function SalesChannels() {
  const sku = useSelector(skuSelector)
  const productCarts = useSelector((state) =>
    productCartsSelector(state, {sku}),
  )
  const carts = useSelector(cartsSelector)
  const cartsHaveLinkedWarehouses = useSelector(
    cartsHaveLinkedWarehousesSelector,
  )
  const {selectedCartIDs} = useSelector(productDetailFormSelector)
  const cartsCanHaveFulfillmentLatency = useSelector(
    cartsCanHaveFulfillmentLatencySelector,
  )
  const productCartIDs = useSelector(productCartIDsSelector)
  const areAllCartsSelected = useSelector(areAllCartsSelectedSelector)
  const areIndeterminateCartsSelected = useSelector(
    areIndeterminateCartsSelectedSelector,
  )

  const {canEditSyncLookup, canEditMaxExportQtyLookup} = productCarts.reduce(
    (prev, productCart) => {
      prev.canEditSyncLookup[productCart.id] =
        productCart.vendor !== MANUAL &&
        cartsHaveLinkedWarehouses[productCart.id]

      prev.canEditMaxExportQtyLookup[productCart.id] =
        productCart.vendor !== MANUAL

      return prev
    },
    {canEditSyncLookup: {}, canEditMaxExportQtyLookup: {}},
  )
  const selectedThatCanEditSync = selectedCartIDs.filter(
    (id) => canEditSyncLookup[id],
  )
  const selectedThatCanEditMaxExportQty = selectedCartIDs.filter(
    (id) => canEditMaxExportQtyLookup[id],
  )

  return (
    <table className="table fs-00">
      <thead>
        <tr>
          <th className="table__th table__th--sm w-1">
            <Checkbox
              className="margin-bottom-0"
              checked={areAllCartsSelected}
              indeterminate={areIndeterminateCartsSelected}
              onClick={(checked) =>
                updateProductDetailForm({
                  selectedCartIDs: checked ? productCartIDs : [],
                })
              }
            />
          </th>
          <th className="table__th table__th--md w-15">Sales Channel</th>
          <th className="table__th table__th--md w-20">Last Import</th>
          <th className="table__th table__th--md w-15">
            {selectedThatCanEditSync.length === 0 ? (
              'Writeback'
            ) : (
              <ButtonLink
                className="darker lh-inherit"
                title="Bulk Enable/Disable Writeback"
                onClick={() =>
                  showEditProductCartModal(sku, selectedThatCanEditSync, 'sync')
                }
              >
                Writeback
              </ButtonLink>
            )}
          </th>
          <th className="table__th table__th--md align-center w-15">
            {selectedThatCanEditMaxExportQty.length === 0 ? (
              'Writeback Qty'
            ) : (
              <ButtonLink
                className="darker lh-inherit"
                title="Bulk Update Writeback Quantity"
                onClick={() =>
                  showEditProductCartModal(
                    sku,
                    selectedThatCanEditMaxExportQty,
                    'export_qty',
                  )
                }
              >
                Writeback Qty
              </ButtonLink>
            )}
          </th>
          <th className="table__th table__th--md w-15">Status</th>
          <th className="table__th table__th--md w-20">Last Writeback</th>
        </tr>
      </thead>
      <tbody className="table__tbody table__tbody--lines">
        {productCarts.map((productCart) => (
          <SalesChannel
            key={productCart.id}
            sku={sku}
            productCart={productCart}
            selectedCartIDs={selectedCartIDs}
            carts={carts}
            cartsCanHaveFulfillmentLatency={cartsCanHaveFulfillmentLatency}
            cartsHaveLinkedWarehouses={cartsHaveLinkedWarehouses}
            productCartIDs={productCartIDs}
          />
        ))}
      </tbody>
    </table>
  )
}
