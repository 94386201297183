import PropTypes from 'prop-types'
import {useRef, useEffect} from 'react'
import {select} from 'd3-selection'
import {scaleTime, scaleLinear} from 'd3-scale'
import {axisBottom, axisLeft} from 'd3-axis'
import {line, area, curveStepAfter} from 'd3-shape'
import {format} from 'd3-format'
import d3Tip from 'd3-tip'
import startOfDay from 'date-fns/startOfDay'
import parse from 'date-fns/parse'
import addDays from 'date-fns/addDays'
import dateFormat from 'date-fns/format'

import {DateShape} from '../../common/PropTypes.js'
import html from '../../common/html.js'
import Currency, {formatCurrency} from '../../common/components/Currency.js'
import Quantity, {quantity} from '../../common/components/Quantity.js'
import {useElementSize} from '../../common/components/useWindowSize.js'

function toolTipTemplate(date, stats) {
  return html`
    <div class="popover-wrapper wrapper--popover-line-graph">
      <div class="popover popover--line-graph popover--product-graph">
        <div class="arrow"></div>
        <div class="inner">
          <p class="title chart-popover-title">
            <strong>${date}</strong>
          </p>
          <div class="content">
            <ul
              class="list list--product-graph list--no-style margin-left-0 margin-bottom-0 clearfix"
            >
              ${stats.map(
                ({title, value, currency}) => html`
                  <li class="list__item">
                    <dl class="list--graph-text">
                      <dt class="list__item--graph-title">${title}</dt>
                      <dd class="list__item--graph-value">
                        <span
                          >${currency
                            ? formatCurrency(value, '$')
                            : quantity({value})}</span
                        >
                      </dd>
                    </dl>
                  </li>
                `,
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  `
}

function cloneBucketForDate(date, originalBucket) {
  return {
    ...originalBucket,
    key: date.toISOString(),
    key_as_string: dateFormat(date, 'yyyy-MM-dd'),
    date,
  }
}

function renderGraph(
  data,
  lines,
  width,
  height,
  startDate,
  endDate,
  groupElement,
  tips,
  margin,
) {
  if (tips) {
    tips.hide()
  }

  if (!groupElement) {
    return
  }

  const group = select(groupElement)
  group.html('')

  if (!data) {
    return
  }

  if (data.buckets.length === 0) {
    return
  }

  if (height <= 0 || width <= 0) {
    return
  }

  const x = scaleTime().range([0, width])

  const y = scaleLinear().range([height, 0])

  const xAxis = axisBottom().scale(x).ticks(5).tickSize(-height).tickPadding(5)

  const yAxis = axisLeft()
    .scale(y)
    .tickSize(-width)
    .ticks(5)
    .tickFormat(format(',.0f'))
    .tickPadding(10)

  group.attr('transform', `translate(${margin.left},${margin.top})`)

  const yMax = data.buckets.reduce((prev, d) => {
    d.date = parse(d.key_as_string, 'yyyy-MM-dd', new Date())

    lines
      .filter(({graph}) => graph)
      .forEach(({title}) => {
        prev = Math.max(prev, d[title])
      })

    return prev
  }, 10)

  startDate = startOfDay(startDate)
  endDate = startOfDay(endDate)

  let current = new Date(startDate)
  const buckets = []
  const emptyBucket = lines.reduce((prev, {title}) => {
    prev[title] = 0

    return prev
  }, {})

  let dataIndex = 0
  while (current <= endDate) {
    const currentBucket = data.buckets[dataIndex]

    if (currentBucket && currentBucket.date <= current) {
      buckets.push(currentBucket)

      dataIndex += 1
    } else {
      buckets.push(cloneBucketForDate(current, emptyBucket))
    }

    current = addDays(current, 1)
  }

  buckets.push(cloneBucketForDate(current, buckets[buckets.length - 1]))

  x.domain([startDate, current])
  y.domain([0, yMax])

  group
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0, ${height})`)
    .call(xAxis)

  group.append('g').attr('class', 'y axis').call(yAxis)

  lines
    .filter(({graph}) => graph)
    .forEach(({title}, index) => {
      const graphLine = line()
        .x((d) => x(d.date))
        .y((d) => y(d[title]))
        .curve(curveStepAfter)

      const graphArea = area()
        .x((d) => x(d.date))
        .y0(height)
        .y1((d) => y(d[title]))
        .curve(curveStepAfter)

      group
        .append('path')
        .datum(buckets)
        .attr('class', `line lc-0${index + 1}`)
        .attr('d', graphLine)

      group
        .append('path')
        .datum(buckets)
        .attr('class', `area ac-0${index + 1}`)
        .attr('d', graphArea)
    })

  addTooltips(data, lines, buckets, x, width, height, group, tips)
}

function addTooltips(data, lines, buckets, x, width, height, group, tips) {
  let xRangeWidth = width / (buckets.length - 1)
  xRangeWidth = xRangeWidth > 1 ? xRangeWidth : 1
  const isCurrencyLookup = lines.reduce((prev, {title, currency}) => {
    prev[title] = !!currency

    return prev
  }, {})

  tips
    .attr('class', 'd3-tip')
    .offset(() => [0, xRangeWidth * 0.5])
    .html((event, d) => {
      const stats = data.lines.map((title) => ({
        title,
        value: d[title] || 0,
        currency: isCurrencyLookup[title],
      }))

      return toolTipTemplate(dateFormat(d.date, 'E P'), stats)
    })

  group
    .selectAll('.region')
    .data(buckets.slice(0, buckets.length - 1))
    .enter()
    .append('rect')
    .attr('class', 'region')
    .attr('x', (d) => x(d.date))
    .attr('width', xRangeWidth)
    .attr('y', 0)
    .attr('height', height)
    .on('mouseover', tips.show)
    .on('mouseout', tips.hide)
}

function BarGraph({data, lines, startDate, endDate, width, height, margin}) {
  const groupRef = useRef(null)
  const tipsRef = useRef(null)

  useEffect(() => {
    if (!groupRef.current) {
      return
    }

    const tips = d3Tip()
    const group = select(groupRef.current)
    group.call(tips)
    tipsRef.current = tips

    return () => {
      tips.hide()
    }
  }, [groupRef.current])

  useEffect(() => {
    renderGraph(
      data,
      lines,
      width,
      height,
      startDate,
      endDate,
      groupRef.current,
      tipsRef.current,
      margin,
    )
  }, [data, lines, width, height, groupRef.current, margin])

  return (
    <svg
      width={width + margin.left + margin.right}
      height={height + margin.top + margin.bottom}
    >
      <g ref={groupRef}></g>
    </svg>
  )
}

BarGraph.propTypes = {
  data: PropTypes.shape({
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        date: DateShape,
      }),
    ).isRequired,
    lines: PropTypes.arrayOf(PropTypes.string).isRequired,
    totals: PropTypes.shape({}).isRequired,
  }),
  startDate: DateShape,
  endDate: DateShape,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      graph: PropTypes.bool,
      currency: PropTypes.bool,
    }),
  ),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  margin: PropTypes.shape({
    top: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
}

const margin = {
  top: 10,
  right: 30,
  bottom: 30,
  left: 60,
}

export default function ValueOverTimeGraph({
  data,
  startDate,
  endDate,
  lines,
  isLoading,
}) {
  const ref = useRef(null)
  const {width, height} = useElementSize({ref, margin})

  return (
    <div
      className={`wrap--row flex margin-top-30 ${isLoading ? 'loading' : ''}`}
    >
      <div className="medium-9 columns padding-right-0">
        <div className="wrap wrap--graph wrap--line-graph" ref={ref}>
          {isLoading || !data ? null : data.buckets.length === 0 ? (
            <div className="overlay overlay--no-data flex--justify-col">
              <dl className="list--no-data w-75 margin-auto margin-top-0 margin-bottom-0">
                <dt className="list__title fs-01 margin-bottom-0">
                  <strong>No data to display for this date range</strong>
                </dt>
                <dd className="list__item fs-00">
                  Adjust your dates and try again
                </dd>
              </dl>
            </div>
          ) : (
            <BarGraph
              data={data}
              lines={lines}
              startDate={startDate}
              endDate={endDate}
              width={width}
              height={height}
              margin={margin}
            />
          )}
        </div>
      </div>
      <div className="medium-3 columns subdue-for-loading">
        <ul className="list--totals list--revenue-totals">
          {data &&
            lines.map(({title, currency}, index) => (
              <li
                className="list__item list__item--graph-total"
                key={`${index}-${title}`}
              >
                <dl className="list--graph-text">
                  <dt className="list__item--graph-title">{title}</dt>
                  <dd className="list__item--graph-value">
                    {currency ? (
                      <Currency value={data.totals[title]} />
                    ) : (
                      <Quantity value={data.totals[title]} />
                    )}
                  </dd>
                </dl>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

ValueOverTimeGraph.propTypes = {
  data: PropTypes.shape({
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        date: DateShape,
      }),
    ).isRequired,
    lines: PropTypes.arrayOf(PropTypes.string).isRequired,
    totals: PropTypes.shape({}).isRequired,
  }),
  startDate: DateShape,
  endDate: DateShape,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      graph: PropTypes.bool,
      currency: PropTypes.bool,
    }),
  ),
  isLoading: PropTypes.bool,
}
