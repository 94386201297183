import {dispatch, useSelector} from '../../store.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import Weight from '../../common/components/Weight.js'
import Dimensions from '../../common/components/Dimensions.js'
import Currency from '../../common/components/Currency.js'
import {lbToOz} from '../../common/weight.js'
import Quantity from '../../common/components/Quantity.js'
import {
  DROPSHIPPABLE_FULFILLMENT,
  AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
} from '../../common/constants/Products.js'
import {showFeatureLockedModal} from '../Modals/FeatureLockedModal.js'
import {showProductSupplierModal} from '../ProductListPage/Modals/ProductSupplierModal.js'
import {showProductWeightModal} from '../ProductListPage/Modals/ProductWeightModal.js'
import {showProductDimensionsModal} from '../ProductListPage/Modals/ProductDimensionsModal.js'
import {showProductCostModal} from '../ProductListPage/Modals/ProductCostModal.js'
import {showProductPriceModal} from '../ProductListPage/Modals/ProductPriceModal.js'
import {showProductTextFieldModal} from '../ProductListPage/Modals/ProductTextFieldModal.js'
import {
  usesInventorySelector,
  showFeatureLocksSelector,
  autoDropshipOnOrderCreationSelector,
  useDropshippingSelector,
  usesAppsSelector,
} from '../../data/company.js'
import {
  weightSelector,
  lengthSelector,
  heightSelector,
  widthSelector,
  totalOnHandSelector,
  costSelector,
  priceSelector,
  productUPCSelector,
  asinSelector,
  categorySelector,
  hsCodeSelector,
  customsDescriptionSelector,
  countryOfOriginSelector,
  internalNotesSelector,
  fulfillmentTypeSelector,
  hasSerialNumbersSelector,
} from '../../data/products.js'
import {skuSelector} from './productDetailSelectors.js'
import {showUpgradeModal} from '../Modals/UpgradeModal.js'

function FeatureLockButtonLink() {
  const usesApps = useSelector(usesAppsSelector)

  return (
    <ButtonLink
      className="v-align-base op-50 no-underline"
      onClick={() =>
        usesApps ? showUpgradeModal() : showFeatureLockedModal('inventory')
      }
    >
      <span
        className="i-lock-closed fs-01 v-align-middle lh-lg no-underline margin-right-1"
        aria-hidden="true"
      />
    </ButtonLink>
  )
}

export default function Sidebar() {
  const sku = useSelector(skuSelector)
  const weight = useSelector((state) => weightSelector(state, {sku}))
  const length = useSelector((state) => lengthSelector(state, {sku}))
  const height = useSelector((state) => heightSelector(state, {sku}))
  const width = useSelector((state) => widthSelector(state, {sku}))
  const totalOnHand = useSelector((state) => totalOnHandSelector(state, {sku}))
  const cost = useSelector((state) => costSelector(state, {sku}))
  const price = useSelector((state) => priceSelector(state, {sku}))
  const upc = useSelector((state) => productUPCSelector(state, {sku}))
  const asin = useSelector((state) => asinSelector(state, {sku}))
  const category = useSelector((state) => categorySelector(state, {sku}))
  const hsCode = useSelector((state) => hsCodeSelector(state, {sku}))
  const customs_description = useSelector((state) =>
    customsDescriptionSelector(state, {sku}),
  )
  const country_of_origin = useSelector((state) =>
    countryOfOriginSelector(state, {sku}),
  )
  const has_serial_numbers = useSelector((state) =>
    hasSerialNumbersSelector(state, {sku}),
  )
  const internal_notes = useSelector((state) =>
    internalNotesSelector(state, {sku}),
  )
  const fulfillmentType = useSelector((state) =>
    fulfillmentTypeSelector(state, {sku}),
  )
  const usesInventory = useSelector(usesInventorySelector)
  const useDropshipping = useSelector(useDropshippingSelector)
  const showFeatureLocks = useSelector(showFeatureLocksSelector)
  const autoDropshipOnOrderCreation = useSelector(
    autoDropshipOnOrderCreationSelector,
  )

  return (
    <div className="divider--left mid-dark lg w-100">
      {(showFeatureLocks || useDropshipping) && (
        <dl className="list fs-n0 divider--bottom">
          <dt className="list__title--product-data">Fulfillment</dt>
          <dd className="list__item--product-data">
            <strong className="fs-01">
              <ButtonLink
                className="darker v-align-base"
                onClick={() =>
                  useDropshipping
                    ? showProductSupplierModal([sku])
                    : showFeatureLockedModal('dropship')
                }
              >
                {autoDropshipOnOrderCreation &&
                  fulfillmentType === AUTOMATICALLY_DROPSHIPPED_FULFILLMENT &&
                  'Automatically '}
                {[
                  DROPSHIPPABLE_FULFILLMENT,
                  AUTOMATICALLY_DROPSHIPPED_FULFILLMENT,
                ].includes(fulfillmentType)
                  ? 'Dropshipped'
                  : 'In House'}
              </ButtonLink>
            </strong>
          </dd>
        </dl>
      )}

      <dl className="list fs-n0 divider--bottom">
        <dt className="list__title--product-data">Physical Info</dt>
        <dd className="list__item--product-data">
          <strong className="margin-right-3">Weight:</strong>
          <ButtonLink
            className="darker v-align-base"
            onClick={() => dispatch(showProductWeightModal([sku], weight))}
          >
            <Weight value={lbToOz(weight)} />
          </ButtonLink>
        </dd>
        <dd className="list__item--product-data">
          <strong className="margin-right-3">Package Dimensions:</strong>
          <ButtonLink
            className={`v-align-base ${
              length || width || height ? 'darker' : ''
            }`}
            onClick={() =>
              dispatch(showProductDimensionsModal([sku], length, height, width))
            }
          >
            <Dimensions length={length} height={height} width={width} />
          </ButtonLink>
        </dd>
      </dl>

      <dl className="list fs-n0 divider--bottom">
        <dt className="list__title--product-data">Financial Info</dt>
        {(showFeatureLocks || usesInventory) && (
          <>
            <dd className="list__item--product-data">
              <strong className="margin-right-3">
                Weighted Avg Unit Cost:
              </strong>
              {usesInventory ? (
                <ButtonLink
                  className="darker v-align-base"
                  onClick={() => dispatch(showProductCostModal(sku, cost))}
                >
                  <Currency value={cost} />
                </ButtonLink>
              ) : (
                <FeatureLockButtonLink />
              )}
            </dd>
            <dd className="list__item--product-data">
              <strong className="margin-right-3">Total Units On Hand:</strong>
              <span>
                {usesInventory ? (
                  <Quantity value={totalOnHand} />
                ) : (
                  <FeatureLockButtonLink />
                )}
              </span>
            </dd>
            <dd className="list__item--product-data">
              <strong className="margin-right-3">Total Inventory Value:</strong>
              <span>
                {usesInventory ? (
                  <Currency value={totalOnHand * cost} />
                ) : (
                  <FeatureLockButtonLink />
                )}
              </span>
            </dd>
            <dd className="list__item--product-data">
              <span className="op-30">—</span>
            </dd>
          </>
        )}
        <dd className="list__item--product-data">
          <strong className="margin-right-3">Manual Order Price:</strong>
          <ButtonLink
            className="darker v-align-base"
            onClick={() => dispatch(showProductPriceModal(sku, price))}
          >
            <Currency value={price} />
          </ButtonLink>
        </dd>
      </dl>
      <dl className="list fs-n0 divider--bottom">
        <dt className="list__title--product-data">Organization</dt>
        <dd className="list__item--product-data">
          <strong className="margin-right-3">Category:</strong>
          <ButtonLink
            className={`v-align-base ${category ? 'darker' : ''}`}
            onClick={() =>
              showProductTextFieldModal({
                sku,
                value: category,
                display: 'Category',
                apiProp: 'category',
              })
            }
          >
            {category || <em>Not configured</em>}
          </ButtonLink>
        </dd>
        <dd className="list__item--product-data">
          <strong className="margin-right-3">UPC:</strong>
          <ButtonLink
            className={`v-align-base ${upc ? 'darker' : ''}`}
            onClick={() =>
              showProductTextFieldModal({
                sku,
                value: upc,
                display: 'UPC',
                apiProp: 'upc',
              })
            }
          >
            {upc || <em>Not configured</em>}
          </ButtonLink>
        </dd>
        <dd className="list__item--product-data">
          <strong className="margin-right-3">ASIN:</strong>
          <ButtonLink
            className={`v-align-base ${asin ? 'darker' : ''}`}
            onClick={() =>
              showProductTextFieldModal({
                sku,
                value: asin,
                display: 'ASIN',
                apiProp: 'asin',
              })
            }
          >
            {asin || <em>Not configured</em>}
          </ButtonLink>
        </dd>
        <dd className="list__item--product-data">
          <strong className="margin-right-3">Prompt for Serial Numbers?</strong>
          <ButtonLink
            className={`v-align-base ${has_serial_numbers ? 'darker' : ''}`}
            onClick={() =>
              showProductTextFieldModal({
                sku,
                value: has_serial_numbers,
                title: 'Prompt for Serial Numbers?',
                display:
                  'Prompt for serial numbers during packing verification',
                apiProp: 'has_serial_numbers',
                inputType: 'checkbox',
              })
            }
          >
            {has_serial_numbers ? <span>Yes</span> : <em>No</em>}
          </ButtonLink>
        </dd>
      </dl>
      <dl className="list fs-n0 divider--bottom">
        <dt className="list__title--product-data">Customs Declarations</dt>

        <dd className="list__item--product-data">
          <strong className="margin-right-3">Description:</strong>
          <ButtonLink
            className={`v-align-base ${customs_description ? 'darker' : ''}`}
            onClick={() =>
              showProductTextFieldModal({
                sku,
                value: customs_description,
                display: 'Customs Description',
                apiProp: 'customs_description',
                showRefreshLabelInfoCustoms: true,
              })
            }
          >
            {customs_description || <em>Not configured</em>}
          </ButtonLink>
        </dd>
        <dd className="list__item--product-data">
          <strong className="margin-right-3">Harmonized Code:</strong>
          <ButtonLink
            className={`v-align-base ${hsCode ? 'darker' : ''}`}
            onClick={() =>
              showProductTextFieldModal({
                sku,
                value: hsCode,
                display: 'Harmonized Code',
                apiProp: 'hs_code',
                showRefreshLabelInfoCustoms: true,
              })
            }
          >
            {hsCode || <em>Not configured</em>}
          </ButtonLink>
        </dd>
        <dd className="list__item--product-data">
          <strong className="margin-right-3">Country of Origin:</strong>
          <ButtonLink
            className={`v-align-base ${country_of_origin ? 'darker' : ''}`}
            onClick={() =>
              showProductTextFieldModal({
                sku,
                value: country_of_origin,
                display: 'Country of Origin',
                apiProp: 'country_of_origin',
                showRefreshLabelInfoCustoms: true,
              })
            }
          >
            {country_of_origin || <em>Not configured</em>}
          </ButtonLink>
        </dd>
      </dl>
      <dl className="list fs-n0">
        <dt className="list__title--product-data">Internal Notes</dt>
        {internal_notes && (
          <dd className="list__item--product-data ws-pre-wrap">
            {internal_notes}
          </dd>
        )}
        <dd className="list__item--product-data">
          <ButtonLink
            onClick={() =>
              showProductTextFieldModal({
                sku,
                value: internal_notes,
                display: 'Internal Notes',
                apiProp: 'internal_notes',
                inputType: 'textarea',
              })
            }
          >
            {internal_notes ? <span>Edit</span> : <span>Add note</span>}
          </ButtonLink>
        </dd>
      </dl>
    </div>
  )
}
