import api from '../../common/api.js'
import Currency from '../../common/components/Currency.js'
import {FormattedRealDate} from '../../common/components/FormattedDate.js'
import ConfirmModal from '../../common/components/Modal/ConfirmModal.js'
import {formatDateTime} from '../../common/date.js'
import {
  paymentAccountSelector,
  SAME_DAY_ACH_PT,
} from '../../data/paymentAccounts.js'
import {
  mostRecentPaymentRecordSelector,
  paymentRecordSelector,
} from '../../data/paymentRecords.js'
import {
  formsSelector,
  getState,
  onlyIfForm,
  removeForm,
  setForm,
  updateForm,
  useSelector,
} from '../../store.js'
import {showMessageToast} from '../Header/Toast/index.js'

const MODAL_FORM = 'PAYMENT_TRANSFER_MODAL'

export function showPaymentTransferModal(paymentAccountID, paymentRecordID) {
  const paymentRecord = paymentRecordID
    ? paymentRecordSelector(getState())
    : mostRecentPaymentRecordSelector(getState(), {
        paymentAccountID,
      })

  if (!paymentRecord) {
    return
  }

  setForm(MODAL_FORM, {
    paymentAccountID,
    paymentRecordID: paymentRecord.id,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(...args) {
  updateForm(MODAL_FORM, ...args)
}

export function closeModal() {
  removeForm(MODAL_FORM)
}

export function modalFormSelector(state) {
  return formsSelector(state)[MODAL_FORM]
}

async function cancelTransfer() {
  try {
    const {paymentAccountID, paymentRecordID} = modalFormSelector(getState())

    updateModalForm({serverError: null, isSaving: true})

    await api.delete(
      `/company/payment/${paymentAccountID}/transfer/${paymentRecordID}/`,
    )

    showMessageToast('Transfer canceled')

    closeModal()
  } catch (err) {
    updateModalForm({serverError: err.message || err.error_message})
  }
}

function PaymentTransferModal({form}) {
  const paymentAccount = useSelector((state) =>
    paymentAccountSelector(state, {paymentAccountID: form.paymentAccountID}),
  )
  const paymentRecord = useSelector((state) =>
    paymentRecordSelector(state, {
      paymentRecordID: form.paymentRecordID,
    }),
  )
  const {
    activation_response: {account = {}},
  } = paymentAccount
  const transfer = paymentRecord?.payment_response?.transfer

  const canCancel = !!transfer.cancellable

  return (
    <ConfirmModal
      title="Transfer Info"
      modalSize="sm"
      onConfirm={canCancel ? cancelTransfer : null}
      confirmText="Cancel Transfer"
      onCancel={() => closeModal()}
      cancelText="Close"
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <div className="divider--bottom padding-bottom-10 margin-bottom-10">
        <strong className="fs-01 lh-md margin-right-3">{account.name}</strong>
        <span className="fs-01 lh-md">(ending in {account.mask})</span>
      </div>

      <div className="fs-00 lh-md margin-bottom-1">
        <strong>Initiated:</strong>{' '}
        <FormattedRealDate value={transfer.created} format={formatDateTime} />
      </div>
      <div className="fs-00 lh-md margin-bottom-1">
        <strong>Processing Speed:</strong>{' '}
        {transfer.network === SAME_DAY_ACH_PT ? 'Same Day ACH' : 'Standard ACH'}
      </div>
      <div className="fs-00 lh-md">
        <strong>Transfer Amount:</strong> <Currency value={transfer.amount} />
      </div>
      {canCancel && (
        <div className="alert alert--neutral inline-block margin-top-15">
          <p className="fs-00 lh-md margin-bottom-0">
            This transfer can still be cancelled.
          </p>
        </div>
      )}
    </ConfirmModal>
  )
}

export default onlyIfForm(PaymentTransferModal, modalFormSelector)
