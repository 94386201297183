import PropTypes from 'prop-types'
import {useEffect} from 'react'
import classNames from 'classnames'

import {isNumeric} from '../../../../common/utils.js'
import {
  ENDICIA,
  PITNEY,
  PITNEY_MERCHANT,
  VISIBLE_USPS,
} from '../../../../common/constants/ShipperNames.js'
import Currency from '../../../../common/components/Currency.js'
import ButtonPrimary from '../../../../common/components/Button/ButtonPrimary.js'
import {
  hasAccountCreditCardSelector,
  checkTrustScore,
  isInTrialSelector,
  canUsePlaidSelector,
} from '../../../../data/company.js'
import {
  getCanAddPostage,
  shipperTypeSelector,
} from '../../../../data/shippers.js'
import {
  dailyPurchaseLimitSelector,
  todaysPurchasesSelector,
  accountBalanceSelector,
} from '../../../../data/account.js'
import {
  formSelector,
  onlyIfAutoForm,
  updateForm,
  useSelector,
} from '../../../../store.js'
import {
  getShipperBalance,
  shipperBalancesSelector,
} from '../../../../data/shipperBalances.js'
import {showAccountCreditCardModal} from '../../../Modals/AccountCreditCardModal.js'
import {canBeTrustedSelector} from '../../../../data/trust.js'
import {showPaymentAccountsModal} from '../../../Modals/PaymentAccountsModal.js'
import {defaultPaymentAccountHasIntegrationIssueSelector} from '../../../../data/paymentAccounts.js'
import {showPostageBalanceModal} from '../../../LabelConfig/Modals/PostageBalanceModal.js'

export function setupPostageBalance({shipperID}) {
  return {
    formName: getPostageBalanceFormName(shipperID),
    initialForm: {
      isLoadingBalance: false,
      hasLoadedBalance: false,
      serverError: null,
    },
  }
}

function getPostageBalanceFormName(shipperID) {
  return `POSTAGE_BALANCE_${shipperID}`
}

export function updatePostageBalanceForm(shipperID, updates) {
  updateForm(getPostageBalanceFormName(shipperID), updates)
}

export function postageBalanceFormSelector(state, {shipperID}) {
  return (
    formSelector(state, {formName: getPostageBalanceFormName(shipperID)}) || {}
  )
}

export function shippersBalanceSelector(state, {shipperID}) {
  const shipperType = shipperTypeSelector(state, {shipperID})
  const shipperBalances = shipperBalancesSelector(state)
  const accountBalance = accountBalanceSelector(state)

  if (!shipperType) {
    return 0
  }

  if (
    [PITNEY, VISIBLE_USPS].includes(shipperType) &&
    isNumeric(accountBalance)
  ) {
    return accountBalance
  }

  if (shipperType === ENDICIA && shipperBalances[shipperID]) {
    return shipperBalances[shipperID].postage_balance
  }

  if (shipperType === PITNEY_MERCHANT && shipperBalances[shipperID]) {
    return shipperBalances[shipperID].balance
  }

  return 0
}

export async function getBalanceForShipper(shipperID) {
  try {
    updatePostageBalanceForm(shipperID, {
      isLoadingBalance: true,
      serverError: null,
    })

    await getShipperBalance(shipperID)

    updatePostageBalanceForm(shipperID, {hasLoadedBalance: true})
  } catch (err) {
    updatePostageBalanceForm(shipperID, {
      serverError: err.error_message || err.message,
    })
  } finally {
    updatePostageBalanceForm(shipperID, {isLoadingBalance: false})
  }
}

const _PostageBalanceWrapper = ({shipperID, children}) => {
  const {hasLoadedBalance, isLoadingBalance} = useSelector((state) =>
    postageBalanceFormSelector(state, {shipperID}),
  )
  const balance = useSelector((state) =>
    shippersBalanceSelector(state, {shipperID}),
  )
  const dailyPurchaseLimit = useSelector((state) =>
    dailyPurchaseLimitSelector(state),
  )
  const todaysPurchases = useSelector((state) => todaysPurchasesSelector(state))

  const defaultPaymentAccountHasIntegrationIssue = useSelector(
    defaultPaymentAccountHasIntegrationIssueSelector,
  )
  const shipperType = useSelector((state) =>
    shipperTypeSelector(state, {shipperID}),
  )

  const hasAccountCreditCard = useSelector(hasAccountCreditCardSelector)
  const canBeTrusted = useSelector(canBeTrustedSelector)
  const onlyDisplayPostage = shipperType === PITNEY_MERCHANT
  const isInTrial = useSelector(isInTrialSelector)
  const canUsePlaid = useSelector(canUsePlaidSelector)

  useEffect(() => {
    getBalanceForShipper(shipperID)
  }, [])

  useEffect(() => {
    if (!onlyDisplayPostage && hasLoadedBalance) {
      checkTrustScore()
    }
  }, [hasAccountCreditCard, onlyDisplayPostage, hasLoadedBalance])

  if (canBeTrusted === false) {
    return (
      <div className="alert alert--warning align-center">
        <div
          className="i-exclamation-triangle fs-03 op-30 lh-sm margin-bottom-0"
          aria-hidden="true"
        />
        <div className="fs-01 lh-md">
          <strong>There was an issue with your account.</strong>
        </div>
        <div className="fs-00 lh-md">
          Contact Ordoro support for help with purchasing postage.
        </div>
      </div>
    )
  }

  if (!hasAccountCreditCard && !onlyDisplayPostage) {
    return (
      <div className="align-center margin-bottom-25">
        <p className="fs-01 lh-md margin-bottom-15">
          <strong>To purchase postage, please add a payment method.</strong>
        </p>
        {isInTrial && (
          <p className="fs-00 lh-md margin-bottom-15">
            <strong>
              This card will only be used for buying postage with USPS.
            </strong>{' '}
            When you officially start your Ordoro subscription, you’ll be asked
            to enter a payment method separately for those recurring charges.
          </p>
        )}
        <div className="margin-bottom-25">
          <ButtonPrimary
            className="btn--primary margin-bottom-0"
            onClick={() => showAccountCreditCardModal()}
          >
            Add a Credit Card
          </ButtonPrimary>
        </div>
        {canUsePlaid && (
          <div className="margin-bottom-25">
            <ButtonPrimary
              className="btn--primary-alt margin-bottom-0"
              onClick={() => showPaymentAccountsModal()}
            >
              Add a Bank Account
            </ButtonPrimary>
          </div>
        )}
        {isInTrial && (
          <p className="fs-00 lh-md margin-bottom-15">
            <strong>Not ready to enter a payment method?</strong> We get it.
            While you mull it over,{' '}
            <a
              className="btn--link darker border-underline"
              href="https://storage.googleapis.com/ordoro-pappy-assets/public/usps-test-label.png"
              target="_blank"
              rel="noreferrer"
            >
              check out this sample label
            </a>{' '}
            to get a sneak peek of the good times that lie ahead.
          </p>
        )}
      </div>
    )
  }

  if (canBeTrusted === null && !onlyDisplayPostage) {
    return (
      <div className="align-center margin-top-30 margin-bottom-30">
        <div className="loading">
          <span className="spinner--md v-align-middle margin-right-5" />
          <span className="v-align-middle">Initiating postage account...</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <dl className="postage-wrap">
        <dt className="postage-info-label margin-bottom-0">
          Current USPS Postage Balance:
        </dt>
        <dd
          className={classNames('postage-value money margin-bottom-10', {
            calculating: isLoadingBalance,
          })}
        >
          <strong className="span-wrap">
            <Currency value={balance} currencySymbol="$" />
          </strong>
          <span className="loading">
            <span className="spinner" />
            <span>Loading...</span>
          </span>
        </dd>
        {[PITNEY, VISIBLE_USPS].includes(shipperType) && (
          <dd className="fs-n0 text--md-grey">
            <div className="margin-bottom-3">
              <strong>Today’s Postage Purchases:</strong>{' '}
              <Currency value={todaysPurchases} currencySymbol="$" />
            </div>
            <div>
              <strong>Daily Limit:</strong>{' '}
              <Currency value={dailyPurchaseLimit} currencySymbol="$" />
            </div>
          </dd>
        )}

        {defaultPaymentAccountHasIntegrationIssue && (
          <dd className="list__item margin-bottom-7">
            <div className="alert alert--warning alert--sm">
              <span className="fs-n0 lh-md">
                Integration issue with payment account
              </span>
            </div>
            <button
              className="btn btn--primary btn--primary-ol btn--x-sm"
              type="button"
              onClick={() => showPaymentAccountsModal()}
            >
              Manage Payment Methods
            </button>
          </dd>
        )}
        {children}
      </dl>
    </>
  )
}

_PostageBalanceWrapper.displayName = 'PostageBalanceWrapper'

export const PostageBalanceWrapper = onlyIfAutoForm(
  _PostageBalanceWrapper,
  setupPostageBalance,
)

export default function PostageBalance({shipperID}) {
  const shipperType = useSelector((state) =>
    shipperTypeSelector(state, {shipperID}),
  )
  const canAddPostage = getCanAddPostage(shipperType)

  return (
    <PostageBalanceWrapper shipperID={shipperID}>
      {canAddPostage && (
        <dd className="list--item margin-bottom-20">
          <ButtonPrimary
            className="margin-bottom-0"
            onClick={() => showPostageBalanceModal(shipperID)}
          >
            Add Postage
          </ButtonPrimary>
        </dd>
      )}
    </PostageBalanceWrapper>
  )
}

PostageBalance.propTypes = {
  shipperID: PropTypes.number.isRequired,
}
