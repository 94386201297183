import 'whatwg-fetch'
import {stringify} from './querystring.js'

export default function abode(url, options = {}) {
  return fetch(formatAbodeURL(url), {credentials: 'include', ...options})
}

abode.json = async (...args) => {
  const res = await abode(...args)

  return res.json()
}

export function formatAbodeURL(url, params) {
  return `${window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_ABODE_URL}${url}${
    params ? `?${stringify(params)}` : ''
  }`
}

export async function abodeRenderPOST(data) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  const pdfRes = await abode('/render', {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })

  const blob = await pdfRes.blob()

  const file = new File([blob], data.pdf_name || 'document.pdf', {
    type: 'application/pdf',
  })

  return file
}
