import NumberInput from './NumberInput.js'
import {
  labelViewFormAbodeSelector,
  setLabelWidth,
  setLabelHeight,
} from '../abodeFormFunctions.js'
import {useSelector} from '../../../store.js'

export default function LabelDimensions({formName}) {
  const {label_width, label_height} = useSelector((state) =>
    labelViewFormAbodeSelector(state, {formName}),
  )

  return (
    <div className="flex-item">
      <label className="label--bold" htmlFor="label-width">
        Label Dimensions
      </label>
      <div className="flex flex-wrap-margin-10">
        <NumberInput
          text="Width"
          id="label-width"
          value={label_width || ''}
          onChange={(event) => setLabelWidth(formName, event.target.value)}
        />
        <NumberInput
          text="Height"
          id="label-height"
          value={label_height || ''}
          onChange={(event) => setLabelHeight(formName, event.target.value)}
        />
      </div>
    </div>
  )
}
