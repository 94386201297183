import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import ButtonLink from '../../common/components/Button/ButtonLink.js'
import Quantity from '../../common/components/Quantity.js'
import {openGoodsReceiptModal} from '../PurchaseOrderListPage/Modals/GoodsReceiptModal/goodsReceiptModalFunctions.js'
import {productDetailURLSelector} from '../ProductDetailPage/productDetailSelectors.js'
import {
  poItemNameSelector,
  poItemSKUSelector,
  poItemQuantitySelector,
  poItemRemainingSelector,
  poItemLocationInWarehouseSelector,
} from '../../data/pos.js'

export function GoodsReceiptItem({
  poID,
  goodsReceiptID,
  item,
  isPOLocked,
  name,
  sku,
  quantity,
  remaining,
  location_in_warehouse,
  productDetailURL,
}) {
  return (
    <tr key={item.id}>
      <td className="table__td table__td--md">
        <div>
          <a className="btn--link darker word-breaker" href={productDetailURL}>
            <strong className="block">{name}</strong>
            <span className="block">{sku}</span>
          </a>
        </div>
      </td>
      <td className="table__td table__td--md">{location_in_warehouse}</td>
      <td className="table__td table__td--md align-right">
        <Quantity value={quantity} />
      </td>
      <td className="table__td table__td--md align-right">
        <Quantity value={remaining} />
      </td>
      <td className="table__td table__td--md align-right">
        <ButtonLink
          className="darker lh-md v-align-top border-underline"
          onClick={() => openGoodsReceiptModal(poID, goodsReceiptID, item.id)}
          disabled={isPOLocked}
        >
          <Quantity value={item.quantity} />
        </ButtonLink>
      </td>
    </tr>
  )
}

GoodsReceiptItem.propTypes = {
  poID: PropTypes.string.isRequired,
  goodsReceiptID: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
  isPOLocked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  remaining: PropTypes.number.isRequired,
  location_in_warehouse: PropTypes.string.isRequired,
  productDetailURL: PropTypes.string.isRequired,
}

function mapStateToProps(state, {poID, item}) {
  const poItemID = item.po_item_id
  const sku = poItemSKUSelector(state, {poID, poItemID})

  return {
    name: poItemNameSelector(state, {poID, poItemID}),
    sku,
    quantity: poItemQuantitySelector(state, {poID, poItemID}),
    remaining: poItemRemainingSelector(state, {poID, poItemID}),
    location_in_warehouse: poItemLocationInWarehouseSelector(state, {
      poID,
      poItemID,
    }),
    productDetailURL: productDetailURLSelector(state, {sku}),
  }
}

export default connect(mapStateToProps)(GoodsReceiptItem)
