import isEmpty from 'lodash/isEmpty.js'

import {
  PLAN_NAME_LOOKUP,
  STARTER_PLAN,
} from '../../../common/constants/Plans.js'
import apiverson from '../../../common/apiverson.js'
import verde from '../../../common/verde.js'
import {Count, plural} from '../../../common/components/Plural.js'
import createCSVFile from '../../../common/createCSVFile.js'
import {showGlobalError} from '../../GlobalErrorMessage.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {
  planSelector,
  stripePaymentDetailsSelector,
  stripeCustomerIDSelector,
  canChangePlanSelector,
  isNewPlansSelector,
  netZeroPaymentDetailsSelector,
  usesAppsSelector,
  shipAppSelector,
  inventoryAppSelector,
  dropshipAppSelector,
  postagePaymentDetailsSelector,
  usePaymentAccountsForCCSelector,
} from '../../../data/company.js'
import {showCreditCardModal} from '../../Modals/CreditCardModal.js'
import {showExportInsuranceHistoryModal} from '../../../redux/actions/ui/modals/exportInsuranceHistoryModal.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import SettingsPanel from '../SettingsPanel.js'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'
import {showCancellationModal} from '../../Modals/CancellationModal.js'
import {showAccountCreditCardModal} from '../../Modals/AccountCreditCardModal.js'
import {dispatch, getState, useSelector} from '../../../store.js'

import BillingEmailForm from './BillingEmailForm.js'
import {APP_DISPLAY} from '../../../common/constants/Apps.js'
import {showPaymentAccountsModal} from '../../Modals/PaymentAccountsModal.js'
import {
  anyPaymentAccountIsLockedSelector,
  defaultPaymentAccountHasIntegrationIssueSelector,
} from '../../../data/paymentAccounts.js'
import className from '../../../common/className.js'

export async function exportNetZeroBillingHistory() {
  try {
    const {json} = usePaymentAccountsForCCSelector(getState())
      ? await apiverson.get('/charges')
      : await apiverson.get('/net_zero/charges')

    await createCSVFile(
      [
        'id',
        'type',
        'amount',
        'created_date',
        'settled_date',
        'description',
        'outcome_seller_message',
        'card_type',
        'last4',
      ],
      json,
      'ordoro-account-billing-history.csv',
    )

    showMessageToast(
      plural(json)`${Count} charge${['s were', ' was']} exported`,
    )
  } catch (err) {
    showGlobalError(
      {
        summary: 'There was an error exporting billing history.',
        details: err.error_message || err.message,
      },
      err,
    )
  }
}

export async function exportBillingHistory() {
  try {
    const {json} = await verde.get('/stripe/charges')

    await createCSVFile(
      [
        'id',
        'amount',
        'amount_refunded',
        'currency',
        'created_date',
        'description',
        'outcome_seller_message',
        'refunded',
        'source',
        'source_last4',
      ],
      json.charge,
      'ordoro-billing-history.csv',
    )

    showMessageToast(
      plural(json)`${Count} charge${['s were', ' was']} exported`,
    )
  } catch (err) {
    showGlobalError(
      {
        summary: 'There was an error exporting billing history.',
        details: err.error_message || err.message,
      },
      err,
    )
  }
}

export default function Billing() {
  const plan = useSelector(planSelector)
  const stripePaymentDetails = useSelector(stripePaymentDetailsSelector)
  const stripeCustomerID = useSelector(stripeCustomerIDSelector) || ''
  const netZeroPaymentDetails = useSelector(netZeroPaymentDetailsSelector)
  const canChangePlan = useSelector(canChangePlanSelector)
  const hasPermission = useSelector(settingsPermissionsSelector).billing
  const isNewPlans = useSelector(isNewPlansSelector)
  const usesApps = useSelector(usesAppsSelector)
  const selectedShipApp = useSelector(shipAppSelector)
  const selectedDropshipApp = useSelector(dropshipAppSelector)
  const selectedInventoryApp = useSelector(inventoryAppSelector)

  const usePaymentAccountsForCC = useSelector(usePaymentAccountsForCCSelector)
  const postagePaymentDetails = useSelector(postagePaymentDetailsSelector)
  const defaultPaymentAccountHasIntegrationIssue = useSelector(
    defaultPaymentAccountHasIntegrationIssueSelector,
  )
  const showLockedNotice = useSelector(anyPaymentAccountIsLockedSelector)

  return (
    <SettingsPanel header="Billing" hasPermission={hasPermission}>
      <div className="row">
        <ul className="settings-list medium-11 columns">
          <li className="settings-list-item clearfix">
            <div className="medium-8 columns">
              <dl className="list">
                {!isNewPlans && !usesApps && (
                  <>
                    <dt className="list__title fs-00 text--md-grey margin-bottom-5">
                      Your Plan
                    </dt>
                    <dd className="list__item fs-04 lh-sm text--green margin-bottom-15">
                      <strong>{PLAN_NAME_LOOKUP[plan]}</strong>
                    </dd>
                  </>
                )}
                {usesApps && (
                  <>
                    <dt className="list__title fs-01 text--dk-grey margin-bottom-15">
                      Your Apps
                    </dt>
                    {selectedShipApp && (
                      <dd className="list__item outer-wrap--billing-app-callout flex divider--bottom">
                        <div className="wrap--billing-app-callout wrap--billing-app-shipping-callout flex">
                          <div className="wrap--app-icon margin-right-5 lh-md">
                            <svg
                              className="icon--app icon--app-shipping icon--app-shipping-01"
                              viewBox="0 0 150 130"
                            >
                              <path
                                className="icon__bg-fill--app"
                                d="M102.72,16.95l27.72,48.05-27.72,48.06h-55.44l-27.72-48.06,27.72-48.05h55.44ZM116.25,65l-4.91-8.51h-53.06l5.91-10.23h41.25l-9.82-17.01h-41.25l-20.62,35.75,4.91,8.51h53.05l-5.9,10.24h-41.25l9.81,17.01h41.25l20.63-35.76Z"
                              />
                              <path
                                className="icon__hexagon--app"
                                d="M112.5,0l37.5,65-37.5,65.01H37.5L0,65,37.5,0h75ZM130.44,65l-27.72-48.05h-55.44l-27.72,48.05,27.72,48.06h55.44l27.72-48.06Z"
                              />
                              <polygon
                                className="icon__name--app"
                                points="111.34 56.49 116.25 65 95.62 100.75 54.37 100.75 44.56 83.74 64.19 83.74 85.35 83.74 85.81 83.74 91.71 73.5 58.28 73.5 39.68 73.5 38.66 73.5 33.75 65 54.37 29.25 95.62 29.25 105.44 46.26 85.81 46.26 65.04 46.26 64.19 46.26 58.28 56.49 111.34 56.49"
                              />
                            </svg>
                          </div>
                          <strong className="fs-02">SHIPPING</strong>
                        </div>
                        <div className="wrap--billing-plan-callout text--md-grey fs-02 lh-md">
                          <span className="text--lt-grey margin-left-7 margin-right-7">
                            –
                          </span>
                          {APP_DISPLAY[selectedShipApp]} Plan
                        </div>
                      </dd>
                    )}
                    {selectedInventoryApp && (
                      <dd className="list__item outer-wrap--billing-app-callout flex divider--bottom">
                        <div className="wrap--billing-app-callout wrap--billing-app-inv-callout flex">
                          <div className="wrap--app-icon margin-right-5 lh-md">
                            <svg
                              className="icon--app icon--app-inventory icon--app-inventory-01"
                              viewBox="0 0 150 130"
                            >
                              <g>
                                <polygon
                                  className="icon__bg-fill--app"
                                  points="19.56 65 47.28 16.95 102.72 16.95 130.44 65 102.72 113.05 47.28 113.05 19.56 65"
                                />
                                <path
                                  className="icon__hexagon--app"
                                  d="M112.5,0l37.5,65-37.5,65H37.5L0,65,37.5,0h75ZM130.44,65l-27.72-48.05h-55.44l-27.72,48.05,27.72,48.06h55.44l27.72-48.06Z"
                                />
                              </g>
                              <polygon
                                className="icon__name--app"
                                points="95.63 46.26 95.63 29.27 95.62 29.25 54.37 29.25 54.37 29.26 54.37 46.26 66.5 46.26 66.5 83.74 54.37 83.74 54.37 100.74 54.37 100.75 95.62 100.75 95.63 100.73 95.63 83.74 83.5 83.74 83.5 46.26 95.63 46.26"
                              />
                            </svg>
                          </div>
                          <strong className="fs-02">INVENTORY</strong>
                        </div>
                        <div className="wrap--billing-plan-callout text--md-grey fs-02 lh-md">
                          <span className="text--lt-grey margin-left-7 margin-right-7">
                            –
                          </span>
                          {APP_DISPLAY[selectedInventoryApp]} Plan
                        </div>
                      </dd>
                    )}
                    {selectedDropshipApp && (
                      <dd className="list__item outer-wrap--billing-app-callout flex divider--bottom">
                        <div className="wrap--billing-app-callout wrap--billing-app-dropshipping-callout flex">
                          <div className="wrap--app-icon margin-right-5 lh-md">
                            <svg
                              className="icon--app icon--app-dropshipping icon--app-dropshipping-01"
                              viewBox="0 0 150 130"
                            >
                              <path
                                className="icon__bg-fill--app"
                                d="M102.72,16.95l27.73,48.05-27.73,48.04h-55.44l-27.72-48.04,27.72-48.05h55.44ZM96.62,65.01l-10.81-18.74h-19.93v37.48h19.93l10.81-18.73Z"
                              />
                              <polygon
                                className="icon__bg-fill--app"
                                points="85.81 46.26 96.62 65.01 85.81 83.74 65.88 83.74 65.88 46.26 85.81 46.26"
                              />
                              <path
                                className="icon__hexagon--app"
                                d="M112.5,0l37.5,65.01-37.5,64.99H37.5L0,65.01,37.5,0h75ZM130.45,65.01l-27.73-48.05h-55.44l-27.72,48.05,27.72,48.04h55.44l27.73-48.04Z"
                              />
                              <path
                                className="icon__name--app"
                                d="M95.34,29.25h-46.75v71.5s15.24,0,15.24,0h31.5l20.63-35.75-20.63-35.75ZM85.52,83.73h-19.93v-37.47h19.93l10.81,18.74-10.81,18.74Z"
                              />
                            </svg>
                          </div>
                          <strong className="fs-02">DROPSHIPPING</strong>
                        </div>
                        <div className="wrap--billing-plan-callout text--md-grey fs-02 lh-md">
                          <span className="text--lt-grey margin-left-7 margin-right-7">
                            –
                          </span>
                          {APP_DISPLAY[selectedDropshipApp]} Plan
                        </div>
                      </dd>
                    )}
                  </>
                )}

                {canChangePlan && (
                  <dd className="list__item margin-top-10">
                    <button
                      className="btn btn--primary btn--primary-ol btn--sm"
                      type="button"
                      onClick={() => showUpgradeModal()}
                    >
                      Manage Apps
                    </button>
                  </dd>
                )}
              </dl>
            </div>
          </li>
          <li className="settings-list-item clearfix">
            <div className="medium-5 columns">
              <div className="padding-top-10 divider--bottom">
                <BillingEmailForm />
              </div>
              <dl className="list divider--bottom">
                <dt className="list__title fs-00 text--md-grey margin-bottom-5">
                  Payment Method for Ordoro Subscription
                </dt>
                {!isEmpty(stripePaymentDetails) && (
                  <dd className="list__item">
                    <div className="fs-01 lh-md margin-bottom-5">
                      <strong>{stripePaymentDetails.brand}</strong> (ending in{' '}
                      {stripePaymentDetails.last4})
                    </div>
                    <div className="fs-n0 lh-md margin-bottom-10">
                      Expires{' '}
                      <strong>
                        {stripePaymentDetails.exp_month}/
                        {stripePaymentDetails.exp_year}
                      </strong>
                    </div>
                    <div className="lh-md">
                      <button
                        className="btn btn--primary btn--primary-ol btn--x-sm"
                        type="button"
                        onClick={() => showCreditCardModal()}
                      >
                        Update Card
                      </button>
                    </div>
                  </dd>
                )}
                {isEmpty(stripePaymentDetails) && (
                  <dd className="list__item">
                    <div className="fs-01 lh-md margin-bottom-5">
                      None provided
                    </div>
                    <div className="lh-md">
                      <button
                        className="btn btn--link fs-n0"
                        type="button"
                        onClick={() => showCreditCardModal()}
                      >
                        Add card
                      </button>
                    </div>
                  </dd>
                )}
              </dl>
              {usePaymentAccountsForCC ? (
                <dl className="list divider--bottom">
                  <dt className="list__title fs-00 text--md-grey margin-bottom-5">
                    Default Payment Method for Postage
                  </dt>
                  {showLockedNotice ? (
                    <dd className="list__item margin-bottom-7">
                      <div className="alert alert--error alert--sm">
                        <p className="fs-n0 lh-md margin-bottom-0">
                          <strong>
                            ACH transfers have been temporarily suspended in
                            your Ordoro account. Please contact our Support team
                            to get this resolved.
                          </strong>
                        </p>
                      </div>
                    </dd>
                  ) : defaultPaymentAccountHasIntegrationIssue ? (
                    <dd className="list__item margin-bottom-7">
                      <div className="alert alert--warning alert--sm">
                        <span className="fs-n0 lh-md">
                          Integration issue with payment account
                        </span>
                      </div>
                    </dd>
                  ) : null}

                  {postagePaymentDetails ? (
                    <dd className="list__item">
                      <div
                        className={className`lh-md margin-bottom-5 ${{
                          'text--error': showLockedNotice,
                          'text--warning':
                            !showLockedNotice &&
                            defaultPaymentAccountHasIntegrationIssue,
                        }}`}
                      >
                        {postagePaymentDetails.last4 ? (
                          <>
                            <div className="fs-01 lh-md margin-bottom-3">
                              <strong>{postagePaymentDetails.brand}</strong>{' '}
                              (ending in {postagePaymentDetails.last4})
                            </div>
                            <div className="fs-n0 lh-md margin-bottom-10">
                              <span>Credit Card ·</span>{' '}
                              <span>
                                Exp.{' '}
                                <strong>
                                  {postagePaymentDetails.exp_month}/
                                  {postagePaymentDetails.exp_year}
                                </strong>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="fs-01 lh-md margin-bottom-3">
                              <strong>{postagePaymentDetails.name}</strong>{' '}
                              (ending in {postagePaymentDetails.mask})
                            </div>
                            <div className="fs-n0 lh-md margin-bottom-10 ">
                              Bank/ACH Transfer
                            </div>
                          </>
                        )}
                      </div>

                      <div className="lh-md">
                        <button
                          className="btn btn--primary btn--primary-ol btn--x-sm"
                          type="button"
                          onClick={() => showPaymentAccountsModal()}
                        >
                          Manage Payment Methods
                        </button>
                      </div>
                    </dd>
                  ) : (
                    <dd className="list__item">
                      <div className="fs-01 lh-md margin-bottom-5">
                        None provided
                      </div>
                      <div className="lh-md">
                        <button
                          className="btn btn--link fs-n0"
                          type="button"
                          onClick={() => showPaymentAccountsModal()}
                        >
                          Add a Payment Method
                        </button>
                      </div>
                    </dd>
                  )}
                </dl>
              ) : (
                <dl className="list divider--bottom">
                  <dt className="list__title fs-00 text--md-grey margin-bottom-5">
                    Payment Method for Postage
                  </dt>
                  {!isEmpty(netZeroPaymentDetails) ? (
                    <dd className="list__item">
                      <div className="fs-01 lh-md margin-bottom-3">
                        <strong>{netZeroPaymentDetails.brand}</strong> (ending
                        in {netZeroPaymentDetails.last4})
                      </div>
                      <div className="fs-n0 lh-md margin-bottom-10">
                        <span>Credit Card ·</span>{' '}
                        <span>
                          Exp.{' '}
                          <strong>
                            {netZeroPaymentDetails.exp_month}/
                            {netZeroPaymentDetails.exp_year}
                          </strong>
                        </span>
                      </div>
                      <div className="lh-md">
                        <button
                          className="btn btn--primary btn--primary-ol btn--x-sm"
                          type="button"
                          onClick={() => showAccountCreditCardModal()}
                        >
                          Update Card
                        </button>
                      </div>
                    </dd>
                  ) : (
                    <dd className="list__item">
                      <div className="fs-01 lh-md margin-bottom-5">
                        None provided
                      </div>
                      <div className="lh-md">
                        <button
                          className="btn btn--link fs-n0"
                          type="button"
                          onClick={() => showAccountCreditCardModal()}
                        >
                          Add card
                        </button>
                      </div>
                    </dd>
                  )}
                </dl>
              )}

              <dl className="list margin-bottom-15">
                <dt className="list__title fs-00 text--md-grey margin-bottom-5">
                  Additional Options
                </dt>
                {stripeCustomerID && (
                  <dd className="list__item margin-bottom-5">
                    <button
                      type="button"
                      className="btn btn--link fs-n0"
                      onClick={() => exportBillingHistory()}
                    >
                      <span className="button-text">
                        Export billing history
                      </span>
                    </button>
                  </dd>
                )}
                {stripeCustomerID && (
                  <dd className="list__item margin-bottom-5">
                    <button
                      type="button"
                      className="btn btn--link fs-n0"
                      onClick={() =>
                        dispatch(showExportInsuranceHistoryModal())
                      }
                    >
                      <span className="button-text">
                        Export insurance cost history
                      </span>
                    </button>
                  </dd>
                )}
                {!isEmpty(netZeroPaymentDetails) && (
                  <dd className="list__item margin-bottom-5">
                    <button
                      type="button"
                      className="btn btn--link fs-n0"
                      onClick={() => exportNetZeroBillingHistory()}
                    >
                      <span className="button-text">
                        Export postage payment history
                      </span>
                    </button>
                  </dd>
                )}
                <dd className="list__item margin-bottom-0">
                  <button
                    type="button"
                    className="btn btn--link fs-n0"
                    onClick={() => showCancellationModal()}
                  >
                    <span className="button-text">Cancel your account</span>
                  </button>
                </dd>
              </dl>
            </div>
          </li>
          <li className="settings-list-item clearfix">
            <dl className="medium-6 columns settings-list-item-desc make-smaller margin-top-10">
              <dt className="margin-bottom-0">
                Have questions about our pricing plans?
              </dt>
              <dd>
                {'Please visit our '}
                <a
                  href="https://www.ordoro.com/pricing"
                  target="_blank"
                  title="Ordoro pricing"
                  rel="noopener noreferrer"
                >
                  pricing page
                </a>
                {
                  ' for an overview of our various service levels and what we charge.'
                }
              </dd>
            </dl>
            <dl className="medium-6 columns settings-list-item-desc end make-smaller margin-top-10">
              <dt className="margin-bottom-0">
                Wondering about charges on your account?
              </dt>
              <dd>
                {'For anything billing-related, please email us at '}
                <a href="mailto:info@ordoro.com" title="Drop us a line">
                  info@ordoro.com
                </a>
                {plan !== STARTER_PLAN && (
                  <span>
                    {' '}
                    or call us at <strong>512.271.9453</strong>
                  </span>
                )}
                {'.'}
              </dd>
            </dl>
          </li>
          <li className="settings-list-item clearfix">
            <div className="medium-6 columns">
              <p className="fs-n1 lh-md margin-bottom-15 text--lt-grey">
                Ordoro{' '}
                <a
                  className="make-grey--mid-dark"
                  href="https://www.ordoro.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms
                </a>{' '}
                and{' '}
                <a
                  className="make-grey--mid-dark"
                  href="https://www.ordoro.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </li>
        </ul>
      </div>
    </SettingsPanel>
  )
}
