import {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useToggleTimer from '../../../../common/hooks/useToggleTimer.js'
import NumberInput from '../../../../common/components/Form/NumberInput.js'
import Quantity from '../../../../common/components/Quantity.js'
import {GoodsReceiptLineShape} from '../../../../common/PropTypes.js'
import {
  forceReceiveLine,
  setLineReceivedValue,
} from './goodsReceiptModalFunctions.js'

export default function GoodsReceiptLine({
  line: {
    name,
    sku,
    upc,
    location,
    ordered,
    received,
    fromOtherGoodsReceipts,
    isKit,
  },
  index,
  autoFocus,
}) {
  const [highlight, startHighlightAnimation] = useToggleTimer()
  const prevReceived = useRef(received)

  useEffect(() => {
    if (received > prevReceived.current) {
      startHighlightAnimation()
    }

    prevReceived.current = received
  }, [received])

  const remaining = ordered - received - fromOtherGoodsReceipts

  return (
    <>
      <tr
        className={classNames('tr--scan-success', {
          in: highlight,
          'tr--all-received': remaining <= 0,
        })}
      >
        <td className="table__td">
          <dl className="list list--parent-sku">
            <dt className="list__title">
              <div className="inline-block margin-right-5">{name}</div>
              {isKit && (
                <div className="label__callout label__callout--grey inline-block v-align-middle">
                  <strong>KIT</strong>
                </div>
              )}
            </dt>
            <dd className="list__item">SKU: {sku}</dd>
            {upc && <dd className="list__item">UPC: {upc}</dd>}
            {!upc && (
              <dd className="list__item">No UPC set for this product</dd>
            )}
            {location && <dd className="list__item">Location: {location}</dd>}
          </dl>
        </td>
        <td className="table__td align-center">
          <strong className="lh-md fs-01">
            <Quantity value={ordered} />
          </strong>
        </td>
        <td className="table__td border-left--light align-center">
          <div>
            <strong className="lh-md fs-01">
              <Quantity value={remaining} />
            </strong>
          </div>
          {fromOtherGoodsReceipts !== 0 && (
            <div className="fs-n2 text--lt-grey margin-top-3">
              (<span>{fromOtherGoodsReceipts}</span> already received)
            </div>
          )}
        </td>
        <td className="table__td table__td--verified overflow-hidden relative border-left--light align-center">
          <div className="inline-block">
            <NumberInput
              id="received"
              value={received}
              onChange={(value) => setLineReceivedValue(index, value)}
              onIncrement={(value) => setLineReceivedValue(index, value)}
              onDecrement={(value) => setLineReceivedValue(index, value)}
              min={0}
              autoFocus={autoFocus}
            />
          </div>
          <button
            className="btn btn--link btn--force-verify lighter no-underline"
            type="button"
            tabIndex="-1"
            title="Force-verify that this line item has been packed correctly"
            onClick={() => forceReceiveLine(index)}
          >
            <span className="i-check-circle fs-01 lh-0" />
          </button>
        </td>
      </tr>
    </>
  )
}

GoodsReceiptLine.propTypes = {
  line: GoodsReceiptLineShape.isRequired,
  index: PropTypes.number.isRequired,
  autoFocus: PropTypes.bool.isRequired,
}
