import {setForm, updateForm, formsSelector, getState} from '../store.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {
  ENDICIA,
  PITNEY,
  PITNEY_MERCHANT,
  VISIBLE_USPS,
} from '../common/constants/ShipperNames.js'
import apiverson from '../common/apiverson.js'
import {showMessageToast} from '../ordoro/Header/Toast/index.js'
import {currencySymbolSelector} from '../data/company.js'
import {
  activeShippersSelector,
  shipperTypeSelector,
  autoFillPostageConfigSelector,
  balanceSelector,
} from './shippers.js'
import {
  getAccount,
  addToAccountBalance,
  amountPostageThatCanBePurchasedSelector,
} from './account.js'
import delay from '../common/delay.js'
import {limitPromise} from '../common/debounce.js'

export const SHIPPER_BALANCES = 'SHIPPER_BALANCES'

export function shipperBalancesSelector(state) {
  return (
    formsSelector(state)[SHIPPER_BALANCES] || shipperBalancesSelector.default
  )
}
shipperBalancesSelector.default = {}

export function shipperBalancesHaveLoadedSelector(state) {
  return !!formsSelector(state)[SHIPPER_BALANCES]
}

export function shipperBalanceSelector(state, {shipperID}) {
  return shipperBalancesSelector(state)[shipperID]
}

export function setShipperBalance(shipperID, shipperBalance) {
  if (shipperBalancesHaveLoadedSelector(getState())) {
    updateForm(SHIPPER_BALANCES, {[shipperID]: shipperBalance})
  } else {
    setForm(SHIPPER_BALANCES, {[shipperID]: shipperBalance})
  }
}

export async function addToShipperBalance(
  shipperID,
  amount,
  paymentType,
  paymentAccountID,
) {
  const shipperType = shipperTypeSelector(getState(), {shipperID})

  if ([ENDICIA, PITNEY_MERCHANT].includes(shipperType)) {
    const {json} = await apiverson.post(`/shipper/${shipperID}/balance`, {
      amount,
    })

    const data =
      shipperType === ENDICIA
        ? {
            postage_balance: Number(json.postage_balance),
            postage_printed: Number(json.postage_printed),
          }
        : {
            balance: Number(json.balance),
          }

    setShipperBalance(shipperID, data)
  }

  if ([PITNEY, VISIBLE_USPS].includes(shipperType)) {
    await addToAccountBalance(
      amount,
      'Adding Postage for USPS',
      paymentType,
      paymentAccountID,
    )
  }
}

export async function autoFillPostage(shipperID) {
  try {
    const {isEnabled, lowerThreshold, incrementAmount} =
      autoFillPostageConfigSelector(getState(), {shipperID})

    if (!isEnabled) {
      return
    }

    const balance = balanceSelector(getState(), {shipperID})

    if (balance > lowerThreshold) {
      return
    }

    const amountPostageThatCanBePurchased =
      amountPostageThatCanBePurchasedSelector(getState(), {
        amount: incrementAmount,
      })

    if (amountPostageThatCanBePurchased <= 0) {
      return
    }

    await addToShipperBalance(shipperID, amountPostageThatCanBePurchased)

    const currencySymbol = currencySymbolSelector(getState())

    showMessageToast(
      `Automatically added ${currencySymbol}${amountPostageThatCanBePurchased} postage to USPS account`,
    )
  } catch (err) {
    showGlobalError(
      {
        summary: 'Unable to automatically fill postage balance.',
        details: err.message || err.error_message,
      },
      err,
    )
  }
}

export async function getShipperBalance(shipperID) {
  const shipperType = shipperTypeSelector(getState(), {shipperID})

  if ([ENDICIA, PITNEY_MERCHANT].includes(shipperType)) {
    const {json} = await apiverson.get(`/shipper/${shipperID}/balance`)

    setShipperBalance(shipperID, json)
  }
  if ([PITNEY, VISIBLE_USPS].includes(shipperType)) {
    await getAccount()
  }

  await autoFillPostage(shipperID)
}

export const getShipperBalanceThrottled = limitPromise(
  async (shipperID) => {
    try {
      await delay(1000)

      await getShipperBalance(shipperID)
    } catch (err) {
      showGlobalError(
        {
          summary: 'Error getting shipper balance.',
          details: err.error_message || err.message,
        },
        err,
      )
    }
  },
  {
    limitBy(shipperID) {
      return shipperID
    },
  },
)

export const getAllShipperBalances = limitPromise(async () => {
  try {
    await delay(1000)

    const shippers = activeShippersSelector(getState())

    await Promise.all(shippers.map((shipper) => getShipperBalance(shipper.id)))
  } catch (err) {
    // Swallow error because we will see shipper errors when trying to get rates
  }
})
