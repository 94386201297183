import {useRef, useEffect, useMemo} from 'react'
import snakeCase from 'lodash/snakeCase.js'

import {updateForm, useAutoForm, useSelector} from '../../store.js'
import Zropdown, {useZropdown, useZropdownElement} from './Zropdown.js'
import {currentDropdownSelector} from '../../redux/selectors/ui/index.js'
import className from '../className.js'
import useStable from '../useStable.js'
import {
  Count,
  IfPluralNotZero,
  IfSingle,
  IfZero,
  PluralBlock,
} from './Plural.js'

export function DefaultListItem({
  option,
  index,
  form: {selectedIndex},
  values,
  onSelectInternal,
  children,
}) {
  const ref = useRef()
  const isSelected = index === selectedIndex
  useZropdownElement({ref, isSelected})

  return (
    <li ref={ref} className="list__item list__item--dropdown">
      <button
        className={`btn--link list__link--dropdown ${
          values.includes(option.value) ? 'selected' : ''
        } ${isSelected ? 'list__link--dropdown-arrow-focus' : ''}`}
        type="button"
        onClick={() => onSelectInternal(option)}
      >
        {children || option.display}
      </button>
    </li>
  )
}

export function CheckboxListItem({
  option,
  index,
  form: {selectedIndex},
  values,
  onSelectInternal,
}) {
  const ref = useRef()
  const isSelected = index === selectedIndex
  useZropdownElement({ref, isSelected})

  return (
    <li
      ref={ref}
      className="list__item list__item--dropdown"
      key={option.value}
    >
      <label
        className={`label--selectable label--sm flex margin-bottom-0 ${
          isSelected ? 'list__link--dropdown-arrow-focus' : ''
        }`}
        data-dropdown-prevent-close
      >
        <span>
          <input
            className="fs-00"
            type="checkbox"
            checked={values.includes(option.value)}
            onChange={() => onSelectInternal(option)}
          />
        </span>
        <span className="margin-left-5">{option.display}</span>
      </label>
    </li>
  )
}

/**
 * A Custom Dropdown that behaves like a native select element
 *
 * @param {Object} props
 * @param {Array} props.options
 * @param {String} [props.buttonClassName]
 * @param {Bool} [props.required]
 * @param {String} [props.errorMessage]
 * @param {String} [props.id]
 * @param {*} [props.value]
 * @param {ReactNode} [props.label]
 * @param {String} [props.labelClassName]
 * @param {String} [props.dropdownClassName]
 * @param {Function} props.onChange
 * @param {ReactNode} [props.buttonContents]
 * @param {ReactNode} [props.header]
 * @param {Function} [props.OptionComponent]
 * @param {Function} [props.IfNotSelected]
 * @param {Function} [props.IfSingleSelected]
 * @param {Function} [props.IfMultipleSelected]
 * @param {ReactNode} [props.footer]
 * @param {String} [props.noneSelectedText]
 * @param {Bool} [props.isSingleSelect]
 * @param {Bool} [props.addEmptyOption]
 * @param {String} [props.placeholder]
 * @param {Bool} [props.disabled]
 * @returns {ReactNode}
 */
export default function Zelect({
  options,
  required,
  errorMessage,
  id,
  label,
  labelClassName,
  dropdownClassName,
  value,
  onChange,
  header,
  OptionComponent,
  IfNotSelected,
  IfSingleSelected,
  IfMultipleSelected,
  footer,
  addEmptyOption,
  placeholder = 'Select an option',
  disabled,
}) {
  id = id || (label ? snakeCase(label) : 'NOT_DEFINED')
  const dropdown = `SELECT__${id.toUpperCase()}`

  options = useStable(options)
  options = useMemo(
    () => (addEmptyOption ? [{value: '', display: ''}, ...options] : options),
    [addEmptyOption, options],
  )

  value = useStable(value)
  const isMultiSelect = Array.isArray(value)

  const values = useMemo(
    () => (isMultiSelect ? value : [value]),
    [isMultiSelect, value],
  )

  const chosenOptions = useMemo(
    () => options.filter((option) => values.includes(option.value)),
    [options, values],
  )

  const form = useAutoForm(dropdown, {
    results: [],
    selectedIndex: 0,
  })

  useEffect(() => {
    const firstSelectedOptionIndex = options.indexOf(chosenOptions[0])

    updateForm(dropdown, {
      results: options,
      selectedIndex:
        firstSelectedOptionIndex !== -1 ? firstSelectedOptionIndex : 0,
    })
  }, [options, values, chosenOptions])

  const buttonRef = useRef()

  const {onConfirm, onSelectInternal, onUp, onDown} = useZropdown({
    dropdown,
    form,
    onSelect: onChange,
    closeOnConfirm: !isMultiSelect,
  })
  const currentDropdown = useSelector(currentDropdownSelector)

  OptionComponent = OptionComponent || DefaultListItem
  IfNotSelected = IfNotSelected || (() => <em>{placeholder}</em>)
  IfSingleSelected = IfSingleSelected || (() => chosenOptions[0].display)
  IfMultipleSelected =
    IfMultipleSelected ||
    (() => (
      <>
        <Count /> Selected
      </>
    ))

  return (
    <>
      <label
        className={className`label--bold ${labelClassName}`}
        htmlFor={dropdown}
      >
        <span>{label}</span>
        {required && <span className="required">*</span>}
      </label>
      <div
        className={className`wrap-outer--tag-dropdown-with-filter ${{
          open: currentDropdown === dropdown,
        }} ${dropdownClassName}`}
        data-dropdown={dropdown}
      >
        <button
          id={dropdown}
          ref={buttonRef}
          className="btn btn--dropdown btn--filter btn--filter-w-border w-100"
          type="button"
          disabled={disabled}
        >
          <span
            className={className`btn__text ${{
              'error-message': errorMessage,
            }}`}
          >
            <PluralBlock array={chosenOptions}>
              <IfZero>
                <IfNotSelected />
              </IfZero>
              <IfSingle>
                <IfSingleSelected />
              </IfSingle>
              <IfPluralNotZero>
                <IfMultipleSelected />
              </IfPluralNotZero>
            </PluralBlock>
          </span>
        </button>
        <Zropdown
          dropdown={dropdown}
          parentRef={buttonRef}
          onConfirm={onConfirm}
          onUp={onUp}
          onDown={onDown}
          header={header}
          footer={footer}
          blurOnOpen
        >
          {form.results.map((option, index) => (
            <OptionComponent
              key={option.value}
              option={option}
              index={index}
              form={form}
              values={values}
              onSelectInternal={onSelectInternal}
            />
          ))}
        </Zropdown>
      </div>
      {errorMessage && (
        <small className="error error-message">{errorMessage}</small>
      )}
    </>
  )
}
