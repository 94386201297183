import PropTypes from 'prop-types'
import {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {PACK_MODE} from '../../common/constants/PackingOrderModal.js'
import {
  setupPackShipPage,
  setPackShipLabelType,
  removePackShipPageForm,
} from '../../redux/actions/ui/packShipPage/index.js'
import {
  packShipOrderNumbersSelector,
  packShipIsLoadingSelector,
  packShipLabelTypeSelector,
  currentPackShipOrderNumberSelector,
  currentPackShipOrderNeedsContentsVerifiedSelector,
  currentPackShipOrderNeedsLabelSelector,
  currentPackShipOrderNeedsToPrintSelector,
} from '../../redux/selectors/ui/packShipPage/index.js'

import NextBackCounter from './NextBackCounter.js'
import ScanToBegin from './ScanToBegin.js'
import OrderInfo from './OrderInfo.js'
import PackPanel from './PackPanel/index.js'
import LabelConfigPanel from './LabelConfigPanel.js'
import LabelViewForm from '../OrderListPage/LabelViewForm.js'
import DonePanel from './DonePanel.js'
import Modals from '../OrderListPage/Modals/index.js'
import {hasOrderPermissionSelector} from '../../data/me.js'
import NoPermissionsPage from '../NoPermissionsPage.js'

export class PackShipPage extends Component {
  componentDidMount() {
    document.title = 'Scan/Verify Package Contents'

    this.props.setupPackShipPage()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderNumbers !== this.props.orderNumbers) {
      this.props.setupPackShipPage()
    }
  }

  componentWillUnmount() {
    this.props.removePackShipPageForm()
  }

  render() {
    const {
      orderNumbers,
      isLoading,
      labelType,
      orderNumber,
      needsContentsVerified,
      needsLabel,
      needsToPrint,
      hasOrderPermission,
      ...props
    } = this.props

    if (!hasOrderPermission) {
      return <NoPermissionsPage />
    }

    if (isLoading) {
      return null
    }

    return (
      <div id="iverson-page" className="wrap--packship-page">
        <Modals />
        <div className="wrap--row">
          <div className="medium-10 medium-centered columns">
            <h2 className="fs-04 margin-top-20 align-center">
              Scan/Verify Package Contents
            </h2>
            {orderNumbers.length === 0 ? (
              <ScanToBegin className="margin-top-30" />
            ) : (
              <>
                <NextBackCounter />
                <div className="wrap--edit-form margin-top-20 margin-bottom-20">
                  <div className="form__header--edit align-center flex--justify">
                    <p className="fs-03 margin-bottom-0">
                      <strong>Order:</strong> <span>{orderNumber}</span>
                    </p>
                    <DonePanel />
                  </div>
                  <div className="wrap--row flex">
                    <div className="medium-9 columns">
                      {needsContentsVerified && (
                        <PackPanel
                          orderNumbers={[orderNumber]}
                          mode={PACK_MODE}
                        />
                      )}

                      {!needsContentsVerified &&
                        (needsLabel || needsToPrint) && (
                          <div className="wrap--row">
                            <div className="medium-10 medium-centered columns">
                              <div className="panel--actions panel--order-action margin-top-10 margin-bottom-30">
                                <ul className="list--tabs flex-wrap clearfix">
                                  <li
                                    className={classNames('list__item--tab', {
                                      active: labelType === 'shipping',
                                    })}
                                  >
                                    <button
                                      className="btn btn--tab"
                                      type="button"
                                      onClick={() =>
                                        props.setPackShipLabelType('shipping')
                                      }
                                    >
                                      Shipping Label
                                    </button>
                                  </li>
                                  <li
                                    className={classNames('list__item--tab', {
                                      active: labelType === 'return',
                                    })}
                                  >
                                    <button
                                      className="btn btn--tab"
                                      type="button"
                                      onClick={() =>
                                        props.setPackShipLabelType('return')
                                      }
                                    >
                                      Return Label
                                    </button>
                                  </li>
                                </ul>
                                {(() => {
                                  if (needsLabel) {
                                    return (
                                      <LabelConfigPanel
                                        orderNumber={orderNumber}
                                        labelType={labelType}
                                      />
                                    )
                                  }
                                  if (needsToPrint) {
                                    return (
                                      <LabelViewForm
                                        orderNumbers={[orderNumber]}
                                        labelType={labelType}
                                        showSingleOrderNumber
                                      />
                                    )
                                  }
                                })()}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="medium-3 columns divider--left dark margin-top-10 margin-bottom-20 margin-right-0">
                      <OrderInfo />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

PackShipPage.propTypes = {
  orderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
  labelType: PropTypes.string,
  orderNumber: PropTypes.string,
  needsContentsVerified: PropTypes.bool.isRequired,
  needsLabel: PropTypes.bool.isRequired,
  needsToPrint: PropTypes.bool.isRequired,
  hasOrderPermission: PropTypes.bool.isRequired,
  setupPackShipPage: PropTypes.func.isRequired,
  setPackShipLabelType: PropTypes.func.isRequired,
  removePackShipPageForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    orderNumbers: packShipOrderNumbersSelector(state),
    isLoading: packShipIsLoadingSelector(state),
    labelType: packShipLabelTypeSelector(state),
    orderNumber: currentPackShipOrderNumberSelector(state),
    needsContentsVerified:
      currentPackShipOrderNeedsContentsVerifiedSelector(state),
    needsLabel: currentPackShipOrderNeedsLabelSelector(state),
    needsToPrint: currentPackShipOrderNeedsToPrintSelector(state),
    hasOrderPermission: hasOrderPermissionSelector(state),
  }
}

const mapDispatchToProps = {
  setupPackShipPage,
  setPackShipLabelType,
  removePackShipPageForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(PackShipPage)
