import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {isEmptyValue} from '../../common/utils.js'
import handleListSelection from '../../common/handleListSelection.js'
import {PRODUCT_DETAIL_SUPPLIERS_PANEL} from '../../common/constants/Products.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import Currency from '../../common/components/Currency.js'
import Quantity from '../../common/components/Quantity.js'
import Checkbox from '../../common/components/Checkbox.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {plural} from '../../common/components/Plural.js'
import {
  supplierIDSelector,
  poItemReceivedSelector,
  poItemNameSelector,
  poItemSKUSelector,
  poItemQuantitySelector,
  poItemSupplierSKUSelector,
  poItemPhysicalOnHandSelector,
  poItemCommittedSelector,
  poItemPOTotalCommittedSelector,
  poItemUnitPriceSelector,
  poItemDiscountAmountSelector,
  poItemTotalPriceSelector,
} from '../../data/pos.js'
import {supplierLeadTimeSelector} from '../../data/products.js'
import {productDetailURLSelector} from '../ProductDetailPage/productDetailSelectors.js'
import {showEditPOItemModal} from '../PurchaseOrderListPage/Modals/EditPOItemModal.js'
import {showRemovePOItemModal} from '../PurchaseOrderListPage/Modals/RemovePOItemModal.js'
import {POFormShape, updatePODetailForm} from './poDetailActions.js'

function Item({
  poID,
  poItemID,
  poItemIDs,
  form,
  isPOLocked,
  received,
  name,
  sku,
  quantity,
  supplier_sku,
  physical_on_hand,
  committed,
  po_total_committed,
  unit_price,
  discount_amount,
  total_price,
  productDetailURL,
  supplier_lead_time,
  ...props
}) {
  return (
    <tr>
      <td className="table__td">
        <Checkbox
          className="margin-bottom-0"
          checked={form.selectedItemIDs.includes(poItemID)}
          onClick={(checked, event) =>
            updatePODetailForm({
              selectedItemIDs: handleListSelection({
                value: poItemID,
                isSelected: checked,
                isShiftKey: event.shiftKey,
                selected: form.selectedItemIDs,
                list: poItemIDs,
              }),
            })
          }
          disabled={isPOLocked}
        />
      </td>
      <td className="table__td table__td--md">
        <LinkButton className="darker word-breaker" href={productDetailURL}>
          <strong className="block">{name}</strong>
          <span className="block">{sku}</span>
        </LinkButton>
      </td>
      <td className="table__td table__td--md">
        <LinkButton
          className="darker"
          href={`${productDetailURL}/${PRODUCT_DETAIL_SUPPLIERS_PANEL}`}
        >
          {supplier_sku}
        </LinkButton>
      </td>
      <td className="table__td table__td--md align-right">
        <ButtonLink
          className="darker lh-sm v-align-top border-underline"
          onClick={() =>
            props.showEditPOItemModal(poID, [poItemID], 'quantity')
          }
          disabled={isPOLocked}
        >
          <Quantity value={quantity} />
        </ButtonLink>
      </td>
      <td className="table__td table__td--md align-right">
        <Quantity value={received} />
      </td>
      <td className="table__td table__td--md align-right">
        {!isEmptyValue(physical_on_hand) ? (
          <Quantity value={physical_on_hand} />
        ) : (
          '-'
        )}
      </td>
      <td className="table__td table__td--md align-right">
        {!isEmptyValue(committed) ? <Quantity value={committed} /> : '-'}
      </td>
      <td className="table__td table__td--md align-right">
        {!isEmptyValue(po_total_committed) ? (
          <Quantity value={po_total_committed} />
        ) : (
          '-'
        )}
      </td>
      <td className="table__td table__td--md align-right">
        <ButtonLink
          className="darker lh-sm v-align-top border-underline"
          onClick={() =>
            props.showEditPOItemModal(poID, [poItemID], 'unit_price')
          }
          disabled={isPOLocked}
        >
          <Currency value={unit_price} />
        </ButtonLink>
      </td>
      <td className="table__td table__td--md">
        {supplier_lead_time
          ? plural(supplier_lead_time)`${supplier_lead_time} day${['s']}`
          : ''}
      </td>
      <td className="table__td table__td--md align-right">
        <ButtonLink
          className="darker lh-sm v-align-top border-underline"
          onClick={() =>
            props.showEditPOItemModal(poID, [poItemID], 'discount_amount')
          }
          disabled={isPOLocked}
        >
          <Currency value={discount_amount} />
        </ButtonLink>
      </td>
      <td className="table__td table__td--md align-right">
        <Currency value={total_price} />
      </td>
      <td className="table__td table__td--md align-right">
        <ButtonLink
          title="Remove line from PO"
          className="no-underline"
          onClick={() => props.showRemovePOItemModal(poID, [poItemID])}
          disabled={isPOLocked}
        >
          <span className="i-trash fs-00" aria-hidden="true"></span>
        </ButtonLink>
      </td>
    </tr>
  )
}

Item.propTypes = {
  poID: PropTypes.string.isRequired,
  poItemID: PropTypes.number.isRequired,
  poItemIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  form: POFormShape.isRequired,
  isPOLocked: PropTypes.bool.isRequired,
  received: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  supplier_sku: PropTypes.string.isRequired,
  physical_on_hand: PropTypes.number,
  committed: PropTypes.number,
  po_total_committed: PropTypes.number,
  unit_price: PropTypes.number.isRequired,
  discount_amount: PropTypes.number.isRequired,
  total_price: PropTypes.number.isRequired,
  productDetailURL: PropTypes.string.isRequired,
  supplier_lead_time: PropTypes.number,
  showEditPOItemModal: PropTypes.func.isRequired,
  showRemovePOItemModal: PropTypes.func.isRequired,
}

function mapStateToProps(state, {poID, poItemID}) {
  const sku = poItemSKUSelector(state, {poID, poItemID})
  const supplierID = supplierIDSelector(state, {poID})

  return {
    received: poItemReceivedSelector(state, {poID, poItemID}),
    name: poItemNameSelector(state, {poID, poItemID}),
    sku,
    quantity: poItemQuantitySelector(state, {poID, poItemID}),
    supplier_sku: poItemSupplierSKUSelector(state, {poID, poItemID}),
    physical_on_hand: poItemPhysicalOnHandSelector(state, {poID, poItemID}),
    committed: poItemCommittedSelector(state, {poID, poItemID}),
    po_total_committed: poItemPOTotalCommittedSelector(state, {poID, poItemID}),
    unit_price: poItemUnitPriceSelector(state, {poID, poItemID}),
    discount_amount: poItemDiscountAmountSelector(state, {poID, poItemID}),
    total_price: poItemTotalPriceSelector(state, {poID, poItemID}),
    productDetailURL: productDetailURLSelector(state, {sku}),
    supplier_lead_time: supplierLeadTimeSelector(state, {sku, supplierID}),
  }
}

const mapDispatchToProps = {
  showEditPOItemModal,
  showRemovePOItemModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)
