import DropdownItem from './DropdownItem.js'
import className from '../../className.js'

/**
 * Dropdown Item Button
 *
 * @param {Object} props
 * @param {*} props.selectedValue
 * @param {*} props.value
 * @param {ReactNode} props.children
 * @param {Boolean} props.last
 * @param {Function} props.onClick
 * @returns ReactNode
 */
export default function DropdownItemButton({
  selectedValue,
  value,
  children,
  last,
  onClick,
}) {
  return (
    <DropdownItem last={last}>
      <button
        type="button"
        className={className`btn--link list__link--dropdown ${{
          selected: value !== undefined && value === selectedValue,
        }}`}
        onClick={() => onClick(value)}
      >
        {children}
      </button>
    </DropdownItem>
  )
}
