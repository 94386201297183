import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import classNames from 'classnames'

import HeaderDropdown from '../../../../../common/components/List/HeaderDropdown.js'
import {showPrintPickPackModal} from '../../../../../common/components/Modal/PrintPickPackModal.js'
import {showAddressLabelModal} from '../../../../OrderListPage/Modals/AddressLabelModal.js'
import {
  activeOrderNumbersSelector,
  batchReferenceIDSelector,
} from '../../../../OrderListPage/orderListSelectors.js'

export default function PrintDropdown({
  selectedOrderNumbers,
  className,
  orderActions,
}) {
  const batchReferenceID = useSelector(batchReferenceIDSelector)
  const activeOrderNumbers = useSelector(activeOrderNumbersSelector)

  if (batchReferenceID) {
    return null
  }

  return (
    <HeaderDropdown
      label="Print"
      className={classNames('margin-left-3', className)}
      dropdown="DROPDOWN_ORDER_LIST_PRINT_ACTIONS"
    >
      <li className="list__item list__item--dropdown">
        <button
          className="btn--link list__link--dropdown"
          type="button"
          onClick={() => showPrintPickPackModal(activeOrderNumbers)}
        >
          Pick/Pack Lists
        </button>
      </li>
      {orderActions.address_labels && (
        <li className="list__item list__item--dropdown">
          <button
            className="btn--link list__link--dropdown meta-printdropdown-button-address-label-modal"
            type="button"
            onClick={() => showAddressLabelModal(selectedOrderNumbers)}
          >
            Address Labels
          </button>
        </li>
      )}
    </HeaderDropdown>
  )
}

PrintDropdown.propTypes = {
  selectedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  orderActions: PropTypes.objectOf(PropTypes.bool).isRequired,
}
