import PropTypes from 'prop-types'
import {useMemo} from 'react'
import sortBy from 'lodash/sortBy.js'

import {dispatch, useSelector} from '../../store.js'
import handleListSelection from '../../common/handleListSelection.js'
import Checkbox from '../../common/components/Checkbox.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import {showFeatureLockedModal} from '../Modals/FeatureLockedModal.js'
import {showEditProductWarehouseModal} from '../ProductListPage/Modals/EditProductWarehouseModal.js'
import {hasProductUpdateInventoryPermissionSelector} from '../../data/me.js'
import {
  usesInventorySelector,
  showFeatureLocksSelector,
  hasUseMOsFeatureSelector,
  usePurchaseOrdersSelector,
  usesAppsSelector,
} from '../../data/company.js'
import {
  productSelector,
  productWarehousesSelector,
  getCanEditTotalOnHand,
  isBOMSelector,
} from '../../data/products.js'
import {warehousesSelector, getWarehouseName} from '../../data/warehouses.js'
import {updateProductDetailForm} from './productDetailActions.js'
import {
  productDetailFormSelector,
  skuSelector,
  productWarehouseIDsSelector,
  areAllWarehousesSelectedSelector,
  areIndeterminateWarehousesSelectedSelector,
} from './productDetailSelectors.js'
import {showUpgradeModal} from '../Modals/UpgradeModal.js'
import Quantity from '../../common/components/Quantity.js'

function LockedHeaderButton({featureName, children}) {
  const usesApps = useSelector(usesAppsSelector)

  return (
    <ButtonLink
      className="lh-inherit align-right op-50 no-underline"
      title="Feature Locked"
      onClick={() =>
        usesApps ? showUpgradeModal() : showFeatureLockedModal(featureName)
      }
    >
      <div
        className="i-lock-closed fs-01 v-align-middle lh-sm no-underline align-center op-75 margin-right-1"
        aria-hidden="true"
      />
      <div>{children}</div>
    </ButtonLink>
  )
}
LockedHeaderButton.propTypes = {
  featureName: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default function Warehouses() {
  const warehouses = useSelector(warehousesSelector)
  const hasProductUpdateInventoryPermission = useSelector(
    hasProductUpdateInventoryPermissionSelector,
  )
  const usesInventory = useSelector(usesInventorySelector)
  const usePurchaseOrders = useSelector(usePurchaseOrdersSelector)
  const hasUseMOsFeature = useSelector(hasUseMOsFeatureSelector)
  const showFeatureLocks = useSelector(showFeatureLocksSelector)
  const sku = useSelector(skuSelector)
  const form = useSelector(productDetailFormSelector)
  const productWarehouseIDs = useSelector(productWarehouseIDsSelector)
  const areAllWarehousesSelected = useSelector(areAllWarehousesSelectedSelector)
  const areIndeterminateWarehousesSelected = useSelector(
    areIndeterminateWarehousesSelectedSelector,
  )
  const product = useSelector((state) => productSelector(state, {sku}))
  const productWarehouses = useSelector((state) =>
    productWarehousesSelector(state, {sku}),
  )
  const isBOM = useSelector((state) => isBOMSelector(state, {sku}))

  const sortedProductWarehouses = useMemo(
    () =>
      sortBy(productWarehouses, (productWarehouse) =>
        getWarehouseName(warehouses[productWarehouse.id]),
      ),
    [productWarehouses],
  )
  const canEditTotalOnHandLookup = useMemo(
    () =>
      hasProductUpdateInventoryPermission
        ? Object.values(warehouses).reduce((prev, warehouse) => {
            prev[warehouse.id] = getCanEditTotalOnHand(product, warehouse)
            return prev
          }, {})
        : {},
    [hasProductUpdateInventoryPermission, warehouses],
  )
  const selectedThatCanEditTotalOnHand = useMemo(
    () =>
      form.selectedWarehouseIDs.filter((id) => canEditTotalOnHandLookup[id]),
    [form.selectedWarehouseIDs, canEditTotalOnHandLookup],
  )

  return (
    <table className="table fs-00">
      <thead>
        <tr className="fs-n0">
          <th className="table__th table__th--md w-1">
            <Checkbox
              className="margin-bottom-0"
              checked={areAllWarehousesSelected}
              indeterminate={areIndeterminateWarehousesSelected}
              onClick={(checked) =>
                updateProductDetailForm({
                  selectedWarehouseIDs: checked ? productWarehouseIDs : [],
                })
              }
            />
          </th>
          <th className="table__th table__th--md w-15">Warehouse</th>
          <th className="table__th table__th--md w-5">
            {form.selectedWarehouseIDs.length === 0 ? (
              'Location'
            ) : (
              <ButtonLink
                className="darker lh-inherit"
                title="Bulk Update Location in Warehouses"
                onClick={() =>
                  dispatch(
                    showEditProductWarehouseModal(
                      sku,
                      form.selectedWarehouseIDs,
                      'location_in_warehouse',
                    ),
                  )
                }
              >
                Location
              </ButtonLink>
            )}
          </th>
          {showFeatureLocks || usesInventory ? (
            <>
              <th
                className="table__th table__th--md align-right"
                title="Physical On Hand"
              >
                {!usesInventory ? (
                  <LockedHeaderButton featureName="inventory">
                    POH
                  </LockedHeaderButton>
                ) : selectedThatCanEditTotalOnHand.length === 0 ? (
                  <>
                    <span>POH</span>{' '}
                    <span className="unbold">
                      (<Quantity value={product.total_on_hand} />)
                    </span>
                  </>
                ) : (
                  <ButtonLink
                    className="darker lh-inherit align-right"
                    title="Bulk Update Physical On Hand Values"
                    onClick={() =>
                      dispatch(
                        showEditProductWarehouseModal(
                          sku,
                          selectedThatCanEditTotalOnHand,
                          'physical_on_hand',
                        ),
                      )
                    }
                  >
                    <span>POH</span>{' '}
                    <span className="unbold">
                      (<Quantity value={product.total_on_hand} />)
                    </span>
                  </ButtonLink>
                )}
              </th>
              <th
                className="table__th table__th--md align-right"
                title="Available On Hand"
              >
                {!usesInventory ? (
                  <LockedHeaderButton featureName="inventory">
                    AOH
                  </LockedHeaderButton>
                ) : (
                  <>
                    <span>AOH</span>{' '}
                    <span className="unbold">
                      (<Quantity value={product.total_available} />)
                    </span>
                  </>
                )}
              </th>
              <th
                className="table__th table__th--md align-right"
                title="Committed"
              >
                {!usesInventory ? (
                  <LockedHeaderButton featureName="inventory">
                    CMT
                  </LockedHeaderButton>
                ) : (
                  <>
                    <span>CMT</span>{' '}
                    <span className="unbold">
                      (<Quantity value={product.total_committed} />)
                    </span>
                  </>
                )}
              </th>
              {((showFeatureLocks && !hasUseMOsFeature) || isBOM) && (
                <th
                  className="table__th table__th--md align-right"
                  title="On Manufacturing Order"
                >
                  {!hasUseMOsFeature ? (
                    <LockedHeaderButton featureName="mo">
                      Mfg ORD
                    </LockedHeaderButton>
                  ) : (
                    <>
                      <span>Mfg ORD</span>{' '}
                      <span className="unbold">
                        (<Quantity value={product.total_mfg_ordered} />)
                      </span>
                    </>
                  )}
                </th>
              )}
              <th
                className="table__th table__th--md align-right"
                title="On Purchase Order"
              >
                {!usePurchaseOrders ? (
                  <LockedHeaderButton featureName="po">
                    PO ReORD
                  </LockedHeaderButton>
                ) : (
                  <>
                    <span>PO ReORD</span>{' '}
                    <span className="unbold">
                      (<Quantity value={product.po_total_committed} />)
                    </span>
                  </>
                )}
              </th>
              <th
                className="table__th table__th--md align-right w-15"
                title="Low Stock Threshold"
              >
                {!usesInventory ? (
                  <LockedHeaderButton featureName="inventory">
                    Low Stock THLD
                  </LockedHeaderButton>
                ) : form.selectedWarehouseIDs.length === 0 ? (
                  'Low Stock THLD'
                ) : (
                  <ButtonLink
                    className="darker lh-inherit align-right"
                    title="Bulk Update Low Stock Thresholds"
                    onClick={() =>
                      dispatch(
                        showEditProductWarehouseModal(
                          sku,
                          form.selectedWarehouseIDs,
                          'low_stock_threshold',
                        ),
                      )
                    }
                  >
                    Low Stock THLD
                  </ButtonLink>
                )}
              </th>
            </>
          ) : (
            <th className="table__th table__th--md w-15">&nbsp;</th>
          )}
        </tr>
      </thead>
      <tbody className="table__tbody table__tbody--lines">
        {sortedProductWarehouses.map((productWarehouse) => (
          <tr key={productWarehouse.id}>
            <td className="table__td">
              <Checkbox
                key={productWarehouse.id}
                className="margin-bottom-0"
                checked={form.selectedWarehouseIDs.includes(
                  productWarehouse.id,
                )}
                onClick={(checked, event) =>
                  updateProductDetailForm({
                    selectedWarehouseIDs: handleListSelection({
                      value: productWarehouse.id,
                      isSelected: checked,
                      isShiftKey: event.shiftKey,
                      selected: form.selectedWarehouseIDs,
                      list: productWarehouseIDs,
                    }),
                  })
                }
              />
            </td>
            <td className="table__td table__td--md">
              <strong className="lh-sm-md">
                {getWarehouseName(warehouses[productWarehouse.id])}
              </strong>
            </td>
            <td className="table__td table__td--md">
              <ButtonLink
                className="darker v-align-base lh-sm-md"
                onClick={() =>
                  dispatch(
                    showEditProductWarehouseModal(
                      sku,
                      [productWarehouse.id],
                      'location_in_warehouse',
                    ),
                  )
                }
              >
                {productWarehouse.location_in_warehouse ? (
                  productWarehouse.location_in_warehouse
                ) : (
                  <em>None specified</em>
                )}
              </ButtonLink>
            </td>
            {showFeatureLocks || usesInventory ? (
              <>
                <td className="table__td table__td--md align-right">
                  {!usesInventory ? (
                    <span className="op-30">—</span>
                  ) : hasProductUpdateInventoryPermission &&
                    getCanEditTotalOnHand(
                      product,
                      warehouses[productWarehouse.id],
                    ) ? (
                    <ButtonLink
                      className="darker v-align-base"
                      onClick={() =>
                        dispatch(
                          showEditProductWarehouseModal(
                            sku,
                            [productWarehouse.id],
                            'physical_on_hand',
                          ),
                        )
                      }
                    >
                      <Quantity value={productWarehouse.physical_on_hand} />
                    </ButtonLink>
                  ) : (
                    <span>
                      <Quantity value={productWarehouse.physical_on_hand} />
                    </span>
                  )}
                </td>
                <td className="table__td table__td--md align-right">
                  <span>
                    {!usesInventory ? (
                      <span className="op-30">—</span>
                    ) : (
                      <Quantity value={productWarehouse.available} />
                    )}
                  </span>
                </td>
                <td className="table__td table__td--md align-right">
                  <span>
                    {!usesInventory ? (
                      <span className="op-30">—</span>
                    ) : (
                      <Quantity value={productWarehouse.committed} />
                    )}
                  </span>
                </td>
                {((showFeatureLocks && !hasUseMOsFeature) || isBOM) && (
                  <td className="table__td table__td--md align-right">
                    <span>
                      {!hasUseMOsFeature ? (
                        <span className="op-30">—</span>
                      ) : (
                        <Quantity value={productWarehouse.mfg_ordered} />
                      )}
                    </span>
                  </td>
                )}
                <td className="table__td table__td--md align-right">
                  <span>
                    {!usesInventory ? (
                      <span className="op-30">—</span>
                    ) : (
                      <Quantity value={productWarehouse.po_committed} />
                    )}
                  </span>
                </td>
                <td className="table__td table__td--md align-right">
                  {!usesInventory ? (
                    <span className="op-30">—</span>
                  ) : (
                    <ButtonLink
                      className="darker v-align-base lh-sm-md"
                      onClick={() =>
                        dispatch(
                          showEditProductWarehouseModal(
                            sku,
                            [productWarehouse.id],
                            'low_stock_threshold',
                          ),
                        )
                      }
                    >
                      <Quantity value={productWarehouse.low_stock_threshold} />
                    </ButtonLink>
                  )}
                </td>
              </>
            ) : (
              <td className="table__td table__td--md align-right">&nbsp;</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
