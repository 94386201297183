import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'
import get from 'lodash/get.js'
import debounce from 'lodash/debounce.js'

import {
  setForm,
  updateForm,
  formsSelector,
  removeFormValue,
  getState,
} from '../store.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import api from '../common/api.js'
import {showPaymentAccountsModal} from '../ordoro/Modals/PaymentAccountsModal.js'
import {getNounsFromValue, tokenizeOptions} from '../common/tokenizeOptions.js'
import {
  getPaymentRecords,
  paymentAuthorizationDecisionSelector,
  paymentTransferStatusSelector,
  PLAID_TRANSFER_STATUS_PENDING,
  PLAID_TRANSFER_STATUS_POSTED,
  PLAID_USER_ACTION_REQUIRED,
} from './paymentRecords.js'

export const PAYMENT_ACCOUNTS = 'PAYMENT_ACCOUNTS'
export const PLAID = 'plaid'
export const STRIPE_PA = 'stripe'
export const NET_ZERO_PA = 'net_zero'
export const ACH_PT = 'ach'
export const SAME_DAY_ACH_PT = 'same-day-ach'
export const CREDIT_CARD_PT = 'credit-card'
export const PLAID_PENDING_MANUAL_VERIFICATION = 'pending_manual_verification'

export const PAYMENT_TYPE_OPTIONS = [
  {value: ACH_PT, display: 'Standard ACH'},
  {value: SAME_DAY_ACH_PT, display: 'Same Day ACH'},
  {value: CREDIT_CARD_PT, display: 'Credit Card'},
]

export function paymentAccountsSelector(state) {
  return (
    formsSelector(state)[PAYMENT_ACCOUNTS] || paymentAccountsSelector.default
  )
}
paymentAccountsSelector.default = {}

export function paymentAccountsHaveLoadedSelector(state) {
  return !!formsSelector(state)[PAYMENT_ACCOUNTS]
}

export function paymentAccountSelector(state, {paymentAccountID}) {
  return paymentAccountsSelector(state)[paymentAccountID]
}

export function getPaymentAccountName(paymentAccount) {
  let name

  if (paymentAccount.payment_backend === NET_ZERO_PA) {
    const {last4, card_type} = get(
      paymentAccount,
      ['payment_method_response'],
      {},
    )

    name = last4 ? `${card_type} (ending in ${last4})` : null
  } else if (paymentAccount.payment_backend === STRIPE_PA) {
    const {last4, brand} = get(
      paymentAccount,
      ['activation_response', 'sources', 'data', 0],
      {},
    )

    name = last4 ? `${brand} (ending in ${last4})` : null
  } else if (paymentAccount.payment_backend === PLAID) {
    name = get(paymentAccount, ['activation_response', 'account', 'name'])
  }

  return name || `Payment Account (${paymentAccount.id}`
}

export const paymentAccountsSortedByNameSelector = createSelector(
  paymentAccountsSelector,
  (paymentAccounts) =>
    sortBy(paymentAccounts, getPaymentAccountName).filter(
      ({archive_date}) => !archive_date,
    ),
)

export const postagePaymentAccountsSelector = createSelector(
  paymentAccountsSortedByNameSelector,
  (paymentAccounts) =>
    paymentAccounts.filter(({payment_backend}) =>
      [PLAID, NET_ZERO_PA].includes(payment_backend),
    ),
)

export const postagePaymentAccountOptionsSelector = createSelector(
  postagePaymentAccountsSelector,
  (shippers) =>
    shippers.map((paymentAccount) => {
      const name = getPaymentAccountName(paymentAccount)
      return {
        value: paymentAccount.id,
        display: name,
        entity: paymentAccount,
        type: PAYMENT_ACCOUNTS,
        nouns: [...getNounsFromValue(name), paymentAccount.payment_backend],
      }
    }),
)

export const postagePaymentAccountOptionTokensSelector = createSelector(
  postagePaymentAccountOptionsSelector,
  (options) => tokenizeOptions(options),
)

export function defaultPaymentAccountSelector(state) {
  return postagePaymentAccountsSelector(state).find(
    ({is_default}) => is_default,
  )
}

export function hasNeedsToFinishSetupSelector(state, {paymentAccountID}) {
  const paymentAccount = paymentAccountSelector(state, {paymentAccountID})

  return (
    paymentAccount?.activation_response?.account?.verification_status ===
    PLAID_PENDING_MANUAL_VERIFICATION
  )
}

export function hasInprogressAccountTransferSelector(
  state,
  {paymentAccountID},
) {
  const paymentAccount = paymentAccountSelector(state, {paymentAccountID})

  if (!paymentAccount) {
    return false
  }

  const transferStatus = paymentTransferStatusSelector(state, {
    paymentAccountID,
  })

  return [PLAID_TRANSFER_STATUS_PENDING, PLAID_TRANSFER_STATUS_POSTED].includes(
    transferStatus,
  )
}

export function hasPaymentAccountIntegrationIssueSelector(
  state,
  {paymentAccountID},
) {
  const paymentAccount = paymentAccountSelector(state, {paymentAccountID})

  if (!paymentAccount) {
    return false
  }

  if (paymentAccount.payment_backend !== PLAID) {
    return false
  }

  const authorizationDecision = paymentAuthorizationDecisionSelector(state, {
    paymentAccountID,
  })

  return (
    authorizationDecision === PLAID_USER_ACTION_REQUIRED ||
    !!get(paymentAccount, ['activation_response', 'expired']) ||
    paymentAccount.locked
  )
}

export function paymentAccountIntegrationIssuesSelector(state) {
  const paymentAccounts = paymentAccountsSortedByNameSelector(state)

  return paymentAccounts
    .filter((paymentAccount) =>
      hasPaymentAccountIntegrationIssueSelector(state, {
        paymentAccountID: paymentAccount.id,
      }),
    )
    .map((paymentAccount) => ({
      id: paymentAccount.id,
      type: 'payment_account',
      name: getPaymentAccountName(paymentAccount),
      message: 'needs to be reauthorized',
      errorType: 'needs_reauth',
      onClick() {
        showPaymentAccountsModal()
      },
    }))
}

export function anyPaymentAccountHasIntegrationIssueSelector(state) {
  return paymentAccountIntegrationIssuesSelector(state).length > 0
}

export function defaultPaymentAccountHasIntegrationIssueSelector(state) {
  const paymentAccount = defaultPaymentAccountSelector(state)

  return (
    !!paymentAccount &&
    hasPaymentAccountIntegrationIssueSelector(state, {
      paymentAccountID: paymentAccount.id,
    })
  )
}

export function anyPaymentAccountIsLockedSelector(state) {
  const paymentAccounts = postagePaymentAccountsSelector(state)

  return !!paymentAccounts.find(({locked}) => locked)
}

export const stripePaymentAccountSelector = createSelector(
  paymentAccountsSortedByNameSelector,
  (paymentAccounts) =>
    paymentAccounts.find(({payment_backend}) => payment_backend === STRIPE_PA),
)

export const netZeroPaymentAccountSelector = createSelector(
  paymentAccountsSortedByNameSelector,
  (paymentAccounts) =>
    paymentAccounts.find(
      ({payment_backend}) => payment_backend === NET_ZERO_PA,
    ),
)

export function setPaymentAccounts(paymentAccounts) {
  setForm(PAYMENT_ACCOUNTS, keyBy(paymentAccounts, 'id'))
}

export function setPaymentAccount(paymentAccount) {
  updateForm(PAYMENT_ACCOUNTS, {[paymentAccount.id]: paymentAccount})
}

export function removePaymentAccount(paymentAccountID) {
  removeFormValue(PAYMENT_ACCOUNTS, [paymentAccountID])
}

export async function getPaymentAccounts() {
  try {
    const paymentAccounts = await fetchAllAPI(
      '/company/payment/',
      'payment_account',
    )

    setPaymentAccounts(paymentAccounts)

    await getPaymentRecords()
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting payment accounts.',
        details: err.message || err.error_message,
      },
      `Error getting payment accounts. ${err.error_message || err.message}`,
      err,
    )

    setPaymentAccounts([])
  }

  return paymentAccountsSelector(getState())
}

export const getPaymentsAccountsDebounced = debounce(getPaymentAccounts, 500)

export async function getPaymentAccount(paymentAccountID) {
  const {json: paymentAccount} = await api.get(
    `/company/payment/${paymentAccountID}/`,
  )

  setPaymentAccount(paymentAccount)

  return paymentAccount
}

export async function createPaymentAccount(params) {
  const {json: paymentAccount} = await api.post(`/company/payment/`, params)

  setPaymentAccount(paymentAccount)

  return paymentAccount
}

export async function updatePaymentAccount(paymentAccountID, params) {
  const {json: paymentAccount} = await api.put(
    `/company/payment/${paymentAccountID}/`,
    params,
  )

  setPaymentAccount(paymentAccount)

  return paymentAccount
}
