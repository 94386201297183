import PropTypes from 'prop-types'
import Quantity from '../Quantity.js'

export default function ListItemsCount({limit, offset, count, thing}) {
  const fromCount = offset + 1
  const toCount = Math.min(offset + limit, count)

  return (
    <div className="inline-block list-items-count fs-n0 lh-sm v-align-middle">
      {count === 0 ? null : (
        <span>
          <strong>
            <span>{fromCount}</span>-<span>{toCount}</span>
          </strong>
          <span> of </span>
        </span>
      )}
      <strong>
        <span>
          <Quantity value={count} />
        </span>{' '}
        <span>{thing}</span>
      </strong>
    </div>
  )
}

ListItemsCount.propTypes = {
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  thing: PropTypes.string.isRequired,
}
