import {combineReducers} from 'redux'
import isString from 'lodash/isString.js'

import INITIAL_STATE from '../INITIAL_STATE.js'
import parseHash from '../../../common/parseHash.js'
import {
  SETTINGS_PAGE,
  PRODUCT_DETAIL_PAGE,
  DATA_IMPORT_PAGE,
  PACK_SHIP_PAGE,
  CALL_UP_PAGE,
} from '../../../common/constants/Pages.js'
import {
  RETURN_ORDER_PLURAL_URI_COMPONENT,
  RETURN_ORDER_SINGLE_URI_COMPONENT,
} from '../../../common/constants/ReturnOrders.js'
import {
  PRODUCT_PLURAL_URI_COMPONENT,
  PRODUCT_SINGLE_URI_COMPONENT,
} from '../../../common/constants/Products.js'
import {MOCK_ABODE_URI_COMPONENT} from '../../../ordoro/MockAbodePage/mockAbodeFunctions.js'
import {
  PURCHASE_ORDER_PLURAL_URI_COMPONENT,
  PURCHASE_ORDER_SINGLE_URI_COMPONENT,
} from '../../../common/constants/PurchaseOrders.js'
import {
  MO_PLURAL_URI_COMPONENT,
  MO_SINGLE_URI_COMPONENT,
} from '../../../common/constants/MOs.js'
import {BATCH_PLURAL_URI_COMPONENT} from '../../../common/constants/Batches.js'

import {
  NAVIGATE,
  SET_LOCATION,
  SET_CURRENT_PAGE,
  SET_IS_STARTED,
  SET_IS_LOADING,
  SET_GLOBAL_ERROR,
  SET_ENDICIA_ACCOUNT_ERROR,
  SET_NOTIFICATION,
  SET_IS_REQUESTING,
  SET_API_LOCKED_LATCH,
  SET_ZUCK_COMPANY_ID,
  SET_ZUCK_SUPPRESS_APP_MESSAGE,
} from '../../actions/ui/index.js'

import dataImport from './dataImport/index.js'
import settings from './settings/index.js'
import modals from './modals/index.js'
import forms from './forms.js'
import currentDropdown from './currentDropdown.js'
import dashboard from './dashboard.js'
import tasks from './tasks.js'
import modal from './modal.js'
import userValidity from './userValidity.js'
import {REPORTS_PAGE_URI_COMPONENT} from '../../../ordoro/ReportsPage/reportsPageSelectors.js'
import {CALL_UP_URI_COMPONENT} from '../../../ordoro/CallUpPage/index.js'
import {
  ORDER_PLURAL_URI_COMPONENT,
  ORDER_SINGLE_URI_COMPONENT,
} from '../../../common/constants/Orders.js'
import {DASHBOARD_URI_COMPONENT} from '../../../ordoro/DashboardPage/dashboardFunctions.js'
import {WELCOME_URI_COMPONENT} from '../../../common/constants/index.js'

function isLocalhost(state = INITIAL_STATE.ui.isLocalhost) {
  return state
}

function route(state = INITIAL_STATE.ui.route, action = {}) {
  if (action.type === NAVIGATE) {
    return action.payload
  }

  return state
}

function currentPage(state = '', action = {}) {
  if (action.type === SET_CURRENT_PAGE) {
    return action.payload
  }

  if (action.type === NAVIGATE) {
    const [page, ...rest] = action.payload.pathComponents

    if (page === 'createorder') {
      return state
    }
    if (page === WELCOME_URI_COMPONENT) {
      return state
    }
    if (page === DASHBOARD_URI_COMPONENT) {
      return state
    }
    if (page === ORDER_PLURAL_URI_COMPONENT) {
      return state
    }
    if (page === ORDER_SINGLE_URI_COMPONENT) {
      return state
    }
    if (page === PRODUCT_SINGLE_URI_COMPONENT && rest.length > 0) {
      return PRODUCT_DETAIL_PAGE
    }
    if (
      [PRODUCT_PLURAL_URI_COMPONENT, PRODUCT_SINGLE_URI_COMPONENT].includes(
        page,
      )
    ) {
      return state
    }
    if (page === PURCHASE_ORDER_PLURAL_URI_COMPONENT) {
      return state
    }
    if (page === PURCHASE_ORDER_SINGLE_URI_COMPONENT) {
      return state
    }
    if (page === 'analytics') {
      return state
    }
    if (
      page === 'dataimport' ||
      (page === 'settings' && rest[0] === 'dataimport')
    ) {
      return DATA_IMPORT_PAGE
    }
    if (page === 'settings') {
      return SETTINGS_PAGE
    }
    if (page === 'pack_ship') {
      return PACK_SHIP_PAGE
    }
    if (page === RETURN_ORDER_PLURAL_URI_COMPONENT) {
      return state
    }
    if (page === RETURN_ORDER_SINGLE_URI_COMPONENT && rest.length > 0) {
      return state
    }
    if (page === MOCK_ABODE_URI_COMPONENT) {
      return state
    }
    if (page === REPORTS_PAGE_URI_COMPONENT) {
      return state
    }
    if (page === MO_PLURAL_URI_COMPONENT) {
      return state
    }
    if (page === MO_SINGLE_URI_COMPONENT && rest.length > 0) {
      return state
    }
    if (page === BATCH_PLURAL_URI_COMPONENT) {
      return state
    }
    if (page === CALL_UP_URI_COMPONENT) {
      return CALL_UP_PAGE
    }

    return state
  }

  return state
}

function isStarted(state = INITIAL_STATE.ui.isStarted, action = {}) {
  if (action.type === SET_IS_STARTED) {
    return action.payload
  }

  return state
}

function isLoading(state = INITIAL_STATE.ui.isLoading, action = {}) {
  if (action.type === SET_IS_LOADING) {
    return action.payload
  }

  return state
}

function globalError(state = INITIAL_STATE.ui.globalError, action = {}) {
  if (action.type === SET_GLOBAL_ERROR) {
    if (action.payload === null) {
      return {}
    } else if (isString(action.payload)) {
      // TODO: this won't be necessary once every call to the setGlobalError() action is updated
      return {
        summary: action.payload,
        details: null,
      }
    } else {
      return action.payload
    }
  }

  return state
}

function endiciaAccountError(
  state = INITIAL_STATE.ui.endiciaAccountError,
  action = {},
) {
  if (action.type === SET_ENDICIA_ACCOUNT_ERROR) {
    return action.payload
  }

  return state
}

function notification(state = INITIAL_STATE.ui.notification, action = {}) {
  if (action.type === SET_NOTIFICATION) {
    return action.payload
  }

  return state
}

function isRequesting(state = INITIAL_STATE.ui.isRequesting, action = {}) {
  if (action.type === SET_IS_REQUESTING) {
    return action.payload
  }

  return state
}

function apiLockedLatch(state = INITIAL_STATE.ui.apiLockedLatch, action = {}) {
  if (action.type === SET_API_LOCKED_LATCH) {
    return action.payload
  }

  return state
}

function zuckCompanyID(state = INITIAL_STATE.ui.zuckCompanyID, action = {}) {
  if (action.type === SET_ZUCK_COMPANY_ID) {
    return Number(action.payload) || INITIAL_STATE.ui.zuckCompanyID
  }

  return state
}

function zuckSuppressAppMessage(
  state = INITIAL_STATE.ui.zuckSuppressAppMessage,
  action = {},
) {
  if (action.type === SET_ZUCK_SUPPRESS_APP_MESSAGE) {
    return Number(action.payload) || INITIAL_STATE.ui.zuckSuppressAppMessage
  }

  return state
}

function location(state = parseHash(window.location.hash), action = {}) {
  if ([NAVIGATE, SET_LOCATION].includes(action.type)) {
    return action.payload
  }

  return state
}

export default combineReducers({
  isLocalhost,
  route,
  currentDropdown,
  currentPage,
  isStarted,
  isLoading,
  globalError,
  endiciaAccountError,
  notification,
  isRequesting,
  apiLockedLatch,
  zuckCompanyID,
  zuckSuppressAppMessage,
  dataImport,
  settings,
  modals,
  forms,
  dashboard,
  tasks,
  modal,
  location,
  userValidity,
})
