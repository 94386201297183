import {dispatch, useSelector} from '../../store.js'
import ButtonAction from '../../common/components/Button/ButtonAction.js'
import {showMarkAsSentModal} from '../PurchaseOrderListPage/Modals/MarkAsSentModal.js'
import {showSendToSupplierModal} from '../PurchaseOrderListPage/Modals/SendToSupplierModal.js'
import {showCancelPOModal} from '../PurchaseOrderListPage/Modals/CancelPOModal.js'
import {printURLSelector, poActionsSelector} from '../../data/pos.js'
import {poIDSelector} from './poDetailSelectors.js'
import AddPOTagsDropdown from '../PurchaseOrderListPage/AddPOTagsDropdown.js'
import {defaultPurchaseOrderListHashSelector} from '../PurchaseOrderListPage/purchaseOrderListSelectors.js'

export default function Controls() {
  const poID = useSelector(poIDSelector)
  const defaultPurchaseOrderListHash = useSelector(
    defaultPurchaseOrderListHashSelector,
  )

  const printURL = useSelector((state) => printURLSelector(state, {poID}))
  const poActions = useSelector((state) =>
    poActionsSelector(state, {poIDs: [poID]}),
  )

  return (
    <div className="wrap--row">
      <div className="medium-12 columns">
        <div className="wrap--list-header flex--justify">
          <ul className="list list--no-style">
            <li className="inline-block v-align-base divider--right">
              <a
                href={defaultPurchaseOrderListHash}
                className="btn btn--action"
                title="Back to purchase orders"
              >
                &#8592; POs
              </a>
            </li>
            {poActions.mark_po_as_sent && (
              <li className="inline-block fs-n0 margin-right-15">
                <ButtonAction
                  onClick={() => dispatch(showMarkAsSentModal([poID]))}
                >
                  Mark as Sent
                </ButtonAction>
              </li>
            )}
            {poActions.cancel_po && (
              <li className="inline-block fs-n0 margin-right-15">
                <ButtonAction onClick={() => showCancelPOModal([poID])}>
                  Cancel
                </ButtonAction>
              </li>
            )}
            {poActions.restore_po && (
              <li className="inline-block fs-n0 margin-right-15">
                <ButtonAction onClick={() => showCancelPOModal([poID], true)}>
                  Restore
                </ButtonAction>
              </li>
            )}
            {poActions.print_po && (
              <li className="inline-block fs-n0 margin-right-15">
                <ButtonAction onClick={() => window.open(printURL, '_blank')}>
                  Preview/Print PO
                </ButtonAction>
              </li>
            )}
            {poActions.send_po && (
              <li className="inline-block fs-n0 margin-right-5">
                <ButtonAction onClick={() => showSendToSupplierModal([poID])}>
                  Send PO
                </ButtonAction>
              </li>
            )}
            <AddPOTagsDropdown poIDs={[poID]} />
          </ul>
        </div>
        <hr className="hr--detail-page margin-top-3" />
      </div>
    </div>
  )
}
