import * as Sentry from '@sentry/browser'

import {IS_PROD_ENV, IS_DEV_ENV} from './constants/index.js'
import version from '../../../version.json'

const DEBUG = false

if (DEBUG || IS_PROD_ENV || IS_DEV_ENV) {
  Sentry.init({
    dsn:
      DEBUG || IS_DEV_ENV
        ? 'https://dbca975bdf5f4d50a03c01c61825f5dd@o1136.ingest.sentry.io/50171'
        : 'https://35166c4aeb234c868e9030ccf4faf476@o1136.ingest.sentry.io/2683',

    release: `pappy@v${version.version}`,

    tracesSampleRate: 1.0,

    beforeBreadcrumb(crumb) {
      if (
        crumb.category === 'xhr' &&
        crumb.data &&
        crumb.data.url.includes('fullstory.com')
      ) {
        return false
      }
      return crumb
    },

    ignoreErrors: [
      'APIversonError',
      'APIError',
      'XHRError',
      'Error contacting the server',
      'timeout of 0ms exceeded',
      'Network Error',
      'Timeout contacting the server',
      'Request aborted',
      /^PrintNode: Your VAT information is inconsistent or incomplete./,
      'PrintNode: API Key not found',
      'PrintNode: You must validate your email address',
      'PrintNode: API rate limit exceeded',
      'PrintNode: Account not found',
      'PrintNode: This endpoint cannot be used by suspended Accounts',
      /^PrintNode: You have one or more overdue invoices./,
      'ga is not defined',
      'Illegal invocation', // A particular customer is doing a dumb thing and we can't stop it.
      // Remove this line once Company 585105 goes away.
      // They seem to be loading some script manually or by an extension. I can't tell.
    ],
  })
}
