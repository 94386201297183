import PropTypes from 'prop-types'
import {useMemo} from 'react'
import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import classNames from 'classnames'
import {
  warehousesSortedByNameSelector,
  getWarehouseName,
  createWarehouseSelector,
  firstWarehouseSelector,
} from '../../../data/warehouses.js'

import {
  setIsWarehouseListExpanded,
  toggleExpanded,
  isWarehouseListExpandedSelector,
  productListQuerySelector,
} from '../productListFunctions.js'
import {
  hasUseMOsFeatureSelector,
  usesInventorySelector,
} from '../../../data/company.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'
import {dispatch, useSelector} from '../../../store.js'
import {
  createCanEditTotalOnHandSelector,
  getTotalOnHand,
  getTotalAvailable,
  getTotalCommitted,
  getTotalPOCommitted,
  getTotalMOOrdered,
} from '../../../data/products.js'
import Quantity from '../../../common/components/Quantity.js'
import {showEditProductWarehouseModal} from '../Modals/EditProductWarehouseModal.js'

function createWarehouseItemsSelector(
  isExpandedSelector,
  defaultSupplierWarehousesSelector,
) {
  return createSelector(
    isExpandedSelector,
    defaultSupplierWarehousesSelector,
    warehousesSortedByNameSelector,
    productListQuerySelector,
    isWarehouseListExpandedSelector,
    (
      isExpanded,
      defaultSupplierWarehouses,
      warehouses,
      query,
      isWarehouseListExpanded,
    ) =>
      defaultSupplierWarehouses
        .filter(
          ({id}) =>
            query.warehouse_id.length === 0 ||
            query.warehouse_id.includes(id) ||
            isWarehouseListExpanded,
        )
        .map((supplierWarehouse) => ({
          ...supplierWarehouse,
          name: getWarehouseName(
            warehouses.find((w) => w.id === supplierWarehouse.id),
          ),
        })),
  )
}

function WarehouseRow({
  productSelector,
  warehouseItem,
  hasUseMOsFeature,
  usesInventory,
}) {
  const warehouseSelector = useMemo(
    () => createWarehouseSelector(warehouseItem.id),
    [warehouseItem.id],
  )
  const canEditTotalOnHandSelector = useMemo(
    () => createCanEditTotalOnHandSelector(productSelector, warehouseSelector),
    [productSelector, warehouseSelector],
  )

  const canEditTotalOnHand = useSelector(canEditTotalOnHandSelector)
  const product = useSelector(productSelector)

  return (
    <tr className="table__tr table__tr--warehouse-inventory">
      <td className="table__td table__td--warehouse-inventory">
        <div>
          <ButtonLink
            className="txt-overflow-ellipsis unbold darkest"
            onClick={() =>
              dispatch(
                showEditProductWarehouseModal(product.sku, [warehouseItem.id]),
              )
            }
          >
            <strong>{warehouseItem.name}</strong>
          </ButtonLink>
        </div>
        {warehouseItem.location_in_warehouse && (
          <div>{warehouseItem.location_in_warehouse}</div>
        )}
      </td>
      {usesInventory && (
        <>
          <td className="table__td table__td--warehouse-inventory align-right">
            {canEditTotalOnHand ? (
              <ButtonLink
                className="border-underline darker"
                onClick={() =>
                  dispatch(
                    showEditProductWarehouseModal(
                      product.sku,
                      [warehouseItem.id],
                      'physical_on_hand',
                    ),
                  )
                }
              >
                <strong>
                  <Quantity value={warehouseItem.on_hand} digits={1} />
                </strong>
              </ButtonLink>
            ) : (
              <span className="text--lt-grey">
                <Quantity value={warehouseItem.on_hand} digits={1} />
              </span>
            )}
          </td>
          <td className="table__td table__td--warehouse-inventory align-right">
            <Quantity value={warehouseItem.available} digits={1} />
          </td>
          <td className="table__td table__td--warehouse-inventory align-right">
            <Quantity value={warehouseItem.committed} digits={1} />
          </td>
          {hasUseMOsFeature && (
            <td className="table__td table__td--warehouse-inventory align-right">
              <Quantity value={warehouseItem.mfg_ordered} digits={1} />
            </td>
          )}
          <td className="table__td table__td--warehouse-inventory align-right">
            <Quantity value={warehouseItem.po_committed} digits={1} />
          </td>
        </>
      )}
    </tr>
  )
}

WarehouseRow.propTypes = {
  productSelector: PropTypes.func.isRequired,
  warehouseItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    on_hand: PropTypes.number.isRequired,
    available: PropTypes.number.isRequired,
    committed: PropTypes.number.isRequired,
    po_committed: PropTypes.number.isRequired,
    mfg_ordered: PropTypes.number.isRequired,
    location_in_warehouse: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  hasUseMOsFeature: PropTypes.bool.isRequired,
  usesInventory: PropTypes.bool.isRequired,
}

export default function WarehouseTable({
  productSelector,
  isExpandedSelector,
  defaultSupplierWarehousesSelector,
}) {
  const warehouseItemsSelector = useMemo(
    () =>
      createWarehouseItemsSelector(
        isExpandedSelector,
        defaultSupplierWarehousesSelector,
      ),
    [isExpandedSelector, defaultSupplierWarehousesSelector],
  )
  const canEditTotalOnHandSelector = useMemo(
    () =>
      createCanEditTotalOnHandSelector(productSelector, firstWarehouseSelector),
    [productSelector, firstWarehouseSelector],
  )

  const warehouses = useSelector(warehousesSortedByNameSelector)
  const firstWarehouse = useSelector(firstWarehouseSelector)
  const firstWarehouseID = get(firstWarehouse, 'id')
  const product = useSelector(productSelector)
  const shouldAllowTopLevelEdit =
    useSelector(canEditTotalOnHandSelector) && warehouses.length === 1
  const isExpanded = useSelector(isExpandedSelector)
  const warehouseItems = useSelector(warehouseItemsSelector)
  const isWarehouseListExpanded = useSelector(isWarehouseListExpandedSelector)
  const hasWarehouseFilters =
    useSelector(productListQuerySelector).warehouse_id.length !== 0
  const hasUseMOsFeature = useSelector(hasUseMOsFeatureSelector)
  const usesInventory = useSelector(usesInventorySelector)

  const showTableBody =
    isExpanded && (warehouseItems.length > 1 || hasWarehouseFilters)
  const totalOnHand = getTotalOnHand(product)
  const totalAvailable = getTotalAvailable(product)
  const totalCommitted = getTotalCommitted(product)
  const totalPOCommitted = getTotalPOCommitted(product)
  const totalMOOrdered = getTotalMOOrdered(product)

  return (
    <>
      <td className="table__td table__td--product-list-warehouses padding-left-0 padding-right-0">
        <table className="table table--warehouse-inventory">
          <thead
            className={classNames('thead', {
              'thead--multi-warehouse': warehouseItems.length > 1,
              'thead--non-inventory': !usesInventory,
            })}
          >
            <tr className="table__tr">
              {!usesInventory && !isExpanded && warehouseItems.length !== 1 && (
                <th className="table__th table__th--sm table__th--warehouse-inventory table__th--warehouse-inventory-multi no-border padding-top-0 padding-left-0">
                  <div>
                    <ButtonLink
                      className="align-left unbold"
                      onClick={() => toggleExpanded(product.sku)}
                    >
                      Show all
                    </ButtonLink>
                  </div>
                </th>
              )}
              {warehouseItems.length === 1 && !hasWarehouseFilters && (
                <th className="table__th table__th--sm table__th--warehouse-inventory table__th--warehouse-inventory-single no-border w-20 padding-left-0">
                  <div>
                    <strong>Location:</strong>
                  </div>
                  <div className="text--md-dk-grey lh-sm unbold">
                    <ButtonLink
                      className="txt-overflow-ellipsis unbold darkest"
                      onClick={() =>
                        dispatch(
                          showEditProductWarehouseModal(product.sku, [
                            warehouseItems[0].id,
                          ]),
                        )
                      }
                    >
                      {warehouseItems[0].location_in_warehouse ? (
                        <strong>
                          {warehouseItems[0].location_in_warehouse}
                        </strong>
                      ) : (
                        <em>Not set</em>
                      )}
                    </ButtonLink>
                  </div>
                </th>
              )}
              {showTableBody && (
                <th className="table__th table__th--sm table__th--warehouse-inventory table__th--warehouse-inventory-multi no-border w-30">
                  {hasWarehouseFilters && (
                    <div>
                      <ButtonLink
                        className="lighter align-left no-underline"
                        onClick={() =>
                          setIsWarehouseListExpanded(!isWarehouseListExpanded)
                        }
                      >
                        <span
                          className="i--expand fs-00 lh-lg i--v-align unbold"
                          aria-hidden="true"
                        ></span>{' '}
                        <span className="unbold lh-lg">
                          {isWarehouseListExpanded
                            ? 'Show filtered'
                            : 'Show all'}
                        </span>
                      </ButtonLink>
                    </div>
                  )}
                </th>
              )}
              {usesInventory && (
                <>
                  <th className="table__th table__th--sm table__th--warehouse-inventory table__th--warehouse-inventory-value poh align-right">
                    <div
                      className="unbold lh-1-1 margin-bottom-3 tooltip--left"
                      data-hint="Physical On Hand"
                    >
                      <a
                        className="btn--link btn--product-qty-th"
                        href="https://support.ordoro.com/inventory-management-terminology-explained/#poh"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        POH
                      </a>
                    </div>
                    <div>
                      {shouldAllowTopLevelEdit ? (
                        <ButtonLink
                          className="border-underline darkest"
                          onClick={() =>
                            dispatch(
                              showEditProductWarehouseModal(
                                product.sku,
                                [firstWarehouseID],
                                'physical_on_hand',
                              ),
                            )
                          }
                        >
                          <strong>
                            <Quantity value={totalOnHand} digits={1} />
                          </strong>
                        </ButtonLink>
                      ) : (
                        <strong className="text--md-dk-grey">
                          <Quantity value={totalOnHand} digits={1} />
                        </strong>
                      )}
                    </div>
                  </th>
                  <th className="table__th table__th--sm table__th--warehouse-inventory table__th--warehouse-inventory-value align-right">
                    <div
                      className="unbold margin-bottom-3 lh-1-1 tooltip--left"
                      data-hint="Available On Hand"
                    >
                      <a
                        className="btn--link btn--product-qty-th"
                        href="https://support.ordoro.com/inventory-management-terminology-explained/#aoh"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        AOH
                      </a>
                    </div>
                    <div className="unbold text--md-dk-grey">
                      <Quantity value={totalAvailable} digits={1} />
                    </div>
                  </th>
                  <th className="table__th table__th--sm table__th--warehouse-inventory table__th--warehouse-inventory-value align-right">
                    <div
                      className="unbold margin-bottom-3 lh-1-1 tooltip--left"
                      data-hint="Committed"
                    >
                      <a
                        className="btn--link btn--product-qty-th"
                        href="https://support.ordoro.com/inventory-management-terminology-explained/#committed"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CMT
                      </a>
                    </div>
                    <div className="unbold text--md-dk-grey">
                      <Quantity value={totalCommitted} digits={1} />
                    </div>
                  </th>
                  {hasUseMOsFeature && (
                    <th className="table__th table__th--sm table__th--warehouse-inventory table__th--warehouse-inventory-value align-right">
                      <div
                        className="unbold margin-bottom-3 lh-1-1 tooltip--left"
                        data-hint="On Manufacturing Order"
                      >
                        <a
                          className="btn--link btn--product-qty-th"
                          href="https://support.ordoro.com/inventory-management-terminology-explained/#on-mo"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {showTableBody ? 'OMO' : 'On MO'}
                        </a>
                      </div>
                      <div className="unbold text--md-dk-grey">
                        <Quantity value={totalMOOrdered} digits={1} />
                      </div>
                    </th>
                  )}
                  <th className="table__th table__th--sm table__th--warehouse-inventory table__th--reordered table__th--warehouse-inventory-value align-right">
                    <div
                      className="unbold margin-bottom-3 lh-1-1 tooltip--left"
                      data-hint="On Purchase Order"
                    >
                      <a
                        className="btn--link btn--product-qty-th"
                        href="https://support.ordoro.com/inventory-management-terminology-explained/#on-po"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {showTableBody ? 'OPO' : 'On PO'}
                      </a>
                    </div>
                    <div className="unbold text--md-dk-grey">
                      <Quantity value={totalPOCommitted} digits={1} />
                    </div>
                  </th>
                </>
              )}
            </tr>
          </thead>
          {showTableBody && (
            <tbody className="table__tbody--warehouse-inventory">
              {warehouseItems.map((warehouseItem) => (
                <WarehouseRow
                  key={warehouseItem.id}
                  productSelector={productSelector}
                  warehouseItem={warehouseItem}
                  hasUseMOsFeature={hasUseMOsFeature}
                  usesInventory={usesInventory}
                />
              ))}
            </tbody>
          )}
        </table>
      </td>
    </>
  )
}

WarehouseTable.propTypes = {
  productSelector: PropTypes.func.isRequired,
  defaultSupplierWarehousesSelector: PropTypes.func.isRequired,
  isExpandedSelector: PropTypes.func.isRequired,
}
