import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {getState} from '../../store.js'
import {showGlobalError} from '../../ordoro/GlobalErrorMessage.js'
import {
  getCompany,
  companyIDSelector,
  doNotWebsocketLabelInfoSelector,
} from '../../data/company.js'
import {getWarehouses} from '../../data/warehouses.js'
import {getSuppliers} from '../../data/suppliers.js'
import {receivedLabelInfoUpdateThrottled} from '../../data/labelInfos/index.js'
import {receivedBatchUpdateThrottled} from '../../data/batches.js'
import {receivedOrderUpdateThrottled} from '../../data/orders.js'
import loadScript from '../loadScript.js'
import {informOrderRevisionModalOfUpdate} from '../../ordoro/OrderListPage/Modals/OrderRevisionModal.js'
import {checkForNewOrdersThrottled} from '../../data/orderCounts.js'
import {getPaymentsAccountsDebounced} from '../../data/paymentAccounts.js'
import {getAccount} from '../../data/account.js'

async function connectToWebSocket(companyID) {
  if (!companyID) {
    return null
  }

  const url = window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_HERMES_URL

  if (!window.io) {
    await loadScript(`${url}/socket.io/socket.io.js`)
  }

  const socket = window.io
    ? window.io(url, {
        query: {company_id: companyID},
        withCredentials: true,
      })
    : null

  if (socket) {
    socket.on('db update', (msg) => onMessage(msg))
  }

  return socket
}

async function disconnectFromWebSocket(promise) {
  if (promise) {
    const socket = await promise

    if (socket) {
      socket.close()
    }
  }
}

async function onMessage(message) {
  try {
    const payload = JSON.parse(message)

    if (payload.table === 'company_company') {
      await getCompany()
    }
    if (payload.table === 'misc_location') {
      await getWarehouses()
    }
    if (payload.table === 'inventory_supplier') {
      await getSuppliers()
    }
    if (
      payload.table === 'label_info' &&
      !doNotWebsocketLabelInfoSelector(getState())
    ) {
      await receivedLabelInfoUpdateThrottled(payload)
    }
    if (['orders_order_labels', 'order_revision'].includes(payload.table)) {
      await receivedOrderUpdateThrottled(payload)
    }
    if (payload.table === 'batch' && payload.id) {
      await receivedBatchUpdateThrottled(payload)
    }
    if (payload.table === 'order_revision') {
      await informOrderRevisionModalOfUpdate(payload)
    }
    if (payload.table === 'orders_order') {
      await checkForNewOrdersThrottled(payload)
    }
    if (['payment_account', 'payment_record'].includes(payload.table)) {
      await getPaymentsAccountsDebounced()
    }
    if (payload.table === 'ebenezer') {
      await getAccount()
    }
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error trying to process websocket message.',
      },
      err,
    )
  }
}

export default function WebSockets() {
  const companyID = useSelector(companyIDSelector)

  useEffect(() => {
    const promise = connectToWebSocket(companyID)

    return () => disconnectFromWebSocket(promise)
  }, [companyID])

  return null
}
