import {forwardRef, useRef} from 'react'

import {HotKeyConnect} from '../HotKeys.js'
import cn from '../../className.js'

/**
 * Primary button
 *
 * The main button in the UI
 *
 * @param {Object} props
 * @param {String} props.type
 * @param {String} props.className
 * @param {String} props.size
 * @param {String} props.hotKey
 * @param {Function} props.onClick
 * @param {Boolean} props.isLoading
 * @param {Boolean} props.isDeterminate
 * @param {Boolean} props.isDisabled
 * @param {Boolean} props.isWarning
 * @param {Boolean} props.isError
 * @param {Boolean} props.isOutlined
 * @param {Boolean} props.isWarning
 * @param {Boolean} props.alt
 * @param {ReactNode} props.children
 * @returns {ReactNode}
 */
const ButtonPrimary = forwardRef(
  (
    {
      type,
      children,
      isLoading,
      isDeterminate,
      isDisabled,
      isWarning,
      isError,
      isOutlined,
      className,
      size,
      hotKey,
      alt,
      ...props
    },
    buttonRef,
  ) => {
    const backupInputRef = useRef()
    buttonRef = buttonRef || backupInputRef

    size = size || 'md'
    type = type || 'button'

    const button = (
      <button
        ref={buttonRef}
        className={cn`btn btn--primary ${className} ${`btn--${size}`} ${{
          'btn--primary-alt': alt,
          'btn--disabled': isDisabled,
          'btn--processing': isLoading && !isDeterminate,
          'btn--progress': isLoading && isDeterminate,
          'btn--primary-ol': isOutlined && !isWarning,
          'btn--primary-warning-ol': isOutlined && isWarning,
          'btn--disabled-error': isDisabled && isError,
          'btn--disabled-warning': isDisabled && isWarning && !isError,
        }}`}
        disabled={isDisabled || isLoading}
        type={type}
        {...props}
      >
        {children}
      </button>
    )

    if (hotKey) {
      return (
        <HotKeyConnect
          code={hotKey}
          func={props.onClick}
          isDisabled={isDisabled || isLoading}
        >
          {button}
        </HotKeyConnect>
      )
    }

    return button
  },
)

ButtonPrimary.displayName = 'ButtonPrimary'

export default ButtonPrimary
