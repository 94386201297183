import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'

import {setForm, formsSelector, getState} from '../store.js'
import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'

export const PAYMENT_RECORDS = 'PAYMENT_RECORDS'
export const PLAID_TRANSFER_STATUS_PENDING = 'pending'
export const PLAID_TRANSFER_STATUS_POSTED = 'posted'
export const PLAID_USER_ACTION_REQUIRED = 'user_action_required'

export function setPaymentRecords(paymentRecords) {
  setForm(PAYMENT_RECORDS, keyBy(paymentRecords, 'id'))
}

export function paymentRecordsSelector(state) {
  return formsSelector(state)[PAYMENT_RECORDS] || paymentRecordsSelector.default
}
paymentRecordsSelector.default = {}

export function paymentRecordSelector(state, {paymentRecordID}) {
  return paymentRecordsSelector(state)[paymentRecordID]
}

export function paymentRecordsHaveLoadedSelector(state) {
  return !!formsSelector(state)[PAYMENT_RECORDS]
}

export const paymentRecordsByAccountIDSelector = createSelector(
  paymentRecordsSelector,
  (paymentRecords) => {
    const paymentRecordsByAccountID = Object.values(paymentRecords).reduce(
      (prev, paymentRecord) => {
        const paymentAccountID = paymentRecord.payment_account_id
        prev[paymentAccountID] = prev[paymentAccountID] || []

        prev[paymentAccountID].push(paymentRecord)

        return prev
      },
      {},
    )

    // presort lists by created_date so that we can look at the first element to see the "latest"
    return Object.entries(paymentRecordsByAccountID).reduce(
      (prev, [paymentAccountID, paymentRecords]) => {
        prev[paymentAccountID] = sortBy(
          paymentRecords,
          'created_date',
        ).reverse()

        return prev
      },
      {},
    )
  },
)

export function paymentRecordsForPaymentAccountSelector(
  state,
  {paymentAccountID},
) {
  return paymentRecordsByAccountIDSelector(state)[paymentAccountID] || []
}

export function mostRecentPaymentRecordSelector(state, {paymentAccountID}) {
  const paymentRecords = paymentRecordsForPaymentAccountSelector(state, {
    paymentAccountID,
  })

  // first active payment record
  return paymentRecords.find(({active}) => active)
}

export function paymentTransferStatusSelector(state, {paymentAccountID}) {
  const paymentRecord = mostRecentPaymentRecordSelector(state, {
    paymentAccountID,
  })

  return paymentRecord?.payment_response?.transfer?.status || null
}

export function paymentAuthorizationIDSelector(state, {paymentAccountID}) {
  const paymentRecord = mostRecentPaymentRecordSelector(state, {
    paymentAccountID,
  })

  return paymentRecord?.authorization_response?.authorization?.id || null
}

export function paymentAuthorizationDecisionSelector(
  state,
  {paymentAccountID},
) {
  const paymentRecord = mostRecentPaymentRecordSelector(state, {
    paymentAccountID,
  })

  return paymentRecord?.authorization_response?.authorization.decision || null
}

export async function getPaymentRecords() {
  try {
    const paymentRecords = await fetchAllAPI(
      `/company/payment/payment_record/`,
      'payment_record',
    )

    setPaymentRecords(paymentRecords)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting payment records.',
        details: err.message || err.error_message,
      },
      `Error getting payment records. ${err.error_message || err.message}`,
      err,
    )

    setPaymentRecords([])
  }

  return paymentRecordsSelector(getState())
}
