import PropTypes from 'prop-types'
import {useMemo} from 'react'
import classNames from 'classnames'

import {
  UNSENT_STATUS,
  SENT_STATUS,
  PARTIAL_STATUS,
  PURCHASE_ORDER_STATII,
} from '../../common/constants/PurchaseOrders.js'
import {createNewGoodsReceipt} from './Modals/GoodsReceiptModal/goodsReceiptModalFunctions.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import TimelinePopover from '../../common/components/List/TimelinePopover/index.js'
import RowAddress from '../../common/components/List/RowAddress.js'
import Quantity, {quantity} from '../../common/components/Quantity.js'
import {selectPOID, toggleExpanded} from './purchaseOrderListActions.js'
import {getWarehouseName} from '../../data/warehouses.js'
import {getSupplierName} from '../../data/suppliers.js'
import {
  createPOCommentGroupsSelector,
  isPOLockedSelector,
  poStatusSelector,
  poWarehouseSelector,
  poSupplierSelector,
  poTotalItemQuantitySelector,
  poSelector,
} from '../../data/pos.js'
import {
  createIsSelectedSelector,
  createIsExpandedSelector,
  createDetailURLSelector,
} from './purchaseOrderListSelectors.js'
import {showAddPOCommentModal} from './Modals/AddPOCommentModal.js'
import {showPOIDModal} from './Modals/POIDModal.js'
import {showPOTextFieldModal} from './Modals/POTextFieldModal.js'
import {dispatch, useSelector} from '../../store.js'
import {POTags} from './POTags.js'

export default function Row({poID}) {
  const [
    commentGroupsSelector,
    isSelectedSelector,
    isExpandedSelector,
    detailURLSelector,
  ] = useMemo(
    () => [
      createPOCommentGroupsSelector(poID),
      createIsSelectedSelector(poID),
      createIsExpandedSelector(poID),
      createDetailURLSelector(poID),
    ],
    [poID],
  )

  const po = useSelector((state) => poSelector(state, {poID}))
  const poStatus = useSelector((state) => poStatusSelector(state, {poID}))
  const isPOLocked = useSelector((state) => isPOLockedSelector(state, {poID}))
  const isSelected = useSelector(isSelectedSelector)
  const isExpanded = useSelector(isExpandedSelector)
  const detailURL = useSelector(detailURLSelector)
  const warehouse = useSelector((state) => poWarehouseSelector(state, {poID}))
  const supplier = useSelector((state) => poSupplierSelector(state, {poID}))
  const totalItemCount = useSelector((state) =>
    poTotalItemQuantitySelector(state, {poID}),
  )
  const status = PURCHASE_ORDER_STATII.find(({slug}) => slug === poStatus) || {}

  if (!po) {
    return null
  }

  return (
    <tr
      className={classNames('table__tr', {'table__tr--expanded': isExpanded})}
    >
      <td className="table__td table__td--order-checkbox relative">
        <TimelinePopover
          title="PO Timeline"
          type="po"
          commentGroupsSelector={commentGroupsSelector}
        />
        <input
          type="checkbox"
          className="checkbox"
          checked={isSelected}
          onClick={(event) => selectPOID(poID, !isSelected, event.shiftKey)}
          onChange={() => {
            /* React wants us to have this, but we use onClick */
          }}
        />
      </td>
      <td className="table__td">
        <div
          className="status-badge inline-block lh-md tooltip--top margin-right-5 v-align-top"
          data-hint={status.name}
        >
          <strong>{status.badge}</strong>
        </div>
        <div className="wrap--product-name inline-block">
          <a
            className="btn btn--link darkest fs-n1 lh-md margin-right-5"
            href={detailURL}
          >
            <strong>{poID}</strong>
          </a>
          {poStatus === UNSENT_STATUS && (
            <button
              className="btn btn--link btn--edit-product-name no-underline"
              type="button"
              onClick={() => dispatch(showPOIDModal(poID))}
              disabled={isPOLocked}
            >
              <span
                className="i-pencil fs-00 lh-sm v-align-middle"
                aria-hidden="true"
              />
            </button>
          )}
          {(poStatus === SENT_STATUS || poStatus === PARTIAL_STATUS) && (
            <div className="margin-top-3">
              <button
                className="btn btn--link"
                type="button"
                onClick={() => createNewGoodsReceipt(poID)}
                disabled={isPOLocked}
              >
                Create goods receipt
              </button>
            </div>
          )}
        </div>
        <div className="wrap--po-addl-info divider--top">
          <button
            className="btn btn--link"
            type="button"
            onClick={() => dispatch(showAddPOCommentModal(poID))}
            disabled={isPOLocked}
          >
            Add a comment
          </button>
        </div>
        {po.tags.length > 0 && (
          <div
            className={
              isExpanded
                ? 'wrap--po-addl-info list list--order-data'
                : 'wrap--summary-tags-table wrap--summary-tags-inline'
            }
          >
            <POTags poID={poID} tags={po.tags} />
          </div>
        )}
      </td>
      <td className="table__td">
        <div>
          <strong className="fs-n1">{`${po.items.length} line${
            po.items.length === 1 ? '' : 's'
          } `}</strong>
          {` (${quantity({value: totalItemCount})} unit${totalItemCount === 1 ? '' : 's'})`}
        </div>
        <div className="wrap--po-addl-info margin-top-10">
          {po.items.map((item) => (
            <dl
              key={item.id}
              className="list--line-details list--po-line-details divider--top flex--justify-nowrap"
            >
              <dt className="list__title--line-detail">
                <a
                  className="btn btn--link darker inline-block"
                  href={`#${item.product._link}`}
                >
                  <span className="unbold block">{item.product.name}</span>
                  <span className="unbold block">{item.product.sku}</span>
                </a>
              </dt>
              <dd className="list__item--line-detail list__item--line-qty flex-shrink-0">
                <span>
                  <Quantity value={item.quantity} />
                </span>
              </dd>
            </dl>
          ))}
        </div>
      </td>
      <td className="table__td">
        <div>
          <strong className="fs-n1">{getWarehouseName(warehouse)}</strong>
        </div>
        <div className="wrap--po-addl-info divider--top">
          <RowAddress address={warehouse.address} noPhone noEmail />
        </div>
      </td>
      <td className="table__td">
        <div>
          <strong className="fs-n1">{getSupplierName(supplier)}</strong>
        </div>
        <div className="wrap--po-addl-info">
          <dl className="list list--order-data list--order-items divider--top">
            <dt className="list__title--order-data">Instructions</dt>
            {po.instructions ? (
              <dd className="list__item--order-data">
                <div className="ws-pre-wrap">{po.instructions}</div>
                <button
                  className="btn btn--link meta-orderlistrow-button-edit-po-instructions"
                  type="button"
                  onClick={() =>
                    dispatch(
                      showPOTextFieldModal({
                        poID,
                        value: po.instructions,
                        display: 'Instructions',
                        apiProp: 'instructions',
                      }),
                    )
                  }
                  disabled={isPOLocked}
                >
                  Edit
                </button>
              </dd>
            ) : (
              <dd className="list__item--order-data">
                <button
                  className="btn btn--link meta-orderlistrow-button-edit-po-instructions"
                  type="button"
                  onClick={() =>
                    dispatch(
                      showPOTextFieldModal({
                        poID,
                        value: '',
                        display: 'Instructions',
                        apiProp: 'instructions',
                      }),
                    )
                  }
                  disabled={isPOLocked}
                >
                  Add some
                </button>
              </dd>
            )}
          </dl>
        </div>
      </td>
      <td className="table__td">
        <span className="fs-n1">
          <FormattedDate value={po.updated} format="MMM D, YYYY" />
          {' at '}
          <FormattedDate value={po.updated} format="h:mma" />
        </span>
      </td>
      <td className="table__td">
        <button
          className={classNames(
            'btn btn--link fs-n1 meta-orderlistrow-button-edit-po-instructions',
            {darkest: po.estimated_delivery_date},
          )}
          type="button"
          onClick={() =>
            dispatch(
              showPOTextFieldModal({
                poID,
                value: po.estimated_delivery_date,
                display: 'Estimated Delivery Date',
                apiProp: 'estimated_delivery_date',
                inputType: 'date',
              }),
            )
          }
          disabled={isPOLocked}
        >
          {po.estimated_delivery_date ? (
            <FormattedDate
              value={po.estimated_delivery_date}
              format="MMM D, YYYY"
            />
          ) : (
            'Add date'
          )}
        </button>
      </td>
      <td className="table__td align-right">
        <button
          className="btn btn--link no-underline btn--expand-product-row"
          type="button"
          onClick={() => toggleExpanded(poID)}
        >
          <span
            className="i--expand fs-01 lh-0 v-align-middle"
            aria-hidden="true"
          />
        </button>
      </td>
    </tr>
  )
}

Row.propTypes = {
  poID: PropTypes.string.isRequired,
}
