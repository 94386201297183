import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {
  ALL_STATUS,
  RETURN_ORDER_STATII,
} from '../../common/constants/ReturnOrders.js'
import {AddressShape, TagsShape} from '../../common/PropTypes.js'
import FormattedDate from '../../common/components/FormattedDate.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import LinkButton from '../../common/components/Link/LinkButton.js'
import TimelinePopover from '../../common/components/List/TimelinePopover/index.js'
import RowAddress from '../../common/components/List/RowAddress.js'
import Quantity from '../../common/components/Quantity.js'
import {
  showRMAReturnToAddressModal,
  showRMAReturnCustomerAddressModal,
} from '../../redux/actions/data/returnOrders.js'
import {
  createReturnOrderSelector,
  createReturnOrderOriginalOrderNumberSelector,
  createReturnOrderStatusSelector,
  createReturnOrderCommentGroupsSelector,
  createReturnOrderLinesSelector,
  createReturnOrderCustomerAddressSelector,
  createReturnOrderTotalSKUCountSelector,
  createReturnOrderTotalLineQuantitySelector,
  returnToAddressSelector,
  createReturnOrderCreatedDateSelector,
  createReturnOrderUpdatedDateSelector,
  createReturnOrderInternalNotesSelector,
  createReturnOrderTagsSelector,
  rmaShippedShipperTypeSelector,
} from '../../redux/selectors/data/returnOrders.js'
import {showUpdateReturnOrderInternalNotesModal} from './Modals/UpdateReturnOrderInternalNotesModal.js'
import {
  returnOrderListSelectReferenceID,
  returnOrderListToggleExpanded,
} from './returnOrderListActions.js'
import {
  statusSelector,
  createIsSelectedSelector,
  createIsExpandedSelector,
  createDetailURLSelector,
} from './returnOrderListSelectors.js'
import ReturnOrderTags from './ReturnOrderTags.js'
import {PRIVACY_MASK} from '../../common/analytics/index.js'

export function Row({
  referenceID,
  originalOrderNumber,
  commentGroupsSelector,
  status,
  returnOrderStatus,
  isSelected,
  isExpanded,
  detailURL,
  customerAddress,
  totalSKUCount,
  totalLineQuantity,
  returnToAddress,
  createdDate,
  updatedDate,
  internalNotes,
  tags,
  shipperShipperType,
  ...props
}) {
  return (
    <tr
      className={classNames('table__tr table__tr--return-order-row', {
        'table__tr--expanded': isExpanded,
      })}
    >
      <td className="table__td table__td--order-checkbox relative">
        <TimelinePopover
          title="RMA Timeline"
          type="return-order"
          commentGroupsSelector={commentGroupsSelector}
        />
        <input
          type="checkbox"
          className="checkbox"
          checked={isSelected}
          onClick={(event) =>
            props.returnOrderListSelectReferenceID(
              referenceID,
              !isSelected,
              event.shiftKey,
            )
          }
          onChange={() => {
            /* React wants us to have this, but we use onClick */
          }}
        />
      </td>
      <td className="table__td">
        {status === ALL_STATUS &&
          (() => {
            const {badge, name} =
              RETURN_ORDER_STATII.find(
                ({slug}) => slug === returnOrderStatus,
              ) || {}

            return (
              <div
                className="status-badge inline-block lh-md tooltip--top margin-right-5 v-align-top"
                data-hint={name}
              >
                <strong>{badge}</strong>
              </div>
            )
          })()}
        <div className="wrap--product-name inline-block">
          <a
            className="btn btn--link darkest fs-n1 lh-md margin-right-5"
            href={detailURL}
          >
            <strong>{referenceID}</strong>
          </a>
        </div>
        {shipperShipperType && (
          <div className="margin-top-1 margin-bottom-1">
            <a className="btn btn--link darker" href={detailURL}>
              <span
                className={`inline-block v-align-top selected-shipper selected-shipper--${shipperShipperType}`}
              ></span>
              <em className="fs-n2">Return label created</em>
            </a>
          </div>
        )}
        {tags.length > 0 && (
          <div
            className={
              isExpanded
                ? 'wrap--po-addl-info list list--order-data'
                : 'wrap--summary-tags-table wrap--summary-tags-inline'
            }
          >
            <ReturnOrderTags referenceID={referenceID} tags={tags} />
          </div>
        )}

        {isExpanded && originalOrderNumber && (
          <dl className="wrap--po-addl-info list list--order-data with-top-divider">
            <dt className="list__title--order-data">Originating Order ID</dt>
            <dd className="list__item--order-data">
              <LinkButton
                className="v-align-base"
                href={`#/order/${encodeURIComponent(originalOrderNumber)}`}
              >
                {originalOrderNumber}
              </LinkButton>
            </dd>
          </dl>
        )}

        <dl className="wrap--po-addl-info list list--order-data with-top-divider">
          <dt className="list__title--order-data">Internal Notes</dt>
          {internalNotes ? (
            <dd className="list__item--order-data">
              <div className="ws-pre-wrap">{internalNotes}</div>
              <ButtonLink
                className="meta-returnorderlist-button-edit-internal-note"
                onClick={() =>
                  props.showUpdateReturnOrderInternalNotesModal(referenceID)
                }
              >
                Edit
              </ButtonLink>
            </dd>
          ) : (
            <dd className="list__item--order-data">
              <ButtonLink
                className="meta-returnorderlist-button-add-internal-note"
                onClick={() =>
                  props.showUpdateReturnOrderInternalNotesModal(referenceID)
                }
              >
                Add note
              </ButtonLink>
            </dd>
          )}
        </dl>
      </td>
      <td className="table__td">
        <div className="fs-n1 margin-bottom-1">
          <FormattedDate value={createdDate} format="MMM D, YYYY" />
          {' at '}
          <FormattedDate value={createdDate} format="h:mma" />
        </div>
        <em className="fs-n2 text--lt-grey">
          Updated: <FormattedDate value={updatedDate} format="MMM D, YYYY" />
          {' at '}
          <FormattedDate value={updatedDate} format="h:mma" />
        </em>
      </td>
      <td className="table__td">
        <div>
          <strong className={`fs-n1 ${PRIVACY_MASK}`}>
            {customerAddress.name || customerAddress.company}
          </strong>
        </div>
        <div className="wrap--po-addl-info divider--top">
          <RowAddress address={customerAddress} noPhone noEmail />
        </div>
        <ButtonLink
          className="meta-returnorderlist-button-edit-warehouse"
          onClick={() => showRMAReturnCustomerAddressModal(referenceID)}
        >
          Edit
        </ButtonLink>
      </td>
      <td className="table__td">
        {returnToAddress ? (
          <>
            <div>
              <strong className="fs-n1">
                {returnToAddress.company ||
                  returnToAddress.name ||
                  'Not Provided'}
              </strong>
            </div>
            <div className="wrap--po-addl-info divider--top">
              <RowAddress address={returnToAddress} noPhone noEmail />
            </div>
          </>
        ) : (
          <div>
            <strong className="fs-n1">Not Provided</strong>
          </div>
        )}
        <ButtonLink
          className="meta-returnorderlist-button-edit-warehouse"
          onClick={() => showRMAReturnToAddressModal(referenceID)}
        >
          Edit
        </ButtonLink>
      </td>
      <td className="table__td">
        <div className="fs-n1 margin-bottom-1">
          <Quantity value={totalSKUCount} /> SKU{totalSKUCount !== 1 ? 's' : ''}
        </div>
        <em className="fs-n2 text--lt-grey">
          <Quantity value={totalLineQuantity} />{' '}
          {totalLineQuantity > 1 ? 'total' : ''} item
          {totalLineQuantity > 1 ? 's' : ''}
        </em>
      </td>
      <td className="table__td align-right">
        <ButtonLink
          className="no-underline btn--expand-product-row"
          onClick={() => props.returnOrderListToggleExpanded(referenceID)}
        >
          <span
            className="i--expand fs-01 lh-0 v-align-middle"
            aria-hidden="true"
          />
        </ButtonLink>
      </td>
    </tr>
  )
}

Row.propTypes = {
  referenceID: PropTypes.string.isRequired,
  originalOrderNumber: PropTypes.string,
  commentGroupsSelector: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  returnOrderStatus: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  detailURL: PropTypes.string.isRequired,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerAddress: AddressShape.isRequired,
  totalSKUCount: PropTypes.number.isRequired,
  totalLineQuantity: PropTypes.number.isRequired,
  returnToAddress: AddressShape.isRequired,
  createdDate: PropTypes.string.isRequired,
  updatedDate: PropTypes.string.isRequired,
  internalNotes: PropTypes.string,
  tags: TagsShape.isRequired,
  shipperShipperType: PropTypes.string,
  returnOrderListSelectReferenceID: PropTypes.func.isRequired,
  returnOrderListToggleExpanded: PropTypes.func.isRequired,
  showUpdateReturnOrderInternalNotesModal: PropTypes.func.isRequired,
}

function mapStateToProps(_state, {referenceID}) {
  const returnOrderSelector = createReturnOrderSelector(referenceID)
  const returnOrderOriginalOrderNumberSelector =
    createReturnOrderOriginalOrderNumberSelector(returnOrderSelector)
  const returnOrderStatusSelector =
    createReturnOrderStatusSelector(returnOrderSelector)
  const commentGroupsSelector =
    createReturnOrderCommentGroupsSelector(returnOrderSelector)
  const isSelectedSelector = createIsSelectedSelector(referenceID)
  const isExpandedSelector = createIsExpandedSelector(referenceID)
  const detailURLSelector = createDetailURLSelector(referenceID)
  const linesSelector = createReturnOrderLinesSelector(returnOrderSelector)
  const customerAddressSelector =
    createReturnOrderCustomerAddressSelector(returnOrderSelector)
  const totalSKUCountSelector =
    createReturnOrderTotalSKUCountSelector(returnOrderSelector)
  const totalLineQuantitySelector =
    createReturnOrderTotalLineQuantitySelector(returnOrderSelector)
  const createdDateSelector =
    createReturnOrderCreatedDateSelector(returnOrderSelector)
  const updatedDateSelector =
    createReturnOrderUpdatedDateSelector(returnOrderSelector)
  const internalNotesSelector =
    createReturnOrderInternalNotesSelector(returnOrderSelector)
  const tagsSelector = createReturnOrderTagsSelector(returnOrderSelector)

  return (state) => ({
    commentGroupsSelector,
    originalOrderNumber: returnOrderOriginalOrderNumberSelector(state),
    status: statusSelector(state),
    returnOrderStatus: returnOrderStatusSelector(state),
    isSelected: isSelectedSelector(state),
    isExpanded: isExpandedSelector(state),
    detailURL: detailURLSelector(state),
    lines: linesSelector(state),
    customerAddress: customerAddressSelector(state),
    totalSKUCount: totalSKUCountSelector(state),
    totalLineQuantity: totalLineQuantitySelector(state),
    returnToAddress: returnToAddressSelector(state, {referenceID}),
    createdDate: createdDateSelector(state),
    updatedDate: updatedDateSelector(state),
    internalNotes: internalNotesSelector(state),
    tags: tagsSelector(state),
    shipperShipperType: rmaShippedShipperTypeSelector(state, {referenceID}),
  })
}

const mapDispatchToProps = {
  returnOrderListSelectReferenceID,
  returnOrderListToggleExpanded,
  showUpdateReturnOrderInternalNotesModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Row)
