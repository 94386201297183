import ModalPortal from '../../common/components/ModalPortal.js'
import ReauthorizeCartModal from '../settings/Modals/ReauthorizeCartModal.js'
import EditCartIntegrationBridgeModal from '../settings/Modals/EditCartIntegrationBridgeModal.js'
import EditCartLocationWarehousesModal from '../settings/Modals/EditCartLocationWarehousesModal.js'
import EditCartModal from '../settings/Modals/EditCartModal.js'
import EditAddressModal from './EditAddressModal/index.js'
import PasteAddressModal from '../../common/components/Modal/PasteAddressModal.js'
import EditTagModal from '../../common/components/Modal/EditTagModal.js'
import DeleteTagsModal from '../../common/components/Modal/DeleteTagsModal.js'
import ConfirmAttemptToAllocateModal from '../../common/components/Modal/ConfirmAttemptToAllocateModal.js'
import ConfirmDeallocateModal from '../../common/components/Modal/ConfirmDeallocateModal.js'
import ExportInsuranceHistoryModal from '../../common/components/Modal/ExportInsuranceHistoryModal.js'
import ConfirmDropshipProductModal from '../../common/components/Modal/ConfirmDropshipProductModal.js'
import DeletePresetModal from '../LabelConfig/Modals/DeletePresetModal.js'
import EndiciaCreditCardModal from '../../common/components/Modal/EndiciaCreditCardModal/index.js'
import ListOnAmazonModal from '../../common/components/Modal/ListOnAmazonModal.js'
import CreateChargeModal from '../../common/components/Modal/CreateChargeModal.js'
import ConfirmArchiveProductModal from '../../common/components/Modal/ConfirmArchiveProductModal.js'
import ConfirmExcessiveFulfillmentLatencyModal from '../../common/components/Modal/ConfirmExcessiveFulfillmentLatencyModal.js'
import PrintPickPackModal from '../../common/components/Modal/PrintPickPackModal.js'
import CreateProductModal from '../ProductListPage/Modals/CreateProductModal.js'
import AddPurchaseOrderCommentModal from '../../common/components/Modal/AddPurchaseOrderCommentModal.js'
import EditInstructionsModal from '../../common/components/Modal/EditInstructionsModal.js'
import CartSyncModal from './CartSyncModal/index.js'
import ConfirmMessageModal from '../../common/components/Modal/ConfirmMessageModal.js'
import CreateRuleModal from './CreateRuleModal.js'
import CreditCardModal from './CreditCardModal.js'
import FeatureLockedModal from './FeatureLockedModal.js'
import ReactivationLockedModal from './ReactivationLockedModal.js'
import UnrecoverableErrorModal from './UnrecoverableErrorModal.js'
import UpgradeModal from './UpgradeModal.js'
import WarehouseModal from './WarehouseModal.js'
import WarehouseCartLocationNoticeModal from '../settings/Modals/WarehouseCartLocationNoticeModal.js'
import CancellationModal from './CancellationModal.js'
import EditReportModal from '../ReportsPage/Modals/EditReportModal.js'
import AccountCreditCardModal from './AccountCreditCardModal.js'
import EditPresetModal from '../LabelConfig/Modals/EditPresetModal/index.js'
import InventoryMgmtModuleModal from './InventoryMgmtModuleModal.js'
import OrderListFilterModal from '../OrderListPage/Modals/OrderListFilterModal/index.js'
import OrderListFilterSettingsModal from '../OrderListPage/Modals/OrderListFilterSettingsModal.js'
import AppMessageModal from './AppMessageModal.js'
import AutoFillPostageModal from './AutoFillPostageModal.js'
import OldUpgradeModal from './OldUpgradeModal.js'
import ScheduleExpertModal from './ScheduleExpertModal.js'
import WelcomeTabModal from './WelcomeTabModal.js'
import ProductListFilterModal from '../ProductListPage/Modals/ProductListFilterModal/index.js'
import ProductListFilterSettingsModal from '../ProductListPage/Modals/ProductListFilterSettingsModal.js'
import ProcessLabelModal from '../OrderListPage/Modals/ProcessLabelModal.js'
import PaymentAccountsModal from './PaymentAccountsModal.js'
import PaymentTransferModal from './PaymentTransferModal.js'
import PostageBalanceModal from '../LabelConfig/Modals/PostageBalanceModal.js'

export default function Modals() {
  return (
    <>
      <ModalPortal>
        <ConfirmAttemptToAllocateModal />
        <ConfirmDeallocateModal />
        <ExportInsuranceHistoryModal />
        <ConfirmDropshipProductModal />
        <EndiciaCreditCardModal />
        <ListOnAmazonModal />
        <ConfirmArchiveProductModal />
        <ConfirmExcessiveFulfillmentLatencyModal />
        <AddPurchaseOrderCommentModal />
        <EditInstructionsModal />
      </ModalPortal>
      <AccountCreditCardModal />
      <AppMessageModal />
      <AutoFillPostageModal />
      <CancellationModal />
      <CartSyncModal />
      <ConfirmMessageModal />
      <CreateChargeModal />
      <CreateProductModal />
      <CreateRuleModal />
      <CreditCardModal />
      <DeletePresetModal />
      <DeleteTagsModal />
      <EditAddressModal />
      <EditCartIntegrationBridgeModal />
      <EditCartLocationWarehousesModal />
      <EditCartModal />
      <EditPresetModal />
      <EditReportModal />
      <EditTagModal />
      <FeatureLockedModal />
      <InventoryMgmtModuleModal />
      <OldUpgradeModal />
      <OrderListFilterModal />
      <OrderListFilterSettingsModal />
      <PasteAddressModal />
      <PaymentAccountsModal />
      <PaymentTransferModal />
      <PostageBalanceModal />
      <PrintPickPackModal />
      <ProcessLabelModal />
      <ProductListFilterModal />
      <ProductListFilterSettingsModal />
      <ReactivationLockedModal />
      <ReauthorizeCartModal />
      <ScheduleExpertModal />
      <UnrecoverableErrorModal />
      <UpgradeModal />
      <WarehouseModal />
      <WarehouseCartLocationNoticeModal />
      <WelcomeTabModal />
    </>
  )
}
