import PropTypes from 'prop-types'

import {
  labelInfoIDsThatCanHaveShippingLabelsSelector,
  validBulkShipperIDsSelector,
} from '../../../data/labelInfos/index.js'
import LabelConfig from '../../LabelConfig/index.js'
import LabelViewForm from '../LabelViewForm.js'
import UnusableWarnings from '../../../common/components/List/ActionPanel/UnusableWarnings.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import {orderNumbersNotReadyToCreateLabelSelector} from '../../../data/orders.js'
import ShippingSpeedbumps from './ShippingSpeedbumps.js'
import {deselectOrders} from '../../OrderListPage/orderListActions.js'
import {useShippingLabelsSelector} from '../../../data/company.js'
import {useSelector} from '../../../store.js'
import {showUpgradeModal} from '../../Modals/UpgradeModal.js'

export default function ShippingTab({
  needsShipper,
  needsWarehouse,
  needsAmazonSFP,
  isOrderDetailView,
  unfulfilledOrderNumbers,
  archivedOrderNumbers,
  cancelledOrderNumbers,
  mergedOrderNumbers,
  orderNumbersThatCanHaveShippingLabels,
  orderNumbersWithShippingLabels,
  markAsShippedOrderNumbers,
  manuallyTrackedOrderNumbers,
  isPrimeOrderNumbers,
  isNotPrimeOrderNumbers,
  containsMixOfPrimeAndNotPrimeOrders,
}) {
  const labelInfoIDsThatCanHaveShippingLabels = useSelector((state) =>
    labelInfoIDsThatCanHaveShippingLabelsSelector(state, {
      orderNumbers: orderNumbersThatCanHaveShippingLabels,
    }),
  )
  const orderNumbersNotReadyToCreateLabel = useSelector((state) =>
    orderNumbersNotReadyToCreateLabelSelector(state, {
      orderNumbers: orderNumbersThatCanHaveShippingLabels,
    }),
  )
  const noValidShippers = useSelector(
    (state) =>
      validBulkShipperIDsSelector(state, {
        labelInfoIDs: labelInfoIDsThatCanHaveShippingLabels,
      }).length === 0,
  )
  const useShippingLabels = useSelector(useShippingLabelsSelector)

  if (!useShippingLabels) {
    return (
      <div className="alert alert--lg alert--warning alert--tally margin-bottom-20">
        <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-10 align-center">
          <p className="align-center fs-01 lh-md  margin-bottom-15">
            <strong>Save money, save time, and ship better!</strong>
          </p>
          <p className="fs-00 lh-md margin-bottom-15">
            Fulfill your orders accurately and quickly across all of your sales
            channels.
          </p>

          <p className="fs-00 lh-lg margin-bottom-20">
            To gain access to our shipping label functionality, you need to add
            the <strong>Shipping App</strong> to your account.
          </p>

          <div>
            <ButtonPrimary
              size="x-md"
              className="meta--feature-lock-shipping-label-panel"
              alt
              onClick={() => showUpgradeModal()}
            >
              Manage Your Apps
            </ButtonPrimary>
          </div>
        </div>
      </div>
    )
  }

  if (
    needsShipper ||
    needsWarehouse ||
    needsAmazonSFP ||
    orderNumbersNotReadyToCreateLabel.length > 0 ||
    noValidShippers
  ) {
    return (
      <ShippingSpeedbumps
        needsShipper={needsShipper}
        needsWarehouse={needsWarehouse}
        needsAmazonSFP={needsAmazonSFP}
        orderNumbersNotReadyToCreateLabel={orderNumbersNotReadyToCreateLabel}
        noValidShippers={noValidShippers}
      />
    )
  }

  const singleOrderReference = isOrderDetailView ? 'This order' : '1 order that'

  const allWarningMessages = {
    shippingLabelled: {
      ids: orderNumbersWithShippingLabels,
      singleMessage: `${singleOrderReference} already has a shipping label`,
      pluralMessage: `${orderNumbersWithShippingLabels.length} orders that already have shipping labels`,
    },
    markedAsShipped: {
      ids: markAsShippedOrderNumbers,
      singleMessage: `${singleOrderReference} was marked as shipped`,
      pluralMessage: `${markAsShippedOrderNumbers.length} orders that are marked as shipped`,
    },
    manuallyTracked: {
      ids: manuallyTrackedOrderNumbers,
      singleMessage: `${singleOrderReference} had a tracking number entered for it`,
      pluralMessage: `${manuallyTrackedOrderNumbers.length} orders that had tracking numbers entered for them`,
    },
    archived: {
      ids: archivedOrderNumbers,
      singleMessage: `${singleOrderReference} was archived`,
      pluralMessage: `${archivedOrderNumbers.length} orders that have been archived`,
    },
    cancelled: {
      ids: cancelledOrderNumbers,
      singleMessage: `${singleOrderReference} was cancelled`,
      pluralMessage: `${cancelledOrderNumbers.length} orders that have been cancelled`,
    },
    merged: {
      ids: mergedOrderNumbers,
      singleMessage: `${singleOrderReference} was merged`,
      pluralMessage: `${mergedOrderNumbers.length} orders that have been merged`,
    },
    isPrime: {
      ids: isPrimeOrderNumbers,
      singleMessage: `1 Amazon Prime order`,
      pluralMessage: `${isPrimeOrderNumbers.length} Amazon Prime orders`,
    },
    isNotPrime: {
      ids: isNotPrimeOrderNumbers,
      singleMessage: `1 non-Amazon Prime order`,
      pluralMessage: `${isNotPrimeOrderNumbers.length} non-Amazon Prime orders`,
    },
  }

  if (containsMixOfPrimeAndNotPrimeOrders) {
    const warningTitle =
      'Shipping labels cannot be created for a mix of Amazon Prime and non-Amazon Prime orders. Please deselect one group or the other to proceed:'
    const warningMessages = [
      allWarningMessages.isPrime,
      allWarningMessages.isNotPrime,
    ]

    return (
      <div className="margin-bottom-15 wrap--make-warning">
        <UnusableWarnings
          warningMessages={warningMessages}
          singleTitle={warningTitle}
          pluralTitle={warningTitle}
          showDeselect={!isOrderDetailView}
          deselect={deselectOrders}
        />
      </div>
    )
  }

  if (orderNumbersThatCanHaveShippingLabels.length > 0) {
    const singleWarningTitle = 'A shipping label will not be created for:'
    const pluralWarningTitle = 'Shipping labels will not be created for:'
    const warningMessages = [
      allWarningMessages.shippingLabelled,
      allWarningMessages.markedAsShipped,
      allWarningMessages.manuallyTracked,
      allWarningMessages.cancelled,
      allWarningMessages.merged,
    ]

    return (
      <div>
        <UnusableWarnings
          warningMessages={warningMessages}
          singleTitle={singleWarningTitle}
          pluralTitle={pluralWarningTitle}
          showDeselect={!isOrderDetailView}
          deselect={deselectOrders}
        />
        <LabelConfig labelInfoIDs={labelInfoIDsThatCanHaveShippingLabels} />
      </div>
    )
  }

  if (
    unfulfilledOrderNumbers.length === 0 &&
    orderNumbersWithShippingLabels.length > 0
  ) {
    const singleWarningTitle = 'A shipping label cannot be printed for:'
    const pluralWarningTitle = 'Shipping labels cannot be printed for:'
    const warningMessages = [
      allWarningMessages.markedAsShipped,
      allWarningMessages.manuallyTracked,
    ]

    return (
      <div>
        <UnusableWarnings
          warningMessages={warningMessages}
          singleTitle={singleWarningTitle}
          pluralTitle={pluralWarningTitle}
          showDeselect={!isOrderDetailView}
          deselect={deselectOrders}
        />
        <LabelViewForm orderNumbers={orderNumbersWithShippingLabels} />
      </div>
    )
  }

  const singleWarningTitle = isOrderDetailView
    ? 'A shipping label cannot be created:'
    : 'A shipping label cannot be created for:'
  const pluralWarningTitle = 'Shipping labels will not be created for:'
  const warningMessages = [
    allWarningMessages.shippingLabelled,
    allWarningMessages.markedAsShipped,
    allWarningMessages.manuallyTracked,
    allWarningMessages.archived,
    allWarningMessages.cancelled,
    allWarningMessages.merged,
    allWarningMessages.isPrime,
  ]

  return (
    <div className="margin-bottom-15">
      <UnusableWarnings
        warningMessages={warningMessages}
        singleTitle={singleWarningTitle}
        pluralTitle={pluralWarningTitle}
        showDeselect={!isOrderDetailView}
        deselect={deselectOrders}
      />
    </div>
  )
}

ShippingTab.propTypes = {
  needsWarehouse: PropTypes.bool.isRequired,
  needsShipper: PropTypes.bool.isRequired,
  needsAmazonSFP: PropTypes.bool.isRequired,
  isOrderDetailView: PropTypes.bool.isRequired,
  unfulfilledOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderNumbersThatCanHaveShippingLabels: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  orderNumbersWithShippingLabels: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  archivedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  cancelledOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  mergedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  markAsShippedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  manuallyTrackedOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPrimeOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isNotPrimeOrderNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  containsMixOfPrimeAndNotPrimeOrders: PropTypes.bool.isRequired,
}
